import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../auxiliares/auxiliares.module';
import { MapaUnicoPuntoComponent } from '../../../auxiliares/mapa-unico-punto/mapa-unico-punto.component';
import { AlarmasRoutingModule } from './routing';
import { MapaFacilComponent } from '../../../auxiliares/mapa-facil/mapa-facil.component';
import { DateRangeSelectorComponent } from '../../../auxiliares/date-range-selector/date-range-selector.component';
import { ListadoAlarmasDomiciliariasComponent } from './alarmas-domiciliarias/listado-alarmas-domiciliarias/listado-alarmas-domiciliarias.component';
import { CrearEditarAlarmaDomiciliariaComponent } from './alarmas-domiciliarias/crear-editar-alarma-domiciliaria/crear-editar-alarma-domiciliaria.component';
import { MatDialogActions } from '@angular/material/dialog';
import { MapDrawComponent } from '../../../auxiliares/map-draw/map-draw.component';
import { FlotanteAlarmasComponent } from './mapa-alarmas/flotante-alarmas/flotante-alarmas.component';
import { MapaAlarmasComponent } from './mapa-alarmas/mapa-alarmas.component';
import { IslaAlarmaComponent } from './mapa-alarmas/isla-alarma/isla-alarma.component';
import { DetallesAlarmaInfoComponent } from './detalles-alarma-info/detalles-alarma-info.component';
import { DetallesAlarmaComponent } from './detalles-alarma/detalles-alarma.component';
import { DetallesAlarmaEventosComponent } from './detalles-alarma-eventos/detalles-alarma-eventos.component';
import { DetallesAlarmaEventosDetallesComponent } from './detalles-alarma-eventos/detalles-alarma-eventos-detalles/detalles-alarma-eventos-detalles.component';
import { ListadoConfigsEventosComponent } from '../../standalone/configs-eventos/listado-configs-eventos/listado-configs-eventos.component';
import { ConfigurarComunicadorComponent } from './configurar-comunicador/configurar-comunicador.component';
import { ListadoNotasComponent } from '../../standalone/notas/listado-notas/listado-notas.component';
import { ListadoConfigEventoUsuarioComponent } from '../../standalone/config-evento-usuario/listado-config-evento-usuario/listado-config-evento-usuario.component';
import { ListadoEventosHistoricoComponent } from '../../standalone/eventos-historico/listado-eventos-historico/listado-eventos-historico.component';

@NgModule({
  declarations: [
    //Mapa
    FlotanteAlarmasComponent,
    IslaAlarmaComponent,
    MapaAlarmasComponent,
    //Alarmas
    ListadoAlarmasDomiciliariasComponent,
    CrearEditarAlarmaDomiciliariaComponent,
    DetallesAlarmaInfoComponent,
    DetallesAlarmaComponent,
    DetallesAlarmaEventosComponent,
    DetallesAlarmaEventosDetallesComponent,
    ConfigurarComunicadorComponent,
  ],
  imports: [
    CommonModule,
    AuxiliaresModule,
    AlarmasRoutingModule,
    MapaUnicoPuntoComponent,
    MapaFacilComponent,
    DateRangeSelectorComponent,
    MatDialogActions,
    MapDrawComponent,
    ListadoNotasComponent,
    ListadoConfigsEventosComponent,
    ListadoConfigEventoUsuarioComponent,
    ListadoEventosHistoricoComponent,
  ],
})
export class AlarmasModule {}
