/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Feature, Map, View } from 'ol';
import {
  IDispositivoAlarma,
  IGeoJSONPoint,
  IListado,
  IPopulate,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { Geometry, Point } from 'ol/geom';
import { OpenLayersService } from '../../../../auxiliares/servicios/openLayers.service';
import VectorSource from 'ol/source/Vector';

@Component({
  selector: 'app-mapa-alarmas',
  templateUrl: './mapa-alarmas.component.html',
  styleUrl: './mapa-alarmas.component.scss',
})
export class MapaAlarmasComponent implements OnInit, OnDestroy {
  public ctrl = false;

  //Alarmas
  public alarma?: IDispositivoAlarma;
  public alarmas?: IDispositivoAlarma[] = [];
  public alarmas$?: Subscription;

  /// OPENLAYERS
  public map?: Map;
  // Layer de Markers
  public alarmasMarkers = OpenLayersService.alarmaCasaVectorLayer();

  private populateAlarmas: IPopulate[] = [
    {
      path: 'domicilio',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  private queryAlarmas: IQueryParam = {
    populate: JSON.stringify(this.populateAlarmas),
    includeChildren: true,
  };

  /// Centro
  private puntos: [number, number][] = [];
  constructor(private listados: ListadosService) {}

  /// Alarmas
  public async listar(): Promise<void> {
    this.alarmas$?.unsubscribe();
    this.alarmas$ = this.listados
      .subscribe<
        IListado<IDispositivoAlarma>
      >('dispositivosAlarmas', this.queryAlarmas)
      .subscribe(async (data) => {
        this.alarmas = data.datos;
        this.addMarkers();
        console.log(
          `listado de dispositivosAlarmas ${new Date().toISOString()}`,
          data,
        );
      });
    await this.listados.getLastValue('dispositivosAlarmas', this.queryAlarmas);
  }

  private addMarkers() {
    const source = this.alarmasMarkers.getSource();
    if (!source) return;
    source.clear();
    if (this.alarmas) {
      for (const alarma of this.alarmas) {
        if (!alarma.domicilio?.geojson) continue;
        this.puntos.push(
          (alarma.domicilio.geojson as IGeoJSONPoint).coordinates,
        );
        // marker
        const coord = OpenLayersService.lonLatToCoordinate(
          (alarma.domicilio.geojson as IGeoJSONPoint).coordinates[0],
          (alarma.domicilio.geojson as IGeoJSONPoint).coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: alarma,
          tipo: 'alarma',
        });
        source.addFeature(feature);
      }
    }
  }

  private async initMap() {
    const position = await OpenLayersService.getCurrentPosition();
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'map',
      controls: [],
      layers: [OpenLayersService.mapTile(), this.alarmasMarkers],
      view: new View({
        center: position,
        zoom: 16,
      }),
    });

    this.map.on('error', (e) => {
      console.error('Error en el mapa', e);
    });

    await this.handleClick();
    const sourceres: VectorSource<Feature<Geometry>> = new VectorSource();
    if (this.alarmas?.length) {
      const sourceMarker = this.alarmasMarkers.getSource();
      sourceMarker.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (sourceres) {
      this.setBounds(sourceres);
    }
  }

  private async handleClick() {
    if (!this.map) return;
    this.map.on('singleclick', async (evt) => {
      const feature = this.map?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          const geometry = feature.getGeometry();
          if (geometry instanceof Point) {
            if (feature.get('tipo') === 'alarma') {
              /// ES UNA ALARMA
              return feature;
            }
            return null;
          }
          return null;
        },
      );
      if (!feature) return;
      const data = feature.get('data') as IDispositivoAlarma;
      if (feature.get('tipo') === 'alarma') {
        this.alarma = data;
        if (!this.alarma?.domicilio.geojson?.coordinates) return;
      }
    });
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    if (!extent) return;
    this.map?.getView().fit(extent, { padding: [200, 200, 200, 200] });
    if (this.map?.getView().getZoom() > 17) {
      this.map?.getView().setZoom(17);
    }
  }

  public onClose() {}
  ///
  async ngOnInit() {
    await Promise.all([await this.listar()]);
    await this.initMap();
  }

  ngOnDestroy() {
    this.alarmas$?.unsubscribe();
  }
}
