<app-tabla
  [totalCount]="totalCount"
  [datos]="documentos"
  [columnas]="columnas"
  [name]="name"
  [pageSizeOptions]="helper.pageSizeOptions"
  [search]="search"
  [(query)]="query"
  (queryChange)="listar()"
  [paginatorArriba]="false"
  [crear]="botonCrear"
  [showHijos]="false"
></app-tabla>
<div class="row-flex-end" style="gap: 10px">
  <app-button
    matType="mat-fab extended"
    color="warn"
    (click)="this.helper.volver()"
    text="Volver"
    icon="arrow_back"
  ></app-button>
</div>
