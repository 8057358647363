<h2 mat-dialog-title>Codigos {{ data.nombre }}</h2>
<mat-dialog-content class="mat-typography column-center">
  @for (c of data.codigos; track c) {
    <div>
      <span class="key">Código:</span>
      <span class="value" style="margin-left: 1%">{{ c.codigo }}</span>
    </div>
    <div>
      <span class="key">Descripcion:</span>
      <span class="value" style="margin-left: 1%">{{ c.descripcion }}</span>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-fab extended mat-dialog-close color="accent">Volver</button>
</mat-dialog-actions>
