@if (viaje) {
  <div class="column-center" style="gap: 3px; width: 100%">
    <div class="row-space-between" style="width: 100%">
      <span matTooltip="Fin del viaje" style="cursor: default">📆</span>
      <span>{{ viaje.endTime | date: "short" }}</span>
    </div>
    @if (viaje.maxSpeed) {
      <div class="row-space-between" style="width: 100%">
        <span matTooltip="Velocidad Máxima" style="cursor: default">⏱️</span>
        <span>{{ this.helper.msToKmh(viaje.maxSpeed, true) }} Km/H</span>
      </div>
    }
    @if (viaje.averageSpeed) {
      <div class="row-space-between" style="width: 100%">
        <span matTooltip="Velocidad Promedio" style="cursor: default">⏲️</span>
        <span>{{ this.helper.msToKmh(viaje.averageSpeed, true) }} Km/H</span>
      </div>
    }

    @if (viaje.distance) {
      <div class="row-space-between" style="width: 100%">
        <span matTooltip="Distancia recorrida" style="cursor: default">📏</span>
        <span>{{ this.helper.mToKm(viaje.distance, true) }} Km.</span>
      </div>
    }
    @if (viaje.duration) {
      <div class="row-space-between" style="width: 100%">
        <span matTooltip="Duración del viaje" style="cursor: default">⌛</span>

        <span>{{ this.helper.parseMs(viaje.duration) }}</span>
      </div>
    }
  </div>
}
