<div id="map" class="map-container">
  <div id="flotante" class="ol-flotante ac-85">
    <app-flotante
      [recorridos]="recorridos"
      (recorridosChange)="onRecorridosChange($event)"
      [vehiculos]="reportes?.length"
    ></app-flotante>
  </div>
  @if (reporte) {
    <div id="isla" class="ol-isla">
      <app-isla
        [(reporte)]="reporte"
        [(trackeo)]="trackeo"
        (reporteChange)="onClose()"
      ></app-isla>
    </div>
  }
</div>
