@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      @if (id) {
        <span class="titulo">Editar {{ cronograma?.nombre }}</span>
      } @else {
        <span class="titulo">Crear Cronograma</span>
      }
    </div>
    <br />
    <!-- Nombre - Descripción - Tipo - Terminal-->
    <div class="row-center" style="gap: 8px">
      <!-- Nombre -->
      <mat-form-field style="width: 100%">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="nombre" required />
      </mat-form-field>

      <!-- Descripcion -->
      <mat-form-field style="width: 100%">
        <mat-label>Descripción</mat-label>
        <input matInput formControlName="descripcion" />
      </mat-form-field>

      <!-- Terminal -->
      <div style="width: 100%">
        @if (terminales) {
          <ng-select
            [items]="terminales"
            bindLabel="identificacion"
            bindValue="_id"
            formControlName="idUbicacion"
            placeholder="Terminales"
            [notFoundText]="'No hay terminales disponibles'"
            appendTo="body"
            style="width: 100%"
          >
          </ng-select>
        }
      </div>
    </div>
    <br />
    <div class="row-center" style="gap: 8px">
      <!-- Días -->
      <mat-form-field class="no-hint">
        <mat-label>Días</mat-label>
        <mat-select formControlName="dias" multiple>
          @for (d of dias; track d) {
            <mat-option [value]="d">{{ d }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <!-- Automático -->
      <mat-checkbox
        formControlName="automatico"
        matTooltip="Los despachos se generarán automáticamente por el sistema."
      >
        Automático
      </mat-checkbox>
    </div>
    <br />
    <!-- Configuración -->
    <!-- Color - Nombre para Mostrar -->
    <div class="row-center" style="gap: 8px" [formGroup]="configuracion">
      <!-- Color -->
      <mat-form-field style="width: 100%">
        <mat-label>Color</mat-label>
        <input matInput readonly formControlName="color" />
        <ngx-colors
          class="suffix"
          matSuffix
          ngx-colors-trigger
          formControlName="color"
        >
        </ngx-colors>
      </mat-form-field>
      <!-- Nombre para mostrar -->
      <mat-form-field style="width: 100%">
        <mat-label>Nombre para Mostrar</mat-label>
        <input matInput formControlName="nombreParaMostrar" />
      </mat-form-field>
    </div>

    <br />
    <!-- Períodos -->
    <div
      class="column-center mat-elevation-z1"
      style="gap: 8px"
      formArrayName="periodos"
    >
      <section class="layout">
        <div class="header">
          <span>Períodos</span>
        </div>
        <div class="main">
          @for (p of periodos.controls; track p; let i = $index) {
            <div
              class="row-center"
              style="gap: 5px; width: 100%"
              [formGroupName]="i"
            >
              <!-- Sale -->
              <mat-form-field style="width: 20%">
                <mat-label>Hora</mat-label>
                <input
                  matInput
                  placeholder="12:15"
                  formControlName="desde"
                  pattern="^(?:[01]\d|2[0-3]):[0-5]\d$"
                />
                <mat-icon matSuffix>schedule</mat-icon>
              </mat-form-field>
              <!-- Vehículo -->
              <div style="width: 20%" formGroupName="datos">
                @if (vehiculos) {
                  <ng-select
                    [items]="vehiculos"
                    bindLabel="identificacion"
                    bindValue="_id"
                    formControlName="idVehiculo"
                    placeholder="Vehículo"
                    [notFoundText]="'No hay vehículos disponibles'"
                    appendTo="body"
                    style="width: 100%"
                  >
                  </ng-select>
                }
              </div>
              <!-- Chofer -->
              <div style="width: 20%" formGroupName="datos">
                @if (choferes) {
                  <ng-select
                    [items]="choferes"
                    bindLabel="datosPersonales.nombre"
                    bindValue="_id"
                    formControlName="idChofer"
                    placeholder="Chofer"
                    [notFoundText]="'No hay choferes disponibles'"
                    appendTo="body"
                    style="width: 100%"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <span
                        >{{
                          item.identificacionInterna ||
                            item.datosPersonales?.nombre ||
                            item.usuario
                        }}
                      </span>
                    </ng-template>

                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-item$="item$"
                      let-index="index"
                    >
                      <span
                        >{{
                          item.identificacionInterna ||
                            item.datosPersonales?.nombre ||
                            item.usuario
                        }}
                      </span>
                    </ng-template>
                  </ng-select>
                }
              </div>
              <!-- Recorridos -->
              <div style="width: 20%" formGroupName="datos">
                @if (recorridos) {
                  <ng-select
                    [items]="recorridos"
                    bindLabel="nombre"
                    bindValue="_id"
                    formControlName="idsRecorridos"
                    placeholder="Recorridos"
                    [notFoundText]="'No hay recorridos disponibles'"
                    appendTo="body"
                    style="width: 100%"
                    multiple="true"
                  >
                  </ng-select>
                }
              </div>
              <!-- ID Recorrido Actual  -->
              <div style="width: 20%" formGroupName="datos">
                <mat-form-field style="width: 100%">
                  <mat-label>Tag Recorrido</mat-label>
                  <input matInput formControlName="idRecorridoActual" />
                </mat-form-field>
              </div>
              <!-- Eliminar -->
              <div>
                <button
                  mat-mini-fab
                  color="warn"
                  type="button"
                  (click)="borrarPeriodo(i)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          } @empty {
            <div class="row-center" style="width: 100%">
              <span class="no-info">No hay períodos</span>
            </div>
          }
        </div>
      </section>

      <div class="row-center" style="margin-bottom: 1em">
        <app-button
          [matType]="'mat-fab extended'"
          color="accent"
          (click)="agregarPeriodo()"
          text="Agregar Perdíodo"
          icon="add"
        ></app-button>
      </div>
    </div>
    <br />

    <!-- Botones  -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>

    <!-- DEBUG -->
    <!-- <pre>{{ form.value | json }}</pre> -->
  </form>
}
