import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICliente,
  ICreateActivo,
  IListado,
  IQueryParam,
  IUpdateActivo,
  IActivo,
  ITracker,
  IFilter,
  ICategoriaActivo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ActivosService } from '../../../../../auxiliares/servicios/http/activos.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { CrearEditarTrackerComponent } from '../../../administracion/trackers/crear-editar-tracker/crear-editar-tracker.component';
import { ParamsService } from '../../../../../auxiliares/servicios/params.service';

@Component({
  selector: 'app-crear-editar-activo',
  templateUrl: './crear-editar-activo.component.html',
  styleUrl: './crear-editar-activo.component.scss',
})
export class CrearEditarActivoComponent implements OnInit, OnDestroy {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public clientes?: ICliente[] = [];
  public clientes$?: Subscription;
  public trackers?: ITracker[] = [];
  public trackers$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { edit?: IActivo; categoria: ICategoriaActivo },
    public dialog: MatDialog,
    private listados: ListadosService,
    public helper: HelperService,
    private service: ActivosService,
    public dialogRef: MatDialogRef<CrearEditarActivoComponent>,
    public paramsService: ParamsService,
  ) {}

  private createForm() {
    const categoria: ICategoriaActivo = this.data.categoria || 'Normal';

    this.form = new FormGroup({
      identificacion: new FormControl(this.data?.edit?.identificacion),
      categoria: new FormControl(
        this.data?.edit?.categoria || categoria,
        Validators.required,
      ),
      idCliente: new FormControl(this.data?.edit?.idCliente),
      idTracker: new FormControl(this.data?.edit?.idTracker),
      idsClientesQuePuedenAtender: new FormControl(
        this.data?.edit?.idsClientesQuePuedenAtender,
      ),
    });
  }

  private async listarClientes() {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      includeChildren: true,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  private async listarTrackers() {
    const filter: IFilter<ITracker> = {
      $or: [
        {
          asignadoA: null,
        },
        {
          asignadoA: { $exists: false },
        },
        {
          asignadoA: this.data?.edit?._id,
        },
      ],
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
    };
    this.trackers$?.unsubscribe();
    this.trackers$ = this.listados
      .subscribe<IListado<ITracker>>('trackers', query)
      .subscribe((data) => {
        this.trackers = data.datos;
        //Si se creo uno se actualiza esto y el params service
        if (this.paramsService.getParams()['idTracker']) {
          this.form.patchValue({
            idTracker: this.paramsService.getParams()['idTracker'],
          });
          this.paramsService.setParams({});
        }
        console.log(`listado de trackers`, data);
      });
    await this.listados.getLastValue('trackers', query);
  }

  public crearTracker() {
    this.paramsService.setParams({ desdeActivo: true });
    this.dialog.open(CrearEditarTrackerComponent);
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?.edit?._id) {
        // Update
        await this.service.update(this.data.edit._id, data);
        this.helper.notifSuccess('Actualizado correctamente');
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateActivo | IUpdateActivo {
    const data: ICreateActivo | IUpdateActivo = this.form?.value;
    if (data.idTracker) {
      const tracker = this.trackers?.find((t) => t._id === data.idTracker);
      if (tracker.traccar?.id) {
        data.idUnicoTraccar = tracker.traccar.id;
      } else {
        data.idUnicoTraccar = null;
      }
    } else {
      data.idUnicoTraccar = null;
    }
    return data;
  }

  async ngOnInit() {
    this.createForm();
    this.title = this.data?.edit?._id
      ? `Editar ${this.data?.edit?.identificacion}`
      : 'Crear Activo';

    await Promise.all([this.listarTrackers(), this.listarClientes()]);
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
    this.trackers$?.unsubscribe();
  }
}
