import { Component } from '@angular/core';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrl: './usuario.component.scss',
})
export class UsuarioComponent {
  public usuario = LoginService.getUsuario();
  public iniciales = this.getIniciales(this.usuario?.datosPersonales?.nombre);

  private getIniciales(nombre?: string) {
    if (nombre) {
      return nombre
        .split(' ')
        .map((n) => n[0])
        .join('');
    } else {
      return 'XX';
    }
  }
}
