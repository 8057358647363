import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IDocumentacion,
  IFilter,
  IListado,
  IQueryParam,
  IUpdateActivo,
} from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DocumentosService } from '../documentos.service';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import { IRegExpSearch } from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

@Component({
  selector: 'app-listado-documentos',
  templateUrl: './listado-documentos.component.html',
  styleUrl: './listado-documentos.component.scss',
})
export class ListadoDocumentosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public id?: string | null;
  public tipo?: string | null;
  public form?: FormGroup;
  public columnas?: IColumnas<IDocumentacion>[];
  public name = ListadoDocumentosComponent.name;
  public documentos: IDocumentacion[] = [];
  public documentos$?: Subscription;
  public totalCount = 0;

  public botonCrear: ICrearBoton = {
    mostrar: true,
    tooltip: 'Crear Documento',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };

  public search: IRegExpSearch = {
    fields: ['tipo'],
  };

  public filter: IFilter<IDocumentacion> = {};

  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private navigator: Router,
    private route: ActivatedRoute,
    public service: DocumentosService,
    private dialogService: DialogService,
  ) {}

  public async listar(): Promise<void> {
    if (this.query.filter) {
      const filter: IFilter<IDocumentacion> = JSON.parse(this.query.filter);
      if (this.tipo == 'usuario') filter.idChofer = this.id;
      if (this.tipo == 'vehiculo') filter.idActivo = this.id;
      this.query.filter = JSON.stringify(filter);
    }
    this.documentos$?.unsubscribe();
    this.documentos$ = this.listados
      .subscribe<IListado<IDocumentacion>>('documentos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.documentos = data.datos;
        console.log(`listado de documentos`, data);
      });
    await this.listados.getLastValue('documentos', this.query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate([
      '/',
      'main',
      'documentos',
      'crear',
      this.tipo,
      this.id,
    ]);
  }

  public ventanaDocumentos(data: IDocumentacion) {
    this.navigator.navigate([
      '/',
      'main',
      'documentos',
      'documento',
      this.tipo,
      data._id,
    ]);
  }

  public createForm() {
    this.form = new FormGroup({
      idChofer: new FormControl(undefined),
    });
  }

  public getData(): IUpdateActivo {
    const data: IUpdateActivo = this.form?.value;
    return data;
  }

  public async eliminar(dato: IDocumentacion) {
    const res = await this.dialogService.confirm(
      'Eliminar Documento',
      `¿Desea eliminar el Documento?`,
    );
    if (!res) return;
    if (dato._id) {
      await this.service.delete(dato._id);
      this.helper.notifSuccess('Documento eliminado');
    }
  }

  // Hooks

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Tipo',
          sortable: true,
        },
        row: {
          field: 'tipo',
        },
      },
      {
        header: {
          label: 'Fecha de Emision',
          sortable: true,
        },
        row: {
          field: 'emision',
          pipe: {
            pipe: DatePipe,
            args: 'dd/MM/yyyy',
          },
        },
      },
      {
        header: {
          label: 'Fecha de Vencimiento',
          sortable: true,
        },
        row: {
          field: 'vencimiento',
          pipe: {
            pipe: DatePipe,
            args: 'dd/MM/yyyy',
          },
        },
      },
      {
        header: {
          label: 'Descripcion',
        },
        row: {
          parse(dato) {
            if (dato.descripcion) {
              let text = dato.descripcion;
              if (text.length >= 50) {
                text = text.slice(0, 50);
                text += '...';
              }
              return text;
            }
            return '';
          },
          noData: 'Sin Descripcion',
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'search',
              tooltip: 'Detalles',
              click: (dato) => this.ventanaDocumentos(dato),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
            },
          ],
        },
      },
    ];
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.tipo = params.get('tipo');
    this.setColumnas();
    await this.listar();
  }

  ngOnDestroy() {
    this.documentos$?.unsubscribe();
  }
}
