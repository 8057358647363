import { Component, Input, OnInit } from '@angular/core';
import {
  IActivo,
  IFilter,
  IListado,
  IPopulate,
  IQueryParam,
  ITrackeo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { IColumnas } from '../../../../../../auxiliares/tabla/tabla.component';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../../auxiliares/servicios/listados.service';
import { DialogService } from '../../../../../../auxiliares/dialog/dialog.service';
import { TrackeosService } from '../../../../../../auxiliares/servicios/http/trackeos.service';

interface ITrackeoTabla extends ITrackeo {
  index?: number;
}

@Component({
  selector: 'app-listado-trackeos',
  templateUrl: './listado-trackeos.component.html',
  styleUrl: './listado-trackeos.component.scss',
})
export class ListadoTrackeosComponent implements OnInit {
  @Input() vehiculo?: IActivo;

  //// Tabla nueva
  public name = ListadoTrackeosComponent.name;
  public trackeos: ITrackeoTabla[] = [];
  public trackeos$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<ITrackeoTabla>[];

  public filter: IFilter<ITrackeo> = {
    // idRecorrido: this.vehiculo?.idRecorrido,
  };
  public populate: IPopulate[] = [
    {
      path: 'recorrido',
      select: 'paradas.nombre paradas._id nombre',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: '-fecha',
    limit: +this.helper.getPageSize(this.name),
  };

  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private dialogService: DialogService,
    private service: TrackeosService,
  ) {}

  private populateTrackeos() {
    if (!this.trackeos) return;

    for (const t of this.trackeos) {
      const recorrido = t?.recorrido;
      if (!recorrido) continue;
      const paradas = recorrido.paradas;
      if (!paradas) continue;
      t.parada = paradas.find((p) => p._id === t.idParada);
      const index = paradas.findIndex((p) => p._id === t.idParada);
      t.index = index + 1;
    }
    // for (const t of this.trackeos) {
    //   t.proximaParada = recorrido.paradas?.find(
    //     (p) => p._id === t.idProximaParada,
    //   );
    // }
  }

  public async listar(): Promise<void> {
    if (!this.vehiculo?._id) return;
    const filter: IFilter<ITrackeo> = HelperService.stringToObject(
      this.query.filter,
    );
    filter['idActivo'] = this.vehiculo._id;
    // filter['idRecorrido'] = this.vehiculo.idRecorrido;
    this.query.filter = JSON.stringify(filter);

    this.trackeos$?.unsubscribe();
    this.trackeos$ = this.listados
      .subscribe<IListado<ITrackeo>>('trackeos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.trackeos = data.datos;
        this.populateTrackeos();
        console.log(`listado de trackeos`, data);
      });
    await this.listados.getLastValue('trackeos', this.query);
  }

  public async eliminar(trackeo: ITrackeo) {
    const res = await this.dialogService.confirm(
      'Eliminar Trackeo',
      `¿Desea eliminar el trackeo?`,
    );
    if (!res) return;
    try {
      await this.service.delete(trackeo._id!);
      this.helper.notifSuccess('Trackeo eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Fecha de Creación',
        },
        row: {
          field: 'fecha',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: {
          label: 'Recorrido',
        },
        row: {
          // field: 'index',`
          parse(dato) {
            return `${dato.recorrido?.nombre}`;
          },
        },
      },
      {
        header: {
          label: '#',
        },
        row: {
          // field: 'index',`
          parse(dato) {
            return `${dato.index} / ${dato.recorrido?.paradas?.length}`;
          },
        },
      },
      // {
      //   header: {
      //     label: 'Total Paradas',
      //   },
      //   row: {
      //     parse(dato) {
      //       return dato.recorrido?.paradas?.length;
      //     },
      //   },
      // },
      {
        header: {
          label: 'Parada',
        },
        row: {
          parse(dato) {
            return dato.parada?.nombre;
          },
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
            },
          ],
        },
      },
    ];
  }

  async ngOnInit() {
    this.setColumnas();
    await this.listar();
  }
}
