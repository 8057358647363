/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ITratamientoEvento,
  IQueryParam,
  IPopulate,
  IFilter,
  IListado,
  ICreateTratamientoEvento,
  IEvento,
  INota,
  ICliente,
  IServicioContratado,
  estadoEvento,
} from 'modelos/src';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { AgregarNotaComponent } from '../agregar-nota/agregar-nota.component';
import { PonerEnEsperaComponent } from '../poner-en-espera/poner-en-espera.component';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { LoginService } from '../../../../login/login.service';
import { MapaFacilDialogComponent } from '../../../../../auxiliares/mapa-facil-dialog/mapa-facil-dialog.component';
import { MapaFacilData } from '../../../../../auxiliares/mapa-facil/interfaces';
import { fromLonLat } from 'ol/proj';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TratamientoEventosService } from '../../../../../auxiliares/servicios/http/tratamientos-eventos.service';

@Component({
  selector: 'app-tratamiento',
  templateUrl: './tratamiento.component.html',
  styleUrl: './tratamiento.component.scss',
})
export class TratamientoComponent implements OnInit, OnDestroy {
  public loading: boolean = true;
  public dragBoundary = document.getElementById('router');

  public tratando: boolean = false;
  public estadoActual?: estadoEvento;

  public evento: ICliente;
  public evento$: Subscription;
  public cliente: ICliente;
  public cliente$: Subscription;
  public estadoDeCuenta?: string = 'Al día';
  public serviciosContratados: IServicioContratado[] = [];

  public tratamientosEvento: ITratamientoEvento[] = [];
  public tratamientosEvento$: Subscription;
  public notas: INota[] = [];
  public contactos: INota[] = [];
  public notas$: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEvento,
    private listados: ListadosService,
    public helper: HelperService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<TratamientoComponent>,
    private dialogService: DialogService,
    private service: TratamientoEventosService,
    private cdk: OverlayContainer,
    private el: ElementRef,
  ) {}

  public async listarNotas(): Promise<void> {
    const idEntidad = this.data.activo?._id || this.data.alarma?._id;
    const idCliente = this.data.cliente?._id;
    const filter: IFilter<INota> = {
      idAsignado: { $in: [idEntidad, idCliente] },
      $or: [
        { permanente: true },
        {
          vigenciaDesde: { $lte: new Date().toISOString() },
          vigenciaHasta: { $gte: new Date().toISOString() },
        },
      ],
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: '-fechaCreacion',
    };
    console.log(`query`, query);

    this.notas$?.unsubscribe();
    this.notas$ = this.listados
      .subscribe<IListado<INota>>('notas', query)
      .subscribe((data) => {
        this.notas = data.datos.filter((nota) => nota.tipo === 'Nota');
        this.contactos = data.datos.filter((nota) => nota.tipo === 'Contacto');
        console.log(`listado de notas`, data);
      });
    await this.listados.getLastValue('notas', query);
  }

  public async listarCliente(): Promise<void> {
    const idCliente = this.data.idCliente;
    const filter: IFilter<ICliente> = {
      _id: idCliente,
    };
    const populate: IPopulate = {
      path: 'serviciosContratados',
      select: 'nombre icono',
    };

    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      select: 'idServiciosContratados nombre estadoDeCuenta',
      populate: JSON.stringify(populate),
    };
    this.cliente$?.unsubscribe();
    this.cliente$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente = data.datos[0];
        // this.estadoDeCuenta = this.cliente.estadoDeCuenta;
        this.serviciosContratados = this.cliente.serviciosContratados;
        console.log(`listado de cliente`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  public async listarTratamientos(): Promise<void> {
    const populate: IPopulate[] = [
      {
        path: 'usuario',
        select: 'usuario',
      },
    ];
    const filter: IFilter<ITratamientoEvento> = {
      idEvento: this.data?._id,
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      sort: '-fechaCreacion',
    };
    this.tratamientosEvento$?.unsubscribe();
    this.tratamientosEvento$ = this.listados
      .subscribe<IListado<ITratamientoEvento>>('tratamientosEventos', query)
      .subscribe((data) => {
        this.tratamientosEvento = JSON.parse(JSON.stringify(data.datos));
        this.estadoActual = this.tratamientosEvento[0]?.estado;
        console.log(`listado de tratamientosEventos`, data);
      });
    await this.listados.getLastValue('tratamientosEventos', query);
  }

  public async listarEvento(): Promise<void> {
    this.evento$?.unsubscribe();
    this.evento$ = this.listados
      .subscribe<IEvento>('evento', this.data?._id)
      .subscribe((data) => {
        this.tratando = data.idsUsuariosAtendiendo.includes(
          LoginService.getUsuario()._id,
        );
        console.log(`listado de evento`, data);
      });
    await this.listados.getLastValue('evento', this.data?._id);
  }

  public async espera() {
    const config: MatDialogConfig = {
      data: this.data,
      panelClass: 'no-scroll-dialog',
      width: '600px',
      disableClose: true,
    };
    this.dialog.open(PonerEnEsperaComponent, config);
  }

  public async liberar() {
    const config: MatDialogConfig<{ evento: IEvento; estado: estadoEvento }> = {
      data: { evento: this.data, estado: 'Liberada' },
      panelClass: 'no-scroll-dialog',
      width: '600px',
      disableClose: true,
    };
    this.dialog.open(AgregarNotaComponent, config);
  }

  public async finalizar() {
    const config: MatDialogConfig<{ evento: IEvento; estado: estadoEvento }> = {
      data: { evento: this.data, estado: 'Finalizada' },
      panelClass: 'no-scroll-dialog',
      width: '600px',
      disableClose: true,
    };
    this.dialog.open(AgregarNotaComponent, config);
  }

  public agregarNota() {
    const config: MatDialogConfig<{ evento: IEvento; estado: estadoEvento }> = {
      data: { evento: this.data, estado: 'En Atención' },
      panelClass: 'no-scroll-dialog',
      width: '600px',
      disableClose: true,
    };
    this.dialog.open(AgregarNotaComponent, config);
  }

  public async tratar() {
    const res = await this.dialogService.confirm(
      `Confirme la acción`,
      `¿Tratar evento ${this.data?.valores?.titulo} de ${this.data?.cliente?.nombre}?`,
    );
    if (!res) return;
    const tratamiento: ICreateTratamientoEvento = {
      estado: 'Pendiente',
      idEvento: this.data?._id,
    };
    await this.service.create(tratamiento);
    this.tratando = true;
  }

  public verUbicacion() {
    const lng =
      (this.data.alarma?.domicilio?.geojson?.coordinates[0] as number) ||
      (this.data.valores?.['geojson']?.coordinates[0] as number);
    const lat =
      (this.data.alarma?.domicilio?.geojson?.coordinates[1] as number) ||
      (this.data.valores?.['geojson']?.coordinates[1] as number);

    const coord = fromLonLat([lng, lat]);
    const data: MapaFacilData = {
      markers: [{ coor: coord, label: 'puntoSimple' }],
    };
    this.dialog.open(MapaFacilDialogComponent, { data });
  }

  public verFotos() {
    alert('Ver fotos');
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public bringToFront(): void {
    const dialogsContainer = Array.from(
      this.cdk.getContainerElement().childNodes,
    );
    const dialogContainerTagName = 'MAT-DIALOG-CONTAINER';
    const parentEl = this.el.nativeElement.closest(dialogContainerTagName);
    const dialogId = parentEl?.getAttribute('id');

    const focusedDialog = (dialogsContainer as HTMLElement[]).find(
      (el: HTMLElement) => {
        return el.querySelector(`#${dialogId}`);
      },
    );

    if (focusedDialog) {
      // appendChild moves the element to the end of the container
      this.cdk.getContainerElement().appendChild(focusedDialog);
    } else {
      console.error('Dialog not found with id:', dialogId);
    }
  }

  //

  async ngOnInit() {
    this.estadoActual = this.data?.estado;
    const usuario = LoginService.getUsuario();
    if (this.data.idsUsuariosAtendiendo.includes(usuario._id)) {
      this.tratando = true;
    }

    await Promise.all([
      this.listarEvento(),
      this.listarTratamientos(),
      this.listarNotas(),
      this.listarCliente(),
    ]);
    this.loading = false;
  }

  async ngOnDestroy() {
    this.tratamientosEvento$?.unsubscribe();
    this.notas$?.unsubscribe();
    this.cliente$?.unsubscribe();
    this.evento$?.unsubscribe();
  }
}
