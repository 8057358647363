import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { IActivo } from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { IParamEventoUsuario } from '../../../../standalone/config-evento-usuario/crear-editar-config-evento-usuario-dialog/crear-editar-config-evento-usuario-dialog.component';

@Component({
  selector: 'app-detalles-activo',
  templateUrl: './detalles-activo.component.html',
  styleUrl: './detalles-activo.component.scss',
})
export class DetallesActivoComponent implements OnInit {
  public selectedTab = 0;
  public idActivo: string;
  public activo: IActivo;
  private activo$: Subscription;
  public loading: boolean = true;

  public params?: IParamEventoUsuario;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private listados: ListadosService,
  ) {}

  public tabChanged(event: MatTabChangeEvent): void {
    // Setea el query param  'tab' con el valor del tab seleccionado
    this.selectedTab = event.index;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: this.selectedTab },
      queryParamsHandling: 'merge',
    });
  }

  private async listarActivo() {
    this.activo$?.unsubscribe();
    this.activo$ = this.listados
      .subscribe<IActivo>('activo', this.idActivo)
      .subscribe((data) => {
        this.activo = data;
        console.log(`listado de activo`, data);
      });
    await this.listados.getLastValue('activo', this.idActivo);
  }

  public activos() {
    this.router.navigate(['main/activos/activos'], {});
  }

  async ngOnInit(): Promise<void> {
    const params = await firstValueFrom(this.activatedRoute.paramMap);
    this.idActivo = params.get('id');

    await this.listarActivo();

    // Los parametros para el componente config evento usuario
    this.params = {
      tipoEntidad: 'Activo',
      agrupacion: 'Entidad',
      idEntidad: this.idActivo,
      activo: this.activo,
      crear: 'Dialog',
    };

    // Obtiene el query param 'tab' y lo asigna al tab seleccionado
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.selectedTab = parseInt(params['tab']);
      }
    });
    this.loading = false;
  }
}
