<div class="flotante-contenedor">
  @if (vehiculos) {
    <div class="flotante-vehiculos">
      <span>Vehiculos:</span>
      <span>{{ vehiculos }}</span>
    </div>
  }
  <div class="flotante-recorridos">
    @if (recorridos && recorridos.length > 0) {
      <div class="grow1">
        <div class="row-center">
          <mat-form-field style="width: 100%" class="no-hint">
            <mat-label>Recorridos a mostrar</mat-label>
            <mat-select
              [multiple]="true"
              (valueChange)="onRecorridoChange($event)"
            >
              @for (r of recorridos; track r) {
                <mat-option [value]="r">
                  <div class="row-center" style="gap: 15px">
                    <strong>Nombre:</strong> {{ r.nombre }}
                    @if (r.grupo?.nombre) {
                      <strong>Grupo:</strong> {{ r.grupo?.nombre }}
                    }
                    @if (r.por) {
                      <strong>Por:</strong> {{ r.por }}
                    }
                    @if (r.destino) {
                      <strong>A:</strong> {{ r.destino }}
                    }
                    <ngx-colors
                      ngx-colors-trigger
                      disabled
                      [(ngModel)]="r.color"
                    ></ngx-colors>
                  </div>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    } @else {
      <div class="grow1">
        <span class="no-info"> Sin Recorridos </span>
      </div>
    }
  </div>
</div>
