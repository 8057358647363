import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  IDespacho,
  IFilter,
  IPopulate,
  IQueryParam,
  IListado,
  ICliente,
  IActivo,
  IUsuario,
  ICronograma,
  IRecorrido,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { DespachosService } from '../../despachos/despachos.service';
import { IColumnas } from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { EditarDespachoComponent } from '../editar-despacho/editar-despacho.component';
import { TZDate } from '@date-fns/tz';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrl: './listado.component.scss',
})
export class ListadoComponent implements OnInit, OnDestroy {
  readonly dialog = inject(MatDialog);
  private hoy = new Date();
  //// Tabla nueva
  public name = ListadoComponent.name;
  public totalCount = 0;
  public columnas?: IColumnas<IDespacho>[];
  public despachos: IDespacho[] = [];
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //Subs
  private despachos$?: Subscription;
  private clientes$: Subscription;
  private activos$: Subscription;
  private cronogramas$: Subscription;
  private choferes$: Subscription;
  private recorridos$: Subscription;
  private usuarios$: Subscription;

  public search: IRegExpSearch = {
    fields: ['nombre'],
  };

  public filter: IFilter<IDespacho> = {};
  public populate: IPopulate[] = [
    {
      path: 'usuario',
      select: 'usuario datosPersonales',
    },
    {
      path: 'activo',
      select: 'vehiculo identificacion',
    },
    {
      path: 'chofer',
      select: 'datosPersonales',
    },
    {
      path: 'recorridos',
      select: 'nombre',
    },
    {
      path: 'cronograma',
      select: 'nombre',
    },
  ];
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public activo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idActivo',
    },
    label: 'Activo',
    selectLabel: 'identificacion',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public chofer: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idChofer',
    },
    label: 'Chofer',
    selectLabel: 'datosPersonales.nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public cronograma: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCronograma',
    },
    label: 'Cronograma',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public recorrido: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsRecorridos',
    },
    label: 'Recorrido',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public usuario: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idUsuario',
    },
    label: 'Usuario',
    selectLabel: 'usuario',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public filtros: IFiltroTabla[] = [
    this.activo,
    this.chofer,
    this.cronograma,
    this.recorrido,
    this.usuario,
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: '-fechaCreacion',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: DespachosService,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([
        this.listar(),
        this.listarClientes(),
        this.listarActivos(),
        this.listarChoferes(),
        this.listarCronograma(),
        this.listarRecorridos(),
        this.listarUsuario(),
      ]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    this.despachos$?.unsubscribe();
    this.despachos$ = this.listados
      .subscribe<IListado<IDespacho>>('despachos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.despachos = data.datos;
        console.log(`listado de despachos`, data);
      });
    await this.listados.getLastValue('despachos', this.query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }
  private async listarActivos(): Promise<void> {
    const query: IQueryParam = {
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.activos$?.unsubscribe();
    this.activos$ = this.listados
      .subscribe<IListado<IActivo>>('activos', query)
      .subscribe((data) => {
        this.activo.elementos = data.datos;
        console.log(`listado de activos`, data);
      });
    await this.listados.getLastValue('activos', query);
  }
  private async listarChoferes(): Promise<void> {
    const populate: IPopulate = {
      path: 'datosPersonales',
      select: 'nombre',
    };
    const query: IQueryParam = {
      populate: JSON.stringify(populate),
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.choferes$?.unsubscribe();
    this.choferes$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.chofer.elementos = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listados.getLastValue('usuarios', query);
  }
  private async listarCronograma(): Promise<void> {
    const query: IQueryParam = {
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.cronogramas$?.unsubscribe();
    this.cronogramas$ = this.listados
      .subscribe<IListado<ICronograma>>('cronogramas', query)
      .subscribe((data) => {
        this.cronograma.elementos = data.datos;
        console.log(`listado de cronogramas`, data);
      });
    await this.listados.getLastValue('cronogramas', query);
  }
  private async listarRecorridos(): Promise<void> {
    const query: IQueryParam = {
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.recorridos$?.unsubscribe();
    this.recorridos$ = this.listados
      .subscribe<IListado<IRecorrido>>('recorridos', query)
      .subscribe((data) => {
        this.recorrido.elementos = data.datos;
        console.log(`listado de recorridos`, data);
      });
    await this.listados.getLastValue('recorridos', query);
  }
  private async listarUsuario(): Promise<void> {
    const populate: IPopulate = {
      path: 'datosPersonales',
      select: 'nombre',
    };
    const query: IQueryParam = {
      populate: JSON.stringify(populate),
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.usuarios$?.unsubscribe();
    this.usuarios$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.usuario.elementos = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listados.getLastValue('usuarios', query);
  }

  // Acciones
  public async editar(despacho: IDespacho) {
    this.dialog.open(EditarDespachoComponent, {
      data: despacho._id,
      width: '600px',
    });
  }

  public async eliminar(despacho: IDespacho) {
    const res = await this.dialogService.confirm(
      'Eliminar Despacho',
      `¿Desea eliminar la despacho ${despacho.fecha}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(despacho._id!);
      this.helper.notifSuccess('Despacho eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      // {
      //   header: {
      //     label: 'Fecha de Creación',
      //     sortable: true,
      //   },
      //   row: {
      //     field: 'fechaCreacion',
      //     pipe: {
      //       pipe: DatePipe,
      //       args: 'short',
      //     },
      //   },
      // },
      {
        header: {
          label: 'Completado',
        },
        row: {
          html(dato) {
            const hoyArg = new TZDate(
              new Date(),
              'America/Argentina/Buenos_Aires',
            );
            const hhMM = dato.hora.split(':');
            const fecha = new TZDate(
              dato.fecha,
              'America/Argentina/Buenos_Aires',
            );
            fecha.setHours(+hhMM[0], +hhMM[1]);
            const color = fecha.getTime() > hoyArg.getTime() ? 'red' : 'green';
            let html = `<div style="display: flex; gap: 1em; align-items: center">`;
            html += `<span style="color: ${color}; font-size: 2em; overflow: visible"> &#x2B24; </span>`;
            if (color === 'red') {
              html += `<span style="color: ${color}; font-size: 1.2em; overflow: visible"> Pendiente </span>`;
            } else {
              html += `<span style="color: ${color}; font-size: 1.2em; overflow: visible"> Completado </span>`;
            }
            html += `</div>`;
            return html;
          },
        },
      },
      {
        header: {
          label: 'Día',
          sortable: true,
        },
        row: {
          field: 'fecha',
        },
      },
      {
        header: {
          label: 'Sale',
          sortable: true,
        },
        row: {
          field: 'hora',
        },
      },
      {
        header: {
          label: 'Salió',
          sortable: true,
        },
        row: {
          field: 'salio',
        },
      },
      {
        header: {
          label: 'Cronograma',
          sortable: true,
        },
        row: {
          parse: (dato) => dato.cronograma?.nombre,
        },
      },
      {
        header: {
          label: 'Coche',
          sortable: true,
        },
        row: {
          parse: (dato) =>
            `${dato.activo?.identificacion} - ${dato.activo?.vehiculo?.patente}`,
          noData: 'Sin coche',
        },
      },
      {
        header: {
          label: 'Chofer',
          sortable: true,
        },
        row: {
          parse: (dato) => this.parseChofer(dato),
          noData: 'Sin chofer',
        },
      },
      {
        header: {
          label: 'Recorridos',
          sortable: true,
        },
        row: {
          parse: (dato) => this.parseRecorridos(dato),
          noData: 'Sin recorridos',
        },
      },
      {
        header: {
          label: 'Usuario',
          sortable: true,
        },
        row: {
          parse(dato) {
            return (
              dato.usuario?.datosPersonales?.nombre || dato.usuario?.usuario
            );
          },
          noData: 'Despacho Autómatico',
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: (dato) => this.checkFecha(dato),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: (dato) => this.checkFecha(dato),
            },
          ],
        },
      },
    ];
  }
  ///

  private parseChofer(dato: IDespacho) {
    if (dato.chofer?.identificacionInterna) {
      return dato.chofer?.identificacionInterna;
    } else {
      return dato.chofer?.datosPersonales?.nombre || dato.chofer?.usuario;
    }
  }

  private parseRecorridos(dato: IDespacho) {
    const nombres = dato.recorridos.map((r) => r.nombre);
    return nombres.join(', ');
  }

  public checkFecha(d: IDespacho) {
    const hoyArg = new TZDate(this.hoy, 'America/Argentina/Buenos_Aires');
    const fecha = new TZDate(this.hoy, 'America/Argentina/Buenos_Aires');
    const horaYMinutos = d.hora.split(':');
    const anioMesDia = d.fecha.split('-');
    fecha.setFullYear(+anioMesDia[0], +anioMesDia[1] - 1, +anioMesDia[2]);
    fecha.setHours(+horaYMinutos[0], +horaYMinutos[1]);
    // True si la fecha es anterior a la actual por media hora
    return fecha.getTime() < hoyArg.getTime() - 1800000;
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listarTodo();
  }

  ngOnDestroy() {
    this.despachos$?.unsubscribe();
  }
}
