import { Component, Input } from '@angular/core';
import { Stop } from 'modelos/src';
import { HelperService } from '../../../../../../../../auxiliares/servicios/helper.service';

@Component({
  selector: 'app-parada',
  templateUrl: './parada.component.html',
  styleUrl: './parada.component.scss',
})
export class ParadaComponent {
  @Input() parada?: Stop;

  constructor(public helper: HelperService) {}
}
