<div class="controles-contenedor">
  <mat-accordion>
    <!-- Viajes  -->
    <mat-expansion-panel
      [expanded]="true"
      (opened)="toggle = true"
      (closed)="toggle = false"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Viajes </mat-panel-title>
        <mat-icon matSuffix fontIcon="mode_of_travel"></mat-icon>
      </mat-expansion-panel-header>
      <!-- Contenido -->
      <app-date-range-selector
        [dateRange]="rangoViajes"
        (dateRangeChange)="onViajesRangeChange($event)"
      ></app-date-range-selector>
      <br />
      <div style="overflow-y: auto">
        @if (loadingViajes) {
          <div style="width: 100%; display: flex; justify-content: center">
            <mat-spinner></mat-spinner>
          </div>
        } @else {
          @if (!viajes.length) {
            <div class="no-data">No hay viajes</div>
          } @else {
            @for (v of viajes; track $index) {
              <mat-expansion-panel
                hideToggle
                [expanded]="viajeSelected === v"
                [ngClass]="viajeSelected === v ? 'ac-85' : ''"
                (expandedChange)="onViajesSelected(v, $event)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Inicio: {{ v.startTime | date: "short" }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <app-viaje [viaje]="v"></app-viaje>
              </mat-expansion-panel>
            }
          }
        }
      </div>
    </mat-expansion-panel>
    <!-- Paradas -->
    <mat-expansion-panel
      (opened)="toggle = false"
      (closed)="toggle = true"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Paradas </mat-panel-title>
        <mat-icon matSuffix fontIcon="airline_stops"></mat-icon>
      </mat-expansion-panel-header>
      <!-- Contenido -->
      <app-date-range-selector
        [dateRange]="rangoParadas"
        (dateRangeChange)="onParadasRangeChange($event)"
      ></app-date-range-selector>
      <br />
      <div style="overflow-y: auto">
        @if (loadingParadas) {
          <div style="width: 100%; display: flex; justify-content: center">
            <mat-spinner></mat-spinner>
          </div>
        } @else {
          @if (!paradas.length) {
            <div class="no-data">No hay paradas</div>
          } @else {
            @for (p of paradas; track $index) {
              <mat-expansion-panel
                hideToggle
                [expanded]="paradaSelected === p"
                [ngClass]="paradaSelected === p ? 'ac-85' : ''"
                (expandedChange)="onParadasSelected(p, $event)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Desde: {{ p.startTime | date: "short" }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <app-parada [parada]="p"></app-parada>
              </mat-expansion-panel>
            }
          }
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
