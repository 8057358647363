import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { LoadingService } from '../../../auxiliares/servicios/loading.service';
import { WebSocketService } from '../../../auxiliares/servicios/websocket.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  public inputType = 'password';
  public form?: FormGroup;

  constructor(
    private login: LoginService,
    public helper: HelperService,
    public loading: LoadingService,
    private wss: WebSocketService,
    private router: Router,
  ) {}

  private buildForm() {
    this.form = new FormGroup({
      usuario: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  // on

  public onClickShowPassword() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
  }

  public async onSubmit() {
    this.loading.setLoading(true);
    try {
      const user = this.form?.get('usuario')?.value;
      const pass = this.form?.get('password')?.value;
      await this.login.login(user, pass);
      this.wss.initWs();
      this.router.navigateByUrl('main');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (
        error.error?.message ===
          'Invalid grant: user credentials are invalid' ||
        error.error?.message === 'Usuario no encontrado'
      ) {
        this.helper.notifError('Usuario o contraseña incorrectos');
      } else {
        console.error(error);
        this.helper.notifError(error);
      }
    }
    this.loading.setLoading(false);
  }

  ngOnInit(): void {
    this.buildForm();
  }
}
