import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApikeysComponent } from './apikeys/apikeys/apikeys.component';
import { CrearEditarApikeyComponent } from './apikeys/crear-editar-apikey/crear-editar-apikey.component';
import { CrearEditarClienteComponent } from './clientes/crear-editar-cliente/crear-editar-cliente.component';
import { CrearEditarTrackerComponent } from './trackers/crear-editar-tracker/crear-editar-tracker.component';
import { ListadoUsuariosComponent } from './usuarios/listado-usuarios/listado-usuarios.component';
import { CrearEditarUsuarioComponent } from './usuarios/crear-editar-usuario/crear-editar-usuario.component';
import { ListadoTrackersComponent } from './trackers/trackers/trackers.component';
import { DetallesTrackersComponent } from './trackers/detalles-tracker/detalles-trackers.component';
import { DetallesTrackerEventosDetallesComponent } from './trackers/detalles-tracker-eventos/detalles-tracker-eventos-detalles/detalles-tracker-eventos-detalles.component';
import { ServiciosComponent } from './servicios-contratados/servicios/servicios.component';
import { CrearEditarServiciosComponent } from './servicios-contratados/crear-editar-servicios/crear-editar-servicios.component';
import { DetallesClienteComponent } from './clientes/clientes/detalles-cliente/detalles-cliente.component';
import { ListadoClientesComponent } from './clientes/clientes/clientes-listado/clientes-listado.component';

const routes: Routes = [
  { path: '', redirectTo: 'usuarios', pathMatch: 'full' },
  // Usuarios
  { path: 'usuarios', component: ListadoUsuariosComponent },
  {
    path: 'usuarios/crear',
    component: CrearEditarUsuarioComponent,
    canActivate: [],
  },
  {
    path: 'usuarios/editar/:id',
    component: CrearEditarUsuarioComponent,
    canActivate: [],
  },
  // Clientes
  {
    path: 'clientes',
    component: ListadoClientesComponent,
    canActivate: [],
  },
  {
    path: 'clientes/crear',
    component: CrearEditarClienteComponent,
    canActivate: [],
  },
  {
    path: 'clientes/editar/:id',
    component: CrearEditarClienteComponent,
    canActivate: [],
  },
  {
    path: 'clientes/detalles/:id',
    component: DetallesClienteComponent,
    canActivate: [],
  },
  // Apikeys
  { path: 'apikeys', component: ApikeysComponent },
  {
    path: 'apikeys/crear',
    component: CrearEditarApikeyComponent,
    canActivate: [],
  },
  {
    path: 'apikeys/editar/:id',
    component: CrearEditarApikeyComponent,
    canActivate: [],
  },

  // Trackers
  {
    path: 'trackers',
    component: ListadoTrackersComponent,
    canActivate: [],
  },
  {
    path: 'trackers/crear',
    component: CrearEditarTrackerComponent,
    canActivate: [],
  },
  {
    path: 'trackers/editar/:id',
    component: CrearEditarTrackerComponent,
    canActivate: [],
  },
  {
    path: 'trackers/detallesTracker/:id',
    component: DetallesTrackersComponent,
    canActivate: [],
  },
  {
    path: 'trackers/detallesTracker/:id/detalles/:id',
    component: DetallesTrackerEventosDetallesComponent,
    canActivate: [],
  },
  // Servicios COntratados
  {
    path: 'servicios',
    component: ServiciosComponent,
    canActivate: [],
  },
  {
    path: 'servicios/crear',
    component: CrearEditarServiciosComponent,
    canActivate: [],
  },
  {
    path: 'servicios/editar/:id',
    component: CrearEditarServiciosComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdministracionRoutingModule {}
