<br />
<!-- <div
  style="display: flex; align-items: center; white-space: nowrap; width: 100%"
> -->
<!-- <div style="width: 100%; display: flex; justify-content: right">

</div> -->
<div style="display: grid; align-items: center; min-height: 40px">
  @if (nombreCliente != "") {
    <h1 style="margin-right: 50px">{{ nombreCliente }}</h1>
    <div>
      <app-button
        matType="mat-mini-fab"
        color="accent"
        icon="search"
        matTooltip="Detalles"
        ngClass="grid-item"
        (click)="notas()"
        style="margin-right: 4px"
      ></app-button>
      <app-button
        matType="mat-mini-fab"
        color="accent"
        icon="edit"
        matTooltip="Editar"
        ngClass="grid-item"
        (click)="editar()"
        style="margin-right: 4px"
      ></app-button>
      <app-button
        matType="mat-mini-fab"
        color="warn"
        icon="delete"
        matTooltip="Eliminar"
        ngClass="grid-item"
        (click)="eliminar()"
      ></app-button>
    </div>
  }
  <div style="justify-self: right; position: absolute">
    <app-button matType="mat-fab" icon="add" color="accent" (click)="crear()">
    </app-button>
  </div>
</div>
<!-- </div> -->
<figure class="highcharts-figure">
  <div id="container"></div>
</figure>
