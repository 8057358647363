import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentosService } from '../documentos.service';
import {
  ICreateDocumentacion,
  ICreateRecordatorio,
  IDocumentacion,
  IUpdateDocumentacion,
  TipoDocumentacion,
} from 'modelos/src';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { RecordatoriosService } from '../../recordatorios/recordatorios.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';

@Component({
  selector: 'app-crear-editar-documento',
  templateUrl: './crear-editar-documento.component.html',
  styleUrl: './crear-editar-documento.component.scss',
})
export class CrearEditarDocumentoComponent implements OnInit {
  public form?: FormGroup;
  public title?: string;
  public tipos: TipoDocumentacion[] = ['Seguro', 'Licencia'];
  public id?: string | null;
  public tipo?: string | null;
  public operacion?: string | null;
  public documento?: IDocumentacion;
  public loading: boolean = false;
  constructor(
    private service: DocumentosService,
    private route: ActivatedRoute,
    public helper: HelperService,
    private serviceR: RecordatoriosService,
    private dialogService: DialogService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      idChofer: new FormControl(this.documento?.idChofer),
      tipo: new FormControl(this.documento?.tipo, Validators.required),
      idActivo: new FormControl(this.documento?.idActivo),
      descripcion: new FormControl(this.documento?.descripcion),
      emision: new FormControl(this.documento?.emision),
      vencimiento: new FormControl(this.documento?.vencimiento),
      imagenes: new FormControl(this.documento?.imagenes),
    });
  }

  get documentoImagenes() {
    return this.form?.get('imagenes') as FormGroup;
  }

  private async getDocumento(id: string) {
    this.documento = await this.service.getById(id);
  }
  //
  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title}`,
      `¿Desea ${this.operacion} el documento?`,
    );
    if (!res) return;
    try {
      const data = this.getData();
      console.log(data.imagenes);
      if (this.operacion == 'editar' && this.id) {
        // Update
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Documento actualizado');
        HelperService.volver();
      }
      if (this.operacion == 'crear') {
        // Create
        const doc = await this.service.create(data);
        const recordatorio: ICreateRecordatorio = {
          tipo: ['fecha'],
          fechaLimite: doc.vencimiento,
          idDocumentacion: doc._id,
        };
        await this.serviceR.create(recordatorio);
        this.helper.notifSuccess('Documento creado');
        HelperService.volver();
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public getData(): ICreateDocumentacion | IUpdateDocumentacion {
    const data: ICreateDocumentacion | IUpdateDocumentacion = this.form?.value;
    return data;
  }

  public loadingFile(bool: boolean) {
    this.loading = bool;
  }
  public onUrlChange(url: string) {
    const imagenes = this.form?.value.imagenes;
    if (imagenes) {
      imagenes.push(url);
      this.form?.patchValue({ imagenes: imagenes });
    } else this.form?.patchValue({ imagenes: [url] });
    console.log(this.form?.value.imagenes);
  }

  public eliminarImagen(index: number) {
    const imagenes = this.form?.value.imagenes;
    imagenes.splice(index, 1);
    this.form?.patchValue({ imagenes: imagenes });
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    const path = await firstValueFrom(this.route.url);
    this.id = params.get('id');
    this.tipo = params.get('tipo');
    if (this.id) {
      if (path[0].path == 'crear') {
        //CREAR
        this.title = 'Crear Documento';
        this.operacion = 'crear';
        this.createForm();
        if (this.tipo == 'usuario' && this.form)
          this.form.patchValue({
            idChofer: this.id,
          });
        if (this.tipo == 'vehiculo' && this.form) {
          this.form.patchValue({
            idActivo: this.id,
          });
        }
      }
      if (path[0].path == 'editar') {
        //EDITAR
        this.title = 'Editar Documento';
        this.operacion = 'editar';
        await this.getDocumento(this.id);
        this.createForm();
      }
    } else {
      this.helper.volver();
    }
  }
}
