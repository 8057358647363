import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IServicioContratado } from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { ServiciosContratadosService } from '../servicios-contratados.service';

@Component({
  selector: 'app-crear-editar-servicios',
  templateUrl: './crear-editar-servicios.component.html',
  styleUrl: './crear-editar-servicios.component.scss',
})
export class CrearEditarServiciosComponent implements OnInit, OnDestroy {
  public form?: FormGroup;

  public id?: string | null;
  public servicio?: IServicioContratado;
  public servicio$?: Subscription;

  public urlIcono?: string;

  constructor(
    private route: ActivatedRoute,
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: ServiciosContratadosService,
  ) {}

  private createForm() {
    // nombre?: string;
    // icono?: string;
    // costo?: string;
    this.form = new FormGroup({
      nombre: new FormControl(this.servicio?.nombre, [Validators.required]),
      icono: new FormControl(this.servicio?.icono),
      costo: new FormControl(this.servicio?.costo, [Validators.required]),
    });
  }

  private async listar() {
    this.servicio$?.unsubscribe();
    this.servicio$ = this.listados
      .subscribe<IServicioContratado>('servicioContratado', this.id!)
      .subscribe((data) => {
        this.servicio = data;
        console.log(`listado de servicioContratado`, data);
      });
    await this.listados.getLastValue('servicioContratado', this.id!);
  }

  public onUrlChange(url: string) {
    this.form.setValue({ ...this.form.value, icono: url });
  }

  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.id ? 'Editar' : 'Crear'} Servicio`,
      `¿Desea ${this.id ? 'editar' : 'crear'} servicio?`,
    );
    if (!res) return;
    try {
      const data = this.form?.value;
      if (this.id) {
        // Update
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Servicio actualizado');
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Servicio creado');
      }
      HelperService.volver();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  /////// HOOKS

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    if (this.id) {
      await this.listar();
      this.urlIcono = this.servicio?.icono;
    }
    this.createForm();
  }

  ngOnDestroy() {
    this.servicio$?.unsubscribe();
  }
}
