import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IQueryTraccar, Stop, Trip, IActivo } from 'modelos/src';
import { Subscription } from 'rxjs';
import { DateRange } from '../../../../../../../auxiliares/date-range-selector/date-range-selector.component';
import { ListadosService } from '../../../../../../../auxiliares/servicios/listados.service';

@Component({
  selector: 'app-controles',
  templateUrl: './controles.component.html',
  styleUrl: './controles.component.scss',
})
export class ControlesComponent implements OnInit, OnDestroy {
  @Input() vehiculo!: IActivo;

  public loadingViajes = false;
  public loadingParadas = false;

  public toggle = true; // True = Viajes, False = Paradas
  public rangoViajes?: DateRange;
  public rangoParadas?: DateRange;
  //
  @Input() viajeSelected?: Trip;
  @Output() viajeSelectedChange = new EventEmitter<Trip>();
  public viajes: Trip[] = [];
  public viajes$?: Subscription;

  @Input() paradaSelected?: Stop;
  @Output() paradaSelectedChange = new EventEmitter<Stop>();
  public paradas: Stop[] = [];
  public paradas$?: Subscription;
  constructor(private listados: ListadosService) {}

  private async listarViajes() {
    this.loadingViajes = true;
    if (!this.rangoViajes) {
      return;
    }
    const query: IQueryTraccar = {
      idVehiculo: this.vehiculo._id!,
      from: this.rangoViajes.start,
      to: this.rangoViajes.end,
    };
    this.viajes$?.unsubscribe();
    this.viajes$ = this.listados
      .subscribe<Trip[]>('trips', query)
      .subscribe((data) => {
        // sort by descending startTime
        this.viajes = data.sort((a, b) => {
          return a.startTime! > b.startTime! ? -1 : 1;
        });
        console.log(`listado de trips`, data);
        this.loadingViajes = false;
      });
    await this.listados.getLastValue('trips', query);
  }

  public async onViajesRangeChange(rango: DateRange) {
    this.rangoViajes = rango;
    await this.listarViajes();
  }

  public onViajesSelected(viaje: Trip, expanded: boolean) {
    if (expanded) {
      this.viajeSelected = viaje;
      this.viajeSelectedChange.emit(viaje);
    }
  }

  private async listarParadas() {
    this.loadingParadas = true;
    if (!this.rangoParadas) {
      return;
    }
    const query: IQueryTraccar = {
      idVehiculo: this.vehiculo._id!,
      from: this.rangoParadas.start,
      to: this.rangoParadas.end,
    };
    this.paradas$?.unsubscribe();
    this.paradas$ = this.listados
      .subscribe<Trip[]>('stops', query)
      .subscribe((data) => {
        // sort by descending startTime
        this.paradas = data.sort((a, b) => {
          return a.startTime! > b.startTime! ? -1 : 1;
        });
        console.log(`listado de stops`, data);
        this.loadingParadas = false;
      });
    await this.listados.getLastValue('stops', query);
  }

  public async onParadasRangeChange(rango: DateRange) {
    this.rangoParadas = rango;
    await this.listarParadas();
  }

  public onParadasSelected(parada: Stop, expanded: boolean) {
    if (expanded) {
      this.paradaSelected = parada;
      this.paradaSelectedChange.emit(parada);
    }
  }

  async ngOnInit(): Promise<void> {
    const to = new Date();
    const from = new Date(to);
    // from.setHours(from.getHours() - 48);
    from.setDate(from.getDate() - 2);

    const defaultRange: DateRange = {
      start: from.toISOString(),
      end: to.toISOString(),
    };
    this.rangoViajes = defaultRange;
    this.rangoParadas = defaultRange;

    await Promise.all([this.listarViajes(), this.listarParadas()]);
  }

  ngOnDestroy() {
    this.viajes$?.unsubscribe();
    this.paradas$?.unsubscribe();
  }
}
