import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ICreateDispositivoAlarma,
  IUpdateDispositivoAlarma,
  IDispositivoAlarma,
  IListado,
  IQueryParam,
  IModeloDispositivo,
  ICliente,
  IFilter,
  IPopulate,
  IUbicacion,
  IUpdateUbicacion,
  ICreateUbicacion,
  IGeoJSONPoint,
  TipoDispositivo,
  Operador,
} from 'modelos/src';
import { firstValueFrom, map, Observable, startWith, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { ActivatedRoute } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AlarmasDomiciliariasService } from '../alarmas-domiciliarias.service';
import { UbicacionService } from '../../../../../auxiliares/servicios/http/ubicacion.service';
import { LoginService } from '../../../../login/login.service';

@Component({
  selector: 'app-crear-editar-alarma-domiciliaria',
  templateUrl: './crear-editar-alarma-domiciliaria.component.html',
  styleUrl: './crear-editar-alarma-domiciliaria.component.scss',
})
export class CrearEditarAlarmaDomiciliariaComponent
  implements OnInit, OnDestroy
{
  public loading = false;
  public form?: FormGroup;
  public formDomicilio?: FormGroup;
  public title?: string;
  public height = HelperService.contentHeight;
  public id?: string | null;
  public domicilioExistente = false;

  public centrarA?: IGeoJSONPoint;
  public apns = [
    'igprs.claro.com.ar',
    'wap.grps.unifon.com.ar',
    'datos.personal.com',
  ];

  filteredOptionsSim1 = new Observable<string[]>();
  filteredOptionsSim2 = new Observable<string[]>();

  public operadores: Operador[] = [
    'Claro',
    'Movistar',
    'Personal',
    'Tuenti',
    'Otro',
  ];

  public dispositivoAlarma?: IDispositivoAlarma;
  public dispositivoAlarma$?: Subscription;

  public domicilio?: IUbicacion = null;
  public domicilioId: string = null;
  public domicilio$?: Subscription;

  public ubicaciones?: IUbicacion[] = [];
  public ubicaciones$?: Subscription;
  public modelosAlarmas?: IModeloDispositivo[] = [];
  public modelosAlarmas$?: Subscription;
  public clientes?: ICliente[] = [];
  public clientes$?: Subscription;
  public comunicadores?: IModeloDispositivo[] = [];
  public comunicadores$?: Subscription;

  public miCliente = LoginService.getCliente();
  public clientesQuePuedenAtender?: ICliente[] = [this.miCliente];

  get sim1() {
    return this.form?.get('sim1') as FormGroup;
  }

  get sim2() {
    return this.form?.get('sim2') as FormGroup;
  }

  get geojson() {
    return this.formDomicilio?.get('geojson') as FormGroup;
  }

  constructor(
    public helper: HelperService,
    private service: AlarmasDomiciliariasService,
    private ubicacionService: UbicacionService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private listados: ListadosService,
    private route: ActivatedRoute,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      nombre: new FormControl(this.dispositivoAlarma?.nombre),
      idUnicoComunicador: new FormControl(
        this.dispositivoAlarma?.idUnicoComunicador,
      ),
      idComunicador: new FormControl(this.dispositivoAlarma?.idComunicador),
      passwordComunicador: new FormControl(
        this.dispositivoAlarma?.passwordComunicador,
      ),
      idModelo: new FormControl(
        this.dispositivoAlarma?.idModelo,
        Validators.required,
      ),
      idDomicilio: new FormControl(this.dispositivoAlarma?.idDomicilio),
      idCliente: new FormControl(
        this.dispositivoAlarma?.idCliente,
        Validators.required,
      ),
      numeroAbonado: new FormControl(this.dispositivoAlarma?.numeroAbonado),
      sim1: new FormGroup({
        iccid: new FormControl(this.dispositivoAlarma?.sim1?.iccid),
        numero: new FormControl(this.dispositivoAlarma?.sim1?.numero),
        operador: new FormControl(this.dispositivoAlarma?.sim1?.operador),
        apn: new FormControl(this.dispositivoAlarma?.sim1?.apn),
        usuario: new FormControl(this.dispositivoAlarma?.sim1?.usuario),
        password: new FormControl(this.dispositivoAlarma?.sim1?.password),
      }),
      sim2: new FormGroup({
        iccid: new FormControl(this.dispositivoAlarma?.sim2?.iccid),
        numero: new FormControl(this.dispositivoAlarma?.sim2?.numero),
        operador: new FormControl(this.dispositivoAlarma?.sim2?.operador),
        apn: new FormControl(this.dispositivoAlarma?.sim2?.apn),
        usuario: new FormControl(this.dispositivoAlarma?.sim2?.usuario),
        password: new FormControl(this.dispositivoAlarma?.sim2?.password),
      }),
      idsClientesQuePuedenAtender: new FormControl(
        this.dispositivoAlarma?.idsClientesQuePuedenAtender || [
          this.miCliente._id,
        ],
      ),
    });
  }
  private createFormDomicilio() {
    this.formDomicilio = new FormGroup({
      idCliente: new FormControl(
        this.domicilio?.idCliente || this.dispositivoAlarma?.idCliente,
      ),
      direccion: new FormControl(this.domicilio?.direccion),
      identificacion: new FormControl(this.domicilio?.identificacion),
      geojson: new FormGroup({
        type: new FormControl('Point'),
        coordinates: new FormControl(this.domicilio?.geojson?.coordinates),
      }),
      categoria: new FormControl('Domicilio'),
    });
  }
  public async listar(): Promise<void> {
    const filter: IFilter<IDispositivoAlarma> = {
      _id: this.id,
    };
    const populate: IPopulate[] = [
      {
        path: 'modelo',
      },
      {
        path: 'domicilio',
      },
      {
        path: 'cliente',
      },
    ];
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      includeChildren: true,
    };

    this.dispositivoAlarma$?.unsubscribe();
    this.dispositivoAlarma$ = this.listados
      .subscribe<IListado<IDispositivoAlarma>>('dispositivosAlarmas', query)
      .subscribe((data) => {
        if (data.datos[0]) this.dispositivoAlarma = data.datos[0];
        console.log(`listado de dispositivosAlarmas`, data);
      });
    await this.listados.getLastValue('dispositivosAlarmas', query);
  }

  public async listarModelos(): Promise<void> {
    const tipo: TipoDispositivo = 'Alarma';
    const filter: IFilter<IModeloDispositivo> = { tipo };
    const query: IQueryParam = { filter: JSON.stringify(filter) };
    this.modelosAlarmas$?.unsubscribe();
    this.modelosAlarmas$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', query)
      .subscribe((data) => {
        this.modelosAlarmas = data.datos;
        console.log(`listado de modelos de alarma`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', query);
  }
  public async listarComunicadores(): Promise<void> {
    const tipo: TipoDispositivo = 'Comunicador';
    const filter: IFilter<IModeloDispositivo> = { tipo };
    const query: IQueryParam = { filter: JSON.stringify(filter) };
    this.comunicadores$?.unsubscribe();
    this.comunicadores$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', query)
      .subscribe((data) => {
        this.comunicadores = data.datos;
        console.log(`listado de comunicadores`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', query);
  }
  public async listarClientes(): Promise<void> {
    const query: IQueryParam = { includeChildren: true };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  public async listarDomicilios(): Promise<void> {
    const filter: IFilter<IUbicacion> = { categoria: 'Domicilio' };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      includeChildren: true,
    };
    this.ubicaciones$ = this.listados
      .subscribe<IListado<IUbicacion>>('ubicacions', query)
      .subscribe((data) => {
        this.ubicaciones = data.datos;
        console.log(`listado de ubicacions`, data);
      });
    await this.listados.getLastValue('ubicacions', query);
  }

  public onCheckboxChange(e: MatCheckboxChange) {
    this.domicilioExistente = e.checked;
    if (this.domicilioExistente) {
      this.domicilio = this.dispositivoAlarma?.domicilio;
    } else {
      this.domicilio = null;
    }
    this.formDomicilio.reset();
  }

  public onClick(e: IUbicacion) {
    if (e) {
      this.centrarA = e.geojson as IGeoJSONPoint;
      this.domicilio = e;
    }
  }

  public onClienteChange(cliente: ICliente) {
    const clientesPadres: ICliente[] = [cliente];
    let idPadre = cliente.idPadre;
    while (idPadre) {
      const clientePadre = this.clientes.find((c) => c._id === idPadre);
      if (clientePadre) {
        clientesPadres.push(clientePadre);
      }
      idPadre = clientePadre?.idPadre;
    }
    this.clientesQuePuedenAtender = clientesPadres;

    // Resetea los clientes que pueden atender
    this.form
      ?.get('idsClientesQuePuedenAtender')
      ?.setValue([this.miCliente._id]);
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.id) {
        // Update
        if (!this.domicilioExistente) {
          const data2 = this.getDataUbicacion();
          if (data2.direccion) {
            const dom = await this.ubicacionService.create(
              this.getDataUbicacion(),
            );
            data.idDomicilio = dom._id;
          }
        } else {
          if (this.domicilio && this.domicilio._id !== data.idDomicilio) {
            data.idDomicilio = this.domicilio._id;
          }
        }
        await this.service.update(this.id, data);
        this.helper.volver();
      } else {
        // Create
        //Domicilio ya existente
        if (this.domicilio) {
          data.idDomicilio = this.domicilio._id;
        } else {
          //Domicilio nuevo
          const data2 = this.getDataUbicacion();
          if (data2) {
            const dom = await this.ubicacionService.create(
              this.getDataUbicacion(),
            );
            data.idDomicilio = dom._id;
          } else {
            this.helper.notifWarn('Debe ingresar un Domicilio');
            return;
          }
        }
        await this.service.create(data);
        this.helper.notifSuccess('Codigos Alarma creado');
        this.helper.volver();
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateDispositivoAlarma | IUpdateDispositivoAlarma {
    const data: ICreateDispositivoAlarma | IUpdateDispositivoAlarma =
      this.form?.value;
    return data;
  }

  public getDataUbicacion() {
    const data: ICreateUbicacion | IUpdateUbicacion = this.formDomicilio?.value;
    data.identificacion = data.direccion;
    return data;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.apns.filter((option) =>
      option.toLowerCase().includes(filterValue),
    );
  }

  public volver() {
    this.helper.volver();
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    if (this.id) {
      await this.listar();
      this.domicilio = this.dispositivoAlarma?.domicilio;
      this.domicilioExistente = this.dispositivoAlarma?.domicilio
        ? true
        : false;
      this.domicilioId = this.domicilio?._id;
    }

    await Promise.all([
      this.listarModelos(),
      this.listarClientes(),
      this.listarDomicilios(),
      this.listarComunicadores(),
    ]);

    this.title = this.id
      ? `Editar ${this.dispositivoAlarma?.nombre}`
      : 'Crear Alarma';
    this.createFormDomicilio();
    this.createForm();
    this.filteredOptionsSim1 = this.sim1.get('apn').valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );

    this.filteredOptionsSim2 = this.sim2.get('apn').valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );
  }

  ngOnDestroy(): void {
    this.modelosAlarmas$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
