import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IDispositivoAlarma,
  IFilter,
  IListado,
  IQueryParam,
  IPopulate,
  ICliente,
  IModeloDispositivo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlarmasDomiciliariasService } from '../alarmas-domiciliarias.service';
import { ConfigurarComunicadorComponent } from '../../configurar-comunicador/configurar-comunicador.component';

@Component({
  selector: 'app-listado-alarmas-domiciliarias',
  templateUrl: './listado-alarmas-domiciliarias.component.html',
  styleUrl: './listado-alarmas-domiciliarias.component.scss',
})
export class ListadoAlarmasDomiciliariasComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoAlarmasDomiciliariasComponent.name;
  public alarmasDomiciliarias: IDispositivoAlarma[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<IDispositivoAlarma>[];
  //Subs
  public modelo$?: Subscription;
  public alarmasDomiciliarias$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Alarma Domiciliaria',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public modelo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idModelo',
    },
    label: 'Modelo',
    selectLabel: 'marca',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public filtros: IFiltroTabla[] = [this.modelo];
  //Query
  public filter: IFilter<IDispositivoAlarma> = {};
  public populate: IPopulate[] = [
    {
      path: 'domicilio',
    },
    {
      path: 'modelo',
    },
    {
      path: 'cliente',
    },
    {
      path: 'comunicador',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: AlarmasDomiciliariasService,
    public dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar(), this.listarClientes(), this.listarModelos()]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    if (this.query.filter) {
      const filter: IFilter<IDispositivoAlarma> = JSON.parse(this.query.filter);
      this.query.filter = JSON.stringify(filter);
    }
    this.alarmasDomiciliarias$?.unsubscribe();
    this.alarmasDomiciliarias$ = this.listados
      .subscribe<
        IListado<IDispositivoAlarma>
      >('dispositivosAlarmas', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.alarmasDomiciliarias = data.datos;
        console.log(`listado de dispositivosAlarmas`, data);
      });
    await this.listados.getLastValue('dispositivosAlarmas', this.query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }
  private async listarModelos(): Promise<void> {
    const query: IQueryParam = {
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.modelo$?.unsubscribe();
    this.modelo$ = this.listados
      .subscribe<IListado<IModeloDispositivo>>('modeloDispositivos', query)
      .subscribe((data) => {
        this.modelo.elementos = data.datos;
        console.log(`listado de modleosDispositivos`, data);
      });
    await this.listados.getLastValue('modeloDispositivos', query);
  }
  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(dispositivoAlarma: IDispositivoAlarma) {
    this.navigator.navigate(['editar', dispositivoAlarma._id], {
      relativeTo: this.route,
    });
  }

  public async detalles(dispositivoAlarma: IDispositivoAlarma) {
    this.navigator.navigate(['detallesAlarma', dispositivoAlarma._id], {
      relativeTo: this.route,
    });
  }

  public async configurarComunicador(dispositivoAlarma: IDispositivoAlarma) {
    this.dialog.open(ConfigurarComunicadorComponent, {
      data: dispositivoAlarma,
      width: '850px',
    });
  }

  public parseDomicilio(dato: IDispositivoAlarma) {
    if (dato.domicilio) {
      let text = dato.domicilio?.direccion;
      if (text.length >= 50) {
        text = text.slice(0, 50);
        text += '...';
      }
      return text;
    } else {
      return '';
    }
  }

  public async eliminar(dispositivoAlarma: IDispositivoAlarma) {
    const res = await this.dialogService.confirm(
      'Eliminar Alarma Domiciliaria',
      `¿Desea eliminar la alarma ${dispositivoAlarma.modelo?.marca} ${dispositivoAlarma.modelo?.modelo} | ${dispositivoAlarma.domicilio?.direccion}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(dispositivoAlarma._id!);
      this.helper.notifSuccess('Alarma domiciliaria eliminada');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Nombre',
        },
        row: {
          field: 'nombre',
        },
      },
      {
        header: {
          label: 'Alarma',
        },
        row: {
          parse(dato) {
            if (dato.modelo) {
              let text = `${dato.modelo?.marca} - ${dato.modelo?.modelo}`;
              if (text.length >= 50) {
                text = text.slice(0, 50);
                text += '...';
              }
              return text;
            }
            return '';
          },
        },
      },
      {
        header: {
          label: 'Cliente',
          sortable: true,
        },
        row: {
          parse(dato) {
            if (dato.cliente?.nombre) {
              let text = dato.cliente?.nombre;
              if (text.length >= 50) {
                text = text.slice(0, 50);
                text += '...';
              }
              return text;
            }
            return '';
          },
        },
      },
      {
        header: {
          label: 'Domicilio',
          sortable: true,
        },
        row: {
          parse: (dato) => this.parseDomicilio(dato),
          noData: 'Sin Domicilio',
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'accent',
              icon: 'search',
              tooltip: 'Detalles',
              click: (dato) => this.detalles(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'accent',
              icon: 'tune',
              tooltip: 'Configurar Comunicador',
              click: (dato) => this.configurarComunicador(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    ];
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listarTodo();
  }

  async ngOnDestroy() {
    this.alarmasDomiciliarias$?.unsubscribe();
  }
}
