import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentosService } from '../documentos.service';
import { IDocumentacion } from 'modelos/src';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrl: './documento.component.scss',
})
export class DocumentoComponent implements OnInit, OnDestroy {
  public id?: string | null;
  public tipo?: string | null;
  public documento?: IDocumentacion;

  constructor(
    private service: DocumentosService,
    private route: ActivatedRoute,
    public helper: HelperService,
    private navigator: Router,
  ) {}

  private async getDocumento(id: string) {
    this.documento = await this.service.getById(id);
  }

  public editar() {
    console.log(this.route.url);
    this.navigator.navigate([
      '/',
      'main',
      'documentos',
      'editar',
      this.tipo,
      this.id,
    ]);
  }
  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.tipo = params.get('tipo');
    if (this.id) {
      this.getDocumento(this.id);
    }
  }

  ngOnDestroy(): void {
    console.log('exit');
  }
}
