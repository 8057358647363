import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICliente, IDispositivoAlarma, IParada, IReporte } from 'modelos/src';
import { LoadingService } from '../../../../../auxiliares/servicios/loading.service';
import { LoginService } from '../../../../login/login.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-isla-alarma',
  templateUrl: './isla-alarma.component.html',
  styleUrl: './isla-alarma.component.scss',
})
export class IslaAlarmaComponent {
  @Input() public alarma?: IDispositivoAlarma;
  @Output() public alarmaChange = new EventEmitter<IReporte>();

  public parada?: IParada;
  public idxParada?: number;
  public cliente?: ICliente;

  constructor(
    public loading: LoadingService,
    public dialog: MatDialog,
    private navigator: Router,
  ) {
    this.cliente = LoginService.getCliente();
  }

  public close() {
    this.alarma = undefined;
    this.alarmaChange.emit(this.alarma);
  }

  public async editar() {
    this.navigator.navigate(
      ['main/alarmas/alarmasDomiciliarias/editar', this.alarma._id],
      {},
    );
  }
}
