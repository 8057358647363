@if (parada) {
  <div class="column-center" style="gap: 3px; width: 100%">
    <div class="row-space-between" style="width: 100%">
      <span matTooltip="Hasta" style="cursor: default">📆</span>
      <span>{{ parada.endTime | date: "short" }}</span>
    </div>
    @if (parada.duration) {
      <div class="row-space-between" style="width: 100%">
        <span matTooltip="Duración" style="cursor: default">⌛</span>
        <span>{{ this.helper.parseMs(parada.duration) }}</span>
      </div>
    }
  </div>
}
