@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      @if (id) {
        <span class="titulo">Editar {{ usuario?.usuario }}</span>
      } @else {
        <span class="titulo">Crear Usuario</span>
      }
    </div>
    <br />
    <div
      class="column-center mat-elevation-z1"
      style="gap: 15px; padding: 0.5em"
    >
      <div class="row-flex-start" style="width: 100%">
        <span class="subtitulo"> Datos de Usuario </span>
      </div>
      <!-- Nombre y Contraseña -->
      <section class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="usuario" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Contraseña</mat-label>
            <input matInput formControlName="hash" />
          </mat-form-field>
        </div>
      </section>

      <!-- Cliente // Roles -->
      <section class="column-center" style="width: 100%; gap: 16px">
        @if (!id) {
          <ng-select
            [items]="clientes"
            bindLabel="nombre"
            bindValue="_id"
            formControlName="idCliente"
            placeholder="Cliente"
            [notFoundText]="'No hay clientes creados'"
            style="width: 100%"
            multiple="false"
            searchable="true"
          >
          </ng-select>
        }
        <ng-select
          [items]="roles"
          formControlName="roles"
          [multiple]="true"
          placeholder="Rol"
          style="width: 100%"
          [hideSelected]="true"
          required
        >
        </ng-select>
      </section>
    </div>
    <br />
    @if (form.get("roles")?.value?.includes("Consultor")) {
      <div
        class="column-center mat-elevation-z1"
        style="gap: 15px; padding: 0.5em"
        [formGroup]="permisos"
      >
        <div class="row-flex-start" style="width: 100%">
          <span class="subtitulo"> Entidades </span>
        </div>
        <ng-select
          [items]="datos"
          bindLabel="nombre"
          bindValue="id"
          groupBy="grupo"
          formControlName="idsEntidades"
          multiple
          style="width: 100%"
          (change)="onEntidadesChange($event)"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.grupo || "Sin Grupo" }}
          </ng-template>
        </ng-select>
      </div>
    }
    <br />
    <div
      class="column-center mat-elevation-z1"
      style="gap: 15px; padding: 0.5em"
      [formGroup]="permisos"
    >
      <div class="row-flex-start" style="width: 100%">
        <span class="subtitulo"> Módulos </span>
      </div>
      <div class="row-space-around" style="width: 100%">
        <mat-checkbox formControlName="moduloActivos"
          >Gestión de Activos</mat-checkbox
        >
        <mat-checkbox formControlName="moduloColectivos"
          >Trasporte Público</mat-checkbox
        >
        <mat-checkbox formControlName="moduloAlarmasDomiciliarias"
          >Gestión de Alarma</mat-checkbox
        >
      </div>
    </div>
    <br />
    <div
      class="column-center mat-elevation-z1"
      style="gap: 15px; padding: 0.5em"
      [formGroup]="datosPersonales"
    >
      <div class="row-flex-start" style="width: 100%">
        <span class="subtitulo"> Datos Personales </span>
      </div>
      <!-- Nombre - DNI - SEXO -->
      <section class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="nombre" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>DNI</mat-label>
            <input matInput formControlName="dni" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Sexo</mat-label>
            <mat-select formControlName="sexo">
              @for (s of sexos; track s) {
                <mat-option [value]="s.value">{{ s.label }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </section>

      <!-- EMAIL - DIRECCIÓN - PAÍS -->
      <section class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Dirección</mat-label>
            <input matInput formControlName="direccion" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>País</mat-label>
            <input matInput formControlName="pais" />
          </mat-form-field>
        </div>
      </section>

      <!-- TELÉFONO - FECHA DE NACIMIENTO -->
      <section class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Teléfono</mat-label>
            <input matInput formControlName="telefono" />
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Fecha de Nacimiento</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="fechaNacimiento"
            />
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </section>
    </div>

    <br />
    <!-- Botones  -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>
  </form>
}
