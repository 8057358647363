<div (click)="bringToFront()">
  <mat-card style="border: 1px solid black">
    <!-- Header -->
    <mat-card-header
      style="border-radius: 2px 2px 0 0"
      [style.background-color]="data.valores?.color"
      [style.color]="helper.colorDeTextoSegunColor(data.valores?.color)"
    >
      <mat-card-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
        [cdkDragBoundary]="dragBoundary"
        (cdkDragStarted)="bringToFront()"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <span style="padding-left: 8px">
          {{ data?.valores.titulo }} - {{ data?.cliente?.nombre }}
        </span>
        @if (estadoDeCuenta) {
          <span>({{ estadoDeCuenta }})</span>
        }
        <div (click)="closeDialog()" class="icon-close">
          <mat-icon> close </mat-icon>
        </div>
      </mat-card-title>
    </mat-card-header>
    <!-- Content -->
    <mat-card-content style="margin: 1em 0; max-height: 30vh; overflow: auto">
      <div>
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 1.5em;
            justify-content: space-between;
            margin-bottom: 1em;
          "
        >
          <!-- Informacion del evento -->
          <div style="display: flex; flex-direction: column">
            <!-- Detalles -->
            <span>
              {{ data.valores?.mensaje || data.valores?.["detalles"] }}
            </span>

            <!-- Activo -->
            @if (data.activo) {
              <span>{{ data.activo.identificacion }}</span>
            }

            <!-- Vehiculo -->
            @if (data.activo?.vehiculo) {
              <span>
                {{ data.activo?.vehiculo?.tipo }} -
                {{ data.activo?.vehiculo?.patente }}
              </span>
            }

            <!-- Alarma -->
            @if (data.alarma) {
              <!-- Comunicador - Alarma -->
              @if (data.alarma?.comunicador || data.alarma?.modelo) {
                <span>
                  {{ data.alarma?.comunicador?.marca }}
                  {{ data.alarma?.comunicador?.modelo }} -
                  {{ data.alarma?.modelo?.marca }}
                  {{ data.alarma?.modelo?.modelo }}
                </span>
              }
              <!-- Domicilio -->
              @if (data.alarma?.domicilio) {
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  {{ data.alarma?.domicilio.identificacion }}
                  @if (data.alarma?.domicilio.fotos?.length) {
                    <div
                      (click)="verFotos(); $event.stopPropagation()"
                      class="icon-map"
                      matTooltip="Ver fotos"
                      style="scale: 0.75"
                    >
                      <mat-icon>image</mat-icon>
                    </div>
                  }
                </div>
              }
            }
          </div>

          <!-- Ubicacion -->
          <div
            (click)="verUbicacion(); $event.stopPropagation()"
            class="icon-map"
            matTooltip="Ver ubicación"
          >
            <mat-icon>map</mat-icon>
          </div>

          <!-- Servicios Contratados -->
          <div
            style="
              display: flex;
              flex-direction: row;
              gap: 0.5em;
              justify-content: flex-end;
            "
          >
            @for (servicio of serviciosContratados; track servicio) {
              <img
                [src]="servicio.icono"
                [alt]="servicio.nombre"
                [matTooltip]="servicio.nombre"
                width="35px"
                height="35px"
              />
            }
          </div>
        </div>

        <mat-divider></mat-divider>

        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 1.5em;
            margin-top: 1em;
          "
        >
          <!-- Tratamientos -->
          <div class="contenidos" style="min-width: 250px">
            <span class="titulo"> Tratamientos </span>
            <div style="margin-top: 0.5em">
              @for (tratamiento of tratamientosEvento; track tratamiento) {
                <div
                  style="display: flex; flex-direction: column"
                  [class.even]="$even"
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span class="estado">{{ tratamiento.estado }}</span>
                    <span style="font-size: 14px">
                      {{ tratamiento.fechaCreacion | date: "short" }}
                    </span>
                  </div>
                  <span class="usuario">
                    {{ tratamiento.usuario?.usuario || "Sistema" }}
                  </span>
                  @if (tratamiento.nota) {
                    <span style="margin-top: 0.25em">
                      {{ tratamiento.nota }}
                    </span>
                  }
                  @if (tratamiento.notaInterna) {
                    <span style="margin-top: 0.25em">
                      Nota Interna: {{ tratamiento.notaInterna }}
                    </span>
                  }
                </div>
                <mat-divider class="divider"></mat-divider>
              }
            </div>
          </div>

          <!-- Notas -->
          @if (notas.length) {
            <div class="contenidos" style="min-width: 200px">
              <span class="titulo">Notas</span>
              <div style="margin-top: 0.5em">
                @for (nota of notas; track nota) {
                  <div
                    style="display: flex; flex-direction: column"
                    [class.even]="$even"
                  >
                    <span>{{ nota.informacion?.nota }}</span>
                  </div>
                  <mat-divider class="divider"></mat-divider>
                }
              </div>
            </div>
          }

          <!-- Contactos -->
          @if (contactos.length) {
            <div class="contenidos" style="min-width: 200px">
              <span class="titulo">Contactos</span>
              <div style="margin-top: 0.5em">
                @for (contacto of contactos; track contacto) {
                  <div
                    style="display: flex; flex-direction: column"
                    [class.even]="$even"
                  >
                    <div
                      style="
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                      "
                    >
                      <span class="estado">
                        {{ contacto.informacion?.contacto }}
                      </span>
                      <span style="font-size: 14px">
                        {{ contacto.informacion?.telefono }}
                      </span>
                    </div>
                    <span>
                      Palabra Seguridad:
                      {{ contacto.informacion?.palabraSeguridadNormal }}
                    </span>
                    <span>
                      Palabra Emergencia:
                      {{ contacto.informacion?.palabraSeguridadEmergencia }}
                    </span>
                    <!-- <span>Número de usuario: {{ contacto.informacion?.numeroUsuarioAlarma }}</span> -->
                  </div>
                  <mat-divider class="divider"></mat-divider>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </mat-card-content>

    <!-- Acciones -->
    <mat-dialog-actions
      style="
        display: flex;
        justify-content: space-between;
        padding: 6px;
        gap: 8px;
      "
    >
      <!-- Tratar -->
      @if (
        !tratando &&
        estadoActual !== "Finalizada" &&
        estadoActual !== "En Espera"
      ) {
        <app-button
          matType="mat-fab extended"
          color="accent"
          text="Tratar"
          icon="monitor_heart"
          (click)="tratar(); $event.stopPropagation()"
        ></app-button>
      }

      <!-- Tratamientos -->
      @if (
        tratando &&
        estadoActual !== "Finalizada" &&
        estadoActual !== "En Espera"
      ) {
        <!-- Agregar Nota  -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          (click)="agregarNota(); $event.stopPropagation()"
          text="Agregar nota"
          icon="add"
        ></app-button>
        <!-- Liberar -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          text="Liberar"
          icon="arrow_upward_alt"
          (click)="liberar(); $event.stopPropagation()"
        ></app-button>
        <!-- Espera -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          text="Espera"
          icon="undo"
          (click)="espera(); $event.stopPropagation()"
        >
        </app-button>
        <!-- Finalizar -->
        <app-button
          matType="mat-fab extended"
          color="accent"
          text="Finalizar"
          icon="check_circle"
          (click)="finalizar(); $event.stopPropagation()"
        ></app-button>
      }

      <!-- Finalizada -->
      @if (estadoActual === "Finalizada") {
        <h2>Evento finalizado</h2>
      }

      <!-- En espera -->
      @if (estadoActual === "En Espera") {
        <h2>Evento en espera</h2>
      }
    </mat-dialog-actions>
  </mat-card>
</div>
