/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AuxiliaresModule } from '../auxiliares.module';
import { Feature, Map, View } from 'ol';
import Draw from 'ol/interaction/Draw';
import {
  Circle,
  Geometry,
  LineString,
  MultiPolygon,
  Point,
  Polygon,
} from 'ol/geom';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import { Modify, Snap } from 'ol/interaction';
import { HelperService } from '../servicios/helper.service';
import { FlatStyle } from 'ol/style/flat';
import { OpenLayersService } from '../servicios/openLayers.service';
import { FormGroup } from '@angular/forms';
import {
  IGeoJSONCircle,
  IGeoJSONLineString,
  IGeoJSONMultiPolygon,
  IGeoJSONPoint,
  IGeoJSONPolygon,
} from 'modelos/src';

@Component({
  selector: 'app-map-draw',
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './map-draw.component.html',
  styleUrl: './map-draw.component.scss',
})
export class MapDrawComponent implements OnInit, OnChanges {
  private modificacionLocal = false;
  @Input() form?: FormGroup;
  @Input() centrarA?: IGeoJSONPoint;
  @Input() public tipo:
    | 'Point'
    | 'LineString'
    | 'Polygon'
    | 'Circle'
    | 'MultiPolygon';
  @Input() public color?: string = '#000000';

  @Input() public point?: IGeoJSONPoint;
  @Output() public pointChange = new EventEmitter<IGeoJSONPoint>();
  @Input() public lineString?: IGeoJSONLineString;
  @Output() public lineStringChange = new EventEmitter<IGeoJSONLineString>();
  @Input() public polygon?: IGeoJSONPolygon;
  @Output() public polygonChange = new EventEmitter<IGeoJSONPolygon>();
  @Input() public circle?: IGeoJSONCircle;
  @Output() public circleChange = new EventEmitter<IGeoJSONCircle>();
  @Input() public multiPolygon?: IGeoJSONMultiPolygon;
  @Output() public multiPolygonChange =
    new EventEmitter<IGeoJSONMultiPolygon>();

  public s: FlatStyle = {
    'fill-color': 'rgba(255, 255, 255, 0.2)',
    'stroke-color': '#ffcc33',
    'stroke-width': 2,
    'circle-radius': 7,
    'circle-fill-color': '#ffcc33',
    'circle-stroke-color': '#fff',
  };

  public draw?: Draw;
  public snap?: Snap;
  public modify?: Modify;
  public dibujando = false;

  private map?: Map;
  public drawLayer = OpenLayersService.drawVectorLayer();

  get editando(): boolean {
    return (
      !!this.point?.coordinates ||
      !!this.lineString?.coordinates ||
      !!this.polygon?.coordinates ||
      !!this.circle?.coordinates ||
      !!this.multiPolygon?.coordinates
    );
  }

  constructor(private helper: HelperService) {}

  private suscribePointChange() {
    this.pointChange.subscribe((point) => {
      if (this.form) {
        this.form.get('type')?.setValue('Point');
        this.form.get('coordinates')?.setValue(point.coordinates);
        this.form.get('radius')?.setValue(null);
      }
    });
  }
  private suscribeLineStringChange() {
    this.lineStringChange.subscribe((lineString) => {
      if (this.form) {
        this.form.get('type')?.setValue('LineString');
        this.form.get('coordinates')?.setValue(lineString.coordinates);
        this.form.get('radius')?.setValue(null);
      }
    });
  }
  private suscribePolygonChange() {
    this.polygonChange.subscribe((polygon) => {
      if (this.form) {
        this.form.get('type')?.setValue('Polygon');
        this.form.get('coordinates')?.setValue(polygon.coordinates);
        this.form.get('radius')?.setValue(null);
      }
    });
  }
  private suscribeCircleChange() {
    this.circleChange.subscribe((circle) => {
      if (this.form) {
        this.form.get('type')?.setValue('Point');
        this.form.get('coordinates')?.setValue(circle.coordinates);
        this.form.get('radius')?.setValue(circle.radius);
      }
    });
  }
  private suscribeMultiPolygonChange() {
    this.multiPolygonChange.subscribe((multiPolygon) => {
      if (this.form) {
        this.form.get('type')?.setValue('MultiPolygon');
        this.form.get('coordinates')?.setValue(multiPolygon.coordinates);
        this.form.get('radius')?.setValue(null);
      }
    });
  }

  private async initMap(): Promise<void> {
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      controls: [],
      layers: [OpenLayersService.mapTile(), this.drawLayer],
      target: 'map-draw',
      view: new View({
        projection: 'EPSG:4326', // GeoJSON
        center: await OpenLayersService.getCurrentPositionGeoJSON(),
        zoom: 13,
      }),
    });

    if (this.color) {
      this.s = {
        'fill-color': HelperService.hexToRgba(this.color, 0.2),
        'stroke-color': this.color,
        'stroke-width': 2,
        'circle-radius': 5,
        'circle-fill-color': this.color,
      };
      this.drawLayer.setStyle(this.s);
    }
  }

  private handleModify() {
    //
    this.snap = new Snap({
      source: this.drawLayer.getSource()!,
    });

    this.modify = new Modify({
      source: this.drawLayer.getSource()!,
    });

    this.map?.addInteraction(this.snap);
    this.map?.addInteraction(this.modify);

    this.handleDrawEnd();
    this.handleModifyEnd();
  }

  public async dibujar() {
    if (this.dibujando) return;

    // Editando
    if (this.editando) {
      // Tengo que elegir que estoy dibujando
      switch (this.tipo) {
        case 'Point':
        case 'Polygon':
        case 'Circle':
        case 'LineString': {
          this.map?.addInteraction(this.snap);
          this.map?.addInteraction(this.modify);
          break;
        }
        case 'MultiPolygon': {
          this.map?.addInteraction(this.draw);
          this.map?.addInteraction(this.snap);
          this.map?.addInteraction(this.modify);
          break;
        }
        default: {
          this.helper.notifError('Tipo de geometría no soportado');
          return;
        }
      }
    } else {
      this.map?.addInteraction(this.draw);
    }
    this.dibujando = true;
    this.setBounds();
  }

  private setBounds() {
    if (!this.editando) return;
    if (!this.map) return;
    const source = this.drawLayer.getSource();
    if (!source) return;
    const extent = source.getExtent();
    if (!extent?.length) return;
    if (extent[0] === Infinity) return;
    this.map.getView().fit(extent, { padding: [50, 50, 50, 50] });
    if (this.map.getView().getZoom() > 18) this.map.getView().setZoom(18);
  }

  private handleDrawEnd() {
    this.draw?.on('drawend', (event) => {
      this.modificacionLocal = true;
      switch (this.tipo) {
        case 'Point': {
          const p = event?.feature.getGeometry() as Point;
          if (!p) return;
          const point = p.getCoordinates() as [number, number];
          this.point.coordinates = point;
          this.pointChange.emit(this.point);
          break;
        }
        case 'Polygon': {
          const p = event?.feature.getGeometry() as Polygon;
          if (!p) return;
          const coords = p.getCoordinates() as [[number, number][]];
          this.polygon.coordinates = coords;
          this.polygonChange.emit(this.polygon);
          break;
        }
        case 'Circle': {
          const c = event?.feature.getGeometry() as Circle;
          if (!c) break;
          const coordinates = c.getCenter() as [number, number];
          const radius = c.getRadius();
          this.circle = { coordinates, radius, type: 'Point' };
          this.circleChange.emit(this.circle);
          break;
        }
        case 'LineString': {
          const l = event?.feature.getGeometry() as LineString;
          if (!l) break;
          const coords = l.getCoordinates() as [number, number][];
          this.lineString.coordinates = coords;
          this.lineStringChange.emit(this.lineString);
          break;
        }
        case 'MultiPolygon': {
          const multiPolygon: IGeoJSONMultiPolygon = {
            type: 'MultiPolygon',
            coordinates: [],
          };

          // Desde el evento
          const geometryEvent = event?.feature.getGeometry() as MultiPolygon;
          const coordsEvent = geometryEvent.getCoordinates() as number[][][][];
          if (!coordsEvent) break;
          for (const coord of coordsEvent) {
            multiPolygon.coordinates.push(coord);
          }

          // Desde el source
          const source = this.drawLayer.getSource();
          const features = source.getFeatures();
          for (const feature of features) {
            const geometry = feature?.getGeometry() as MultiPolygon;
            const coords = geometry?.getCoordinates() as number[][][][];
            if (!coords) continue;
            for (const coord of coords) {
              multiPolygon.coordinates.push(coord);
            }
          }

          //
          this.multiPolygon = multiPolygon;
          this.multiPolygonChange.emit(this.multiPolygon);
          break;
        }
        default: {
          this.helper.notifError('Tipo de geometría no soportado');
          break;
        }
      }
      this.desactivarModoEdicion();
    });
  }

  private handleModifyEnd() {
    this.modify?.on('modifyend', (event) => {
      this.modificacionLocal = true;
      switch (this.tipo) {
        case 'Point': {
          const l = event?.features?.getArray()[0]?.getGeometry() as Point;
          if (!l) return;
          const point = l.getCoordinates() as [number, number];
          this.point.coordinates = point;
          this.pointChange.emit(this.point);
          break;
        }
        case 'Polygon': {
          const l = event?.features?.getArray()[0]?.getGeometry() as Polygon;
          if (!l) return;
          const coords = l.getCoordinates() as [[number, number][]];
          this.polygon.coordinates = coords;
          this.polygonChange.emit(this.polygon);
          break;
        }
        case 'Circle': {
          const l = event?.features?.getArray()[0]?.getGeometry() as Circle;
          if (!l) return;
          const coordinates = l.getCenter() as [number, number];
          const radius = l.getRadius();
          this.circle = { coordinates, radius, type: 'Point' };
          this.circleChange.emit(this.circle);
          return;
        }
        case 'LineString': {
          const l = event?.features?.getArray()[0]?.getGeometry() as LineString;
          if (!l) return;
          const coords = l.getCoordinates() as [number, number][];
          this.lineString.coordinates = coords;
          this.lineStringChange.emit(this.lineString);
          return;
        }
        case 'MultiPolygon': {
          const multiPolygon: IGeoJSONMultiPolygon = {
            type: 'MultiPolygon',
            coordinates: [],
          };

          const source = this.drawLayer.getSource();
          const features = source.getFeatures();

          for (const feature of features) {
            const geometry = feature?.getGeometry() as any;
            if (!geometry) continue;

            const coords = geometry?.getCoordinates() as number[][][][];
            for (const coord of coords) {
              multiPolygon.coordinates.push(coord);
            }
          }

          this.multiPolygon = multiPolygon;
          this.multiPolygonChange.emit(this.multiPolygon);
          return;
        }

        default: {
          this.helper.notifError('Tipo de geometría no soportado');
          return;
        }
      }
    });
  }

  public async desactivarModoEdicion() {
    this.map?.removeInteraction(this.draw);
    this.map?.removeInteraction(this.modify);
    this.map?.removeInteraction(this.snap);
    this.dibujando = false;
  }

  public borrarDibujos() {
    this.drawLayer?.getSource()?.clear();
    this.point = { type: 'Point', coordinates: undefined };
    this.lineString = { type: 'LineString', coordinates: undefined };
    this.polygon = { type: 'Polygon', coordinates: undefined };
    this.circle = { type: 'Point', coordinates: undefined, radius: undefined };
    this.multiPolygon = { type: 'MultiPolygon', coordinates: undefined };
    this.pointChange.emit(this.point);
    this.lineStringChange.emit(this.lineString);
    this.polygonChange.emit(this.polygon);
    this.circleChange.emit(this.circle);
    this.multiPolygonChange.emit(this.multiPolygon);
    this.desactivarModoEdicion();
  }

  private panTo(coordinates: [number, number]) {
    if (!this.map) return;
    this.map
      .getView()
      .animate({ center: coordinates, duration: 1000, zoom: 18 });
  }

  private updatePunto() {
    if (this.modificacionLocal) {
      this.modificacionLocal = false;
      return;
    }
    this.drawLayer?.getSource()?.clear();
    this.prepararVariablesForm();
    this.desactivarModoEdicion();
    this.dibujar();
  }

  private prepararVariablesForm() {
    switch (this.tipo) {
      case 'Point': {
        this.point = this.form?.getRawValue();
        break;
      }
      case 'Circle': {
        this.circle = this.form?.getRawValue();
        break;
      }
      case 'Polygon': {
        this.polygon = this.form?.getRawValue();
        break;
      }
      default: {
        break;
      }
    }
  }

  private prepararVariables() {
    const coordinates = this.form?.get('coordinates')?.value;
    switch (this.tipo) {
      case 'Point': {
        this.point = this.point || { type: 'Point', coordinates };
        break;
      }
      case 'LineString': {
        this.lineString = this.lineString || {
          type: 'LineString',
          coordinates,
        };
        break;
      }
      case 'Polygon': {
        this.polygon = this.polygon || { type: 'Polygon', coordinates };
        break;
      }
      case 'Circle': {
        const radius = this.form?.get('radius')?.value;
        this.circle = this.circle || {
          type: 'Point',
          coordinates,
          radius,
        };
        break;
      }
      case 'MultiPolygon': {
        this.multiPolygon = this.multiPolygon || {
          type: 'MultiPolygon',
          coordinates,
        };
        break;
      }
      default: {
        this.helper.notifError('Tipo de geometría no soportado');
        break;
      }
    }
  }

  private agregarFigurasIniciales() {
    if (!this.editando) return;

    // Tengo que elegir que estoy dibujando
    switch (this.tipo) {
      case 'Point': {
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(this.point.coordinates),
        });
        this.drawLayer.getSource()?.addFeature(feature);
        break;
      }
      case 'Polygon': {
        const feature: Feature<Geometry> = new Feature({
          geometry: new Polygon(this.polygon.coordinates),
        });
        const s = new Style({
          stroke: new Stroke({
            color: this.color,
            width: 4,
          }),
        });
        feature.setStyle(s);
        this.drawLayer.getSource()?.addFeature(feature);
        break;
      }
      case 'Circle': {
        const feature: Feature<Geometry> = new Feature({
          geometry: new Circle(this.circle.coordinates, this.circle.radius),
        });
        const s = new Style({
          stroke: new Stroke({
            color: this.color,
            width: 4,
          }),
        });
        feature.setStyle(s);
        this.drawLayer.getSource()?.addFeature(feature);
        break;
      }
      case 'LineString': {
        const feature: Feature<Geometry> = new Feature({
          geometry: new LineString(this.lineString.coordinates),
        });
        const s = new Style({
          stroke: new Stroke({
            color: this.color,
            width: 4,
          }),
        });
        feature.setStyle(s);
        this.drawLayer.getSource()?.addFeature(feature);
        break;
      }
      case 'MultiPolygon': {
        const feature: Feature<Geometry> = new Feature({
          geometry: new MultiPolygon(this.multiPolygon.coordinates),
        });
        const s = new Style({
          stroke: new Stroke({
            color: this.color,
            width: 4,
          }),
        });
        feature.setStyle(s);
        this.drawLayer.getSource()?.addFeature(feature);
        break;
      }
      default: {
        this.helper.notifError('Tipo de geometría no soportado');
        return;
      }
    }
  }

  private initDraw() {
    this.draw = new Draw({
      source: this.drawLayer.getSource(),
      type: this.tipo,
      style: this.s,
    });
  }

  async ngOnInit(): Promise<void> {
    await this.initMap();
    this.initDraw();
    this.handleModify();

    this.suscribePointChange();
    this.suscribeLineStringChange();
    this.suscribePolygonChange();
    this.suscribeCircleChange();
    this.suscribeMultiPolygonChange();

    this.prepararVariables();
    this.agregarFigurasIniciales();
    this.dibujar();

    this.form?.get('coordinates')?.valueChanges.subscribe(() => {
      this.updatePunto();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['color']?.currentValue !== changes['color']?.previousValue) {
      this.s = {
        'fill-color': HelperService.hexToRgba(this.color, 0.2),
        'stroke-color': this.color,
        'stroke-width': 2,
        'circle-radius': 5,
        'circle-fill-color': this.color,
      };
      this.drawLayer.setStyle(this.s);
    }

    if (changes['centrarA']?.currentValue) {
      this.panTo(this.centrarA?.coordinates);
      // Si se está dibujando un punto o un circulo se dibuja en la posicion recibida
      if (this.tipo === 'Point') {
        this.borrarDibujos();
        this.prepararVariables();
        this.point.coordinates = this.centrarA?.coordinates;
        this.modificacionLocal = true;
        this.pointChange.emit(this.point);
        this.agregarFigurasIniciales();
      }
      if (this.tipo === 'Circle') {
        this.borrarDibujos();
        this.prepararVariables();
        this.circle.coordinates = this.centrarA?.coordinates;
        this.circle.radius = 0.0005;
        this.modificacionLocal = true;
        this.circleChange.emit(this.circle);
        this.agregarFigurasIniciales();
      }
    }

    if (changes['tipo'] && !changes['tipo']?.firstChange) {
      this.borrarDibujos();
      this.initDraw();
      this.handleModify();
      this.prepararVariables();
      this.agregarFigurasIniciales();
      this.dibujar();
    }
  }
}
