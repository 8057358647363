/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Feature, Map, View } from 'ol';
import {
  ICategoriaActivo,
  IDispositivoAlarma,
  IGeoJSONPoint,
  IListado,
  IPopulate,
  IQueryParam,
  IReporte,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { Geometry, LineString, Point } from 'ol/geom';
import { OpenLayersService } from '../../../../auxiliares/servicios/openLayers.service';
import Style from 'ol/style/Style';

import Icon from 'ol/style/Icon';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';

import VectorSource from 'ol/source/Vector';
import { IFiltroLastReporte } from '../../../../auxiliares/servicios/http/reportes.service';

@Component({
  selector: 'app-mapa-ol',
  templateUrl: './mapa-ol.component.html',
  styleUrl: './mapa-ol.component.scss',
})
export class MapaOlComponent implements OnInit, OnDestroy {
  public ctrl = false;
  //Vehiculos
  public reportes?: IReporte[] = [];
  public reporte?: IReporte;
  public reportes$?: Subscription;

  //Activos
  public reportesActivos?: IReporte[] = [];
  public reporteActivos?: IReporte;
  public reportesActivos$?: Subscription;
  private activosMostrar: boolean = true;

  //Alarmas
  public alarma?: IDispositivoAlarma;
  public alarmas?: IDispositivoAlarma[] = [];
  public alarmas$?: Subscription;

  ///Ocultos
  private alarmasOcultas = false;
  private vehiculosOcultos = false;
  private activosOcultos = false;
  /// OPENLAYERS
  public map?: Map;
  // Layer de Markers
  public markerVectorOptions = OpenLayersService.vehiculosVectorLayer();
  public orientationVectorOptions =
    OpenLayersService.vehiculosCompassVectorLayer();
  public alarmasMarkers = OpenLayersService.alarmaCasaVectorLayer();
  // Punto Activo
  public activoSimpleLayer = OpenLayersService.activoVectorLayer();
  /// Centro
  private puntos: [number, number][] = [];

  private populateAlarmas: IPopulate[] = [
    {
      path: 'domicilio',
    },
    {
      path: 'cliente',
      select: 'nombre',
    },
  ];
  private queryAlarmas: IQueryParam = {
    populate: JSON.stringify(this.populateAlarmas),
    includeChildren: true,
  };
  constructor(
    private listados: ListadosService,
    private helper: HelperService,
  ) {}

  /// Alarmas
  public async listarAlarmas(): Promise<void> {
    this.alarmas$?.unsubscribe();
    this.alarmas$ = this.listados
      .subscribe<
        IListado<IDispositivoAlarma>
      >('dispositivosAlarmas', this.queryAlarmas)
      .subscribe(async (data) => {
        this.alarmas = data.datos;
        console.log(
          `listado de dispositivosAlarmas ${new Date().toISOString()}`,
          data,
        );
      });
    await this.listados.getLastValue('dispositivosAlarmas', this.queryAlarmas);
  }

  /// Vehiculos
  public async listar(): Promise<void> {
    const categoriaActivo: ICategoriaActivo = 'Vehículo';
    const query: IFiltroLastReporte = {
      categoriaActivo,
    };
    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IReporte[]>('reportesLastFiltered', query)
      .subscribe(async (data) => {
        this.reportes = data;
        console.log(
          `listado de vehiculosEnVivo2 ${new Date().toISOString()}`,
          data,
        );
      });
    await this.listados.getLastValue('reportesLastFiltered', query);
  }

  private addMarkers() {
    const source = this.markerVectorOptions.getSource();
    if (!source) return;
    source.clear();
    const orientationSource = this.orientationVectorOptions.getSource();
    if (!orientationSource) return;
    orientationSource.clear();
    if (this.reportes && !this.vehiculosOcultos) {
      for (const reporte of this.reportes) {
        if (
          this.reporte &&
          this.reporte.activo.vehiculo &&
          this.reporte.activo._id === reporte.activo._id
        ) {
          this.reporte.fechaCreacion = reporte.fechaCreacion;
        }
        if (!reporte.geojson?.coordinates) return;
        // Solo mostrar los vehiculos que estén en los recorridos seleccionados
        this.puntos.push(reporte.geojson.coordinates);
        // marker
        const coord = OpenLayersService.lonLatToCoordinate(
          reporte.geojson.coordinates[0],
          reporte.geojson.coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: reporte,
          tipo: 'vehiculo',
        });
        source.addFeature(feature);
        // orientation
        if (reporte.reporteTraccar?.['course']) {
          const radian = (reporte.reporteTraccar?.['course'] * Math.PI) / 180;
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'vehiculo',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/brujula.png',
              height: 40,
              width: 40,
              rotation: radian,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        } else {
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'vehiculo',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/circle.png',
              height: 40,
              width: 40,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        }
      }
    }
    const sourceAlarmas = this.alarmasMarkers.getSource();
    if (!sourceAlarmas) return;
    sourceAlarmas.clear();
    if (this.alarmas && !this.alarmasOcultas) {
      for (const alarma of this.alarmas) {
        if (!alarma.domicilio?.geojson) continue;
        this.puntos.push(
          (alarma.domicilio.geojson as IGeoJSONPoint).coordinates,
        );
        // marker
        const coord = OpenLayersService.lonLatToCoordinate(
          (alarma.domicilio.geojson as IGeoJSONPoint).coordinates[0],
          (alarma.domicilio.geojson as IGeoJSONPoint).coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: alarma,
          tipo: 'alarma',
        });
        sourceAlarmas.addFeature(feature);
      }
    }
  }

  /// Activos
  public async listarActivos(): Promise<void> {
    try {
      const categoriaActivo: ICategoriaActivo = 'Normal';
      const query: IFiltroLastReporte = {
        categoriaActivo,
      };

      this.reportesActivos$?.unsubscribe();
      this.reportesActivos$ = this.listados
        .subscribe<IReporte[]>('reportesLastFiltered', query)
        .subscribe(async (data) => {
          this.reportesActivos = data;
          this.addMarkersActivos();
          console.log(
            `listado de reportesActivos ${new Date().toISOString()}`,
            data,
          );
        });
      await this.listados.getLastValue('reportesLastFiltered', query);
    } catch (error: any) {
      this.helper.notifError('Error al listar activos');
    }
  }
  // Punto Activos
  private addMarkersActivos() {
    const source = this.activoSimpleLayer.getSource();
    if (!source) return;
    source.clear();
    if (!this.activosMostrar) return;
    if (this.reportesActivos && !this.activosOcultos) {
      for (const reporte of this.reportesActivos) {
        if (
          this.reporte &&
          this.reporte.activo &&
          this.reporte.activo._id === reporte.activo._id
        ) {
          this.reporte = reporte;
        }
        if (!reporte.geojson?.coordinates) return;
        this.puntos.push(reporte.geojson.coordinates);
        // punto
        const coord = OpenLayersService.lonLatToCoordinate(
          reporte.geojson.coordinates[0],
          reporte.geojson.coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: reporte,
          tipo: 'activo',
        });
        source.addFeature(feature);
      }
    }
  }

  private async initMap() {
    const position = await OpenLayersService.getCurrentPosition();
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'map',
      controls: [],
      layers: [
        OpenLayersService.mapTile(),

        this.orientationVectorOptions,
        this.markerVectorOptions,
        this.alarmasMarkers,
        this.activoSimpleLayer,
      ],
      view: new View({
        center: position,
        zoom: 16,
      }),
    });

    this.map.on('error', (e) => {
      console.error('Error en el mapa', e);
    });

    await this.handleClick();
    const sourceres: VectorSource<Feature<Geometry>> = new VectorSource();
    if (this.reportes?.length) {
      const sourceMarker = this.markerVectorOptions.getSource();
      sourceMarker.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (this.reportesActivos?.length) {
      const sourcePoint = this.activoSimpleLayer.getSource();
      sourcePoint.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (this.alarmas?.length) {
      const sourceMarker = this.alarmasMarkers.getSource();
      sourceMarker.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (sourceres) {
      this.setBounds(sourceres);
    }
  }

  private async handleClick() {
    if (!this.map) return;
    this.reporte = undefined;
    this.alarma = undefined;
    this.map.on('singleclick', async (evt) => {
      const feature = this.map?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          const geometry = feature.getGeometry();
          if (geometry instanceof Point) {
            if (feature.get('tipo') === 'vehiculo') {
              /// ES UN VEHICULO
              return feature;
            }
            if (feature.get('tipo') === 'activo') {
              /// ES UN ACTIVO
              return feature;
            }
            if (feature.get('tipo') === 'alarma') {
              /// ES UNA ALARMA
              return feature;
            }
            return null;
          } else if (geometry instanceof LineString) {
            return null;
          }
          return null;
        },
      );

      if (!feature) return;

      const data = feature.get('data');
      if (feature.get('tipo') === 'vehiculo') {
        this.reporte = data;
        if (!this.reporte?.geojson?.coordinates) return;
      }
      if (feature.get('tipo') === 'activo') {
        this.reporte = data;
        if (!this.reporte?.geojson?.coordinates) return;
      }
      if (feature.get('tipo') === 'alarma') {
        this.reporte = undefined;
        this.alarma = data;
        if (!this.alarma?.domicilio?.geojson?.coordinates) return;
      } else {
        this.alarma = undefined;
      }
    });
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    if (!extent) return;
    this.map?.getView().fit(extent, { padding: [200, 200, 200, 200] });
    if (this.map?.getView().getZoom() > 17) {
      this.map?.getView().setZoom(17);
    }
  }

  public onClose() {
    this.reporte = undefined;
    this.alarma = undefined;
  }

  public ocultar(e: string) {
    console.log(e);
    if (e === 'Vehiculos') this.vehiculosOcultos = !this.vehiculosOcultos;
    if (e === 'Alarmas') this.alarmasOcultas = !this.alarmasOcultas;
    if (e === 'Activos') this.activosOcultos = !this.activosOcultos;
    this.addMarkers();
    this.addMarkersActivos();
  }
  ///
  async ngOnInit() {
    await Promise.all([
      await this.listar(),
      await this.listarActivos(),
      await this.listarAlarmas(),
    ]);
    this.addMarkers();
    await this.initMap();
  }

  ngOnDestroy() {
    this.reportes$?.unsubscribe();
  }
}
