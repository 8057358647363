import { Component, Input } from '@angular/core';
import { Trip } from 'modelos/src';
import { HelperService } from '../../../../../../../../auxiliares/servicios/helper.service';

@Component({
  selector: 'app-viaje',
  templateUrl: './viaje.component.html',
  styleUrl: './viaje.component.scss',
})
export class ViajeComponent {
  @Input() viaje?: Trip;

  constructor(public helper: HelperService) {}
}
