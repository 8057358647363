import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-flotante-alarmas',
  templateUrl: './flotante-alarmas.component.html',
  styleUrl: './flotante-alarmas.component.scss',
})
export class FlotanteAlarmasComponent {
  @Input() alarmas?: number;
  constructor() {}
}
