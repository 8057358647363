import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import {
  IActivo,
  IDespacho,
  IFilter,
  IListado,
  IQueryParam,
  IRecorrido,
  IUpdateDespacho,
  IUsuario,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import { DespachosService } from '../despachos.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';

@Component({
  selector: 'app-editar-despacho',
  templateUrl: './editar-despacho.component.html',
  styleUrl: './editar-despacho.component.scss',
})
export class EditarDespachoComponent implements OnInit, OnDestroy {
  private id = inject(MAT_DIALOG_DATA) as string;
  readonly dialogRef = inject(MatDialog);
  public loading = signal(false);

  public despacho?: IDespacho;
  public despacho$?: Subscription;

  public vehiculos: IActivo[] = [];
  public vehiculos$?: Subscription;

  public choferes: IUsuario[] = [];
  public choferes$?: Subscription;

  public recorridos: IRecorrido[] = [];
  public recorridos$?: Subscription;

  public form?: FormGroup;

  constructor(
    private listados: ListadosService,
    private dialog: DialogService,
    private service: DespachosService,
    private helper: HelperService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      salio: new FormControl(this.despacho?.salio),
      idActivo: new FormControl(this.despacho?.idActivo),
      idChofer: new FormControl(this.despacho?.idChofer),
      idsRecorridos: new FormControl(this.despacho?.idsRecorridos),
      completado: new FormControl(false),
      cancelado: new FormControl(this.despacho?.cancelado || false),
      idCronograma: new FormControl(this.despacho?.idCronograma),
    });
  }

  private async listar() {
    this.despacho$?.unsubscribe();
    this.despacho$ = this.listados
      .subscribe<IDespacho>('despacho', this.id)
      .subscribe((data) => {
        this.despacho = data;
        console.log(`listado de despacho`, data);
      });
    await this.listados.getLastValue('despacho', this.id);
  }

  // Vehículos
  private async listarVehiculos() {
    const query: IQueryParam = {
      sort: 'identificacion',
      limit: 0,
      select: 'identificacion vehiculo.patente',
    };
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', query)
      .subscribe((data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos`, data);
      });
    await this.listados.getLastValue('vehiculos', query);
  }
  // Choferes
  private async listarChoferes() {
    const filter: IFilter<IUsuario> = {
      roles: 'Chofer',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: 'datosPersonales.nombre',
      limit: 0,
      select: 'datosPersonales roles identificacionInterna',
    };
    this.choferes$?.unsubscribe();
    this.choferes$ = this.listados
      .subscribe<IListado<IUsuario>>('usuarios', query)
      .subscribe((data) => {
        this.choferes = data.datos;
        console.log(`listado de usuarios`, data);
      });
    await this.listados.getLastValue('usuarios', query);
  }
  // Recorridos
  private async listarRecorridos() {
    const query: IQueryParam = {
      sort: 'nombre',
      limit: 0,
      select: 'nombre nombreFlota destino por',
    };
    this.recorridos$?.unsubscribe();
    this.recorridos$ = this.listados
      .subscribe<IListado<IRecorrido>>('recorridos', query)
      .subscribe((data) => {
        this.recorridos = data.datos;
        console.log(`listado de recorridos`, data);
      });
    await this.listados.getLastValue('recorridos', query);
  }

  public async editar() {
    const res = await this.dialog.confirm(
      'Editar',
      '¿Está seguro de editar el despacho?',
    );
    if (!res) return;
    console.log('editandox');
    try {
      this.loading.set(true);
      const data = this.form?.value as IUpdateDespacho;
      await this.service.update(this.id, data);
      this.helper.notifSuccess('Despacho editado correctamente');
      this.dialogRef.closeAll();
    } catch (error) {
      console.error(`Error al editar despacho`, error);
      this.helper.notifError(error);
    } finally {
      this.loading.set(false);
    }
  }

  async ngOnInit() {
    this.loading.set(true);
    await Promise.all([
      this.listar(),
      this.listarVehiculos(),
      this.listarChoferes(),
      this.listarRecorridos(),
    ]);
    this.createForm();
    this.loading.set(false);
  }

  ngOnDestroy(): void {
    this.despacho$?.unsubscribe();
    this.vehiculos$?.unsubscribe();
    this.choferes$?.unsubscribe();
    this.recorridos$?.unsubscribe();
  }
}
