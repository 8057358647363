@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-card
      class="m-2"
      style="width: 500px"
      fxLayout="column"
      fxLayoutAlign="space-between start"
    >
      @if (id) {
        <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
          <h2 mat-dialog-title cdkDragHandle>Editar {{ proveedor?.nombre }}</h2>
        </ng-container>
      } @else {
        <ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
          <h2 mat-dialog-title cdkDragHandle>Crear Proveedor</h2>
        </ng-container>
      }

      <mat-card-content style="margin: 1em">
        <mat-form-field style="width: 100%">
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="nombre" />
        </mat-form-field>
        <ng-select
          [items]="tipos"
          formControlName="tipos"
          placeholder="Tipos"
          style="width: 100%"
          multiple="true"
          searchable="true"
        >
        </ng-select>
        @if (!id) {
          <ng-select
            [items]="clientes"
            bindLabel="nombre"
            bindValue="_id"
            formControlName="idCliente"
            placeholder="Cliente"
            [notFoundText]="'No hay clientes creados'"
            style="width: 100%"
            multiple="false"
            searchable="true"
          >
          </ng-select>
        }
        <h2 mat-dialog-title cdkDragHandle>Localizacion</h2>
        <div
          class="mat-elevation-z4"
          style="padding: 0.5em; border-radius: 5px; margin-bottom: 0.5em"
        >
          <app-mapa-unico-punto
            [point]="form?.get('ubicacion')?.value"
            (pointsChange)="onPointChange($event)"
          ></app-mapa-unico-punto>
        </div>
      </mat-card-content>

      <mat-dialog-actions align="end">
        <app-button
          matType="mat-fab extended"
          color="warn"
          (click)="this.dialog.closeAll()"
          text="Volver"
          icon="arrow_back"
        ></app-button>
        <app-button
          matType="mat-fab extended"
          [disabled]="!this.form.valid"
          color="accent"
          text="Guardar"
          icon="save"
          type="submit"
        ></app-button>
      </mat-dialog-actions>
    </mat-card>
  </form>
}
