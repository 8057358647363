import { Component } from '@angular/core';

@Component({
  selector: 'app-configurar-notificaciones',
  templateUrl: './configurar-notificaciones.component.html',
  styleUrls: ['./configurar-notificaciones.component.scss'],
})
export class ConfigurarNotificacionesComponent {
  /*public form?: UntypedFormGroup;
  public enviando = false;

  get notificacionesForm() {
    return this.form?.get('notificaciones') as UntypedFormArray;
  }
  public habilitadasForm(i: number) {
    return (this.form?.get('notificaciones') as UntypedFormArray)
      .at(i)
      .get('habilitados') as UntypedFormArray;
  }

  public usuario: IUsuario = LoginService.getUsuario();

  public notificaciones = ICodigoNotificacion;

  // Listado Continuo
  public unidadNegocios$?: Subscription;
  public centroOperativos$?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private helper: HelperService,
    private usuariosService: UsuariosService,
    private listadosService: ListadosService,
    private loginService: LoginService,
    public loading: LoadingService,
  ) {}

  public isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  private createForm(): void {
    const notifUser = this.usuario.notificaciones || [];
    const notificacionesForm: UntypedFormGroup[] = [];

    for (const notif of notifUser) {
      const notifHabilitadas: UntypedFormGroup[] = [];

      Object.keys(this.notificaciones).forEach((n) => {
        if (this.isNumber(n)) {
          const codigo = Number(n);
          const habilitado = notif.habilitados?.includes(codigo);

          notifHabilitadas.push(
            this.fb.group({
              codigo: [codigo],
              habilitado: [habilitado],
            }),
          );
        }
      });

      notificacionesForm.push(
        this.fb.group({
          nivel: [notif.nivel, Validators.required],
          idUnidadNegocio: [notif.idUnidadNegocio],
          idCentroOperativo: [notif.idCentroOperativo],
          habilitados: this.fb.array(notifHabilitadas),
        }),
      );
    }

    this.form = this.fb.group({
      notificaciones: this.fb.array(notificacionesForm),
    });

    if (notifUser.length === 0) {
      this.agregar(false);
    }
  }

  public agregar(habilitadas = true) {
    const notifHabilitadas: UntypedFormGroup[] = [];
    Object.keys(this.notificaciones).forEach((n) => {
      if (this.isNumber(n)) {
        const codigo = Number(n);

        notifHabilitadas.push(
          this.fb.group({
            codigo: [codigo],
            habilitado: [habilitadas],
          }),
        );
      }
    });

    this.notificacionesForm.push(
      this.fb.group({
        nivel: [null, Validators.required],
        idUnidadNegocio: [],
        idCentroOperativo: [],
        habilitados: this.fb.array(notifHabilitadas),
      }),
    );
  }

  public eliminar(i: number) {
    this.notificacionesForm.removeAt(i);
  }

  //

  private getData(): IUpdateUsuario {
    const data = this.form?.value;

    const update: IUpdateUsuario = {
      notificaciones: [],
    };

    console.log(`data`, data);

    for (const notif of data.notificaciones) {
      const habilitados = notif.habilitados as {
        codigo: number;
        habilitado: boolean;
      }[];
      const codigosHabilitados = habilitados
        .filter((h) => h.habilitado)
        .map((h) => h.codigo);
      update.notificaciones?.push({
        nivel: notif.nivel,
        idCentroOperativo: notif.idCentroOperativo,
        idUnidadNegocio: notif.idUnidadNegocio,
        habilitados: codigosHabilitados,
      });
    }

    console.log(`update`, update);

    return update;
  }

  public async guardar(): Promise<void> {
    try {
      this.enviando = true;
      const data = this.getData();
      await firstValueFrom(this.usuariosService.editarPropio(data));
      this.loginService.updateNotificaciones(data.notificaciones || []);
      this.enviando = false;
      this.helper.notifSuccess('Configuración de notificaciones guardada');
    } catch (error) {
      this.helper.notifError(error);
    }
  }*/
}
