import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { ICliente } from 'modelos/src';
import { firstValueFrom, Subscription } from 'rxjs';
import { ListadosService } from '../../../../../../auxiliares/servicios';

@Component({
  selector: 'app-detalles-cliente',
  templateUrl: './detalles-cliente.component.html',
  styleUrl: './detalles-cliente.component.scss',
})
export class DetallesClienteComponent implements OnInit {
  public selectedTab = 0;
  public idCliente: string;
  public cliente: ICliente;
  private cliente$: Subscription;
  public loading: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private listados: ListadosService,
  ) {}

  public tabChanged(event: MatTabChangeEvent): void {
    // Setea el query param  'tab' con el valor del tab seleccionado
    this.selectedTab = event.index;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: this.selectedTab },
      queryParamsHandling: 'merge',
    });
  }

  private async listarCliente() {
    this.cliente$?.unsubscribe();
    this.cliente$ = this.listados
      .subscribe<ICliente>('cliente', this.idCliente)
      .subscribe((data) => {
        this.cliente = data;
        console.log(`listado de cliente`, data);
      });
    await this.listados.getLastValue('cliente', this.idCliente);
  }

  public clientes() {
    this.router.navigate(['main/administracion/clientes'], {});
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  async ngOnInit(): Promise<void> {
    // Obtiene el query param 'tab' y lo asigna al tab seleccionado
    const params = await firstValueFrom(this.activatedRoute.paramMap);
    this.idCliente = params.get('id');
    await this.listarCliente();
    this.loading = false;
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.selectedTab = parseInt(params['tab']);
      }
    });
  }
}
