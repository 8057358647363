@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      <span class="titulo">{{ title }}</span>
    </div>
    <br />
    <div
      class="column-center mat-elevation-z1"
      style="gap: 15px; padding: 0.5em"
    >
      <div class="row-flex-start" style="width: 100%">
        <span class="subtitulo">Datos del Documento</span>
      </div>
      <!-- Tipo -->
      <section class="column-center" style="width: 100%; gap: 16px">
        <ng-select
          [items]="tipos"
          formControlName="tipo"
          [multiple]="false"
          placeholder="Tipo"
          style="width: 100%"
          [hideSelected]="true"
          required
        >
        </ng-select>
      </section>
      <!-- Emision y Vencimiento -->
      <div class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Fecha de Emision</mat-label>
            <input
              matInput
              [matDatepicker]="picker1"
              formControlName="emision"
            />
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Fecha de Vencimiento</mat-label>
            <input
              matInput
              [matDatepicker]="picker2"
              formControlName="vencimiento"
            />
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <!-- Descripcion -->
      <section class="layout">
        <div class="grow1">
          <mat-form-field style="width: 100%">
            <mat-label>Descripcion</mat-label>
            <textarea matInput formControlName="descripcion"> </textarea>
          </mat-form-field>
        </div>
      </section>
    </div>
    <br />
    <mat-card>
      <mat-card-header>
        <mat-card-title>Imágenes</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div class="column-center" style="gap: 10px">
          <div class="row-space-around" style="width: 100%">
            <app-upload-file
              (urlChange)="onUrlChange($event)"
              (loadingFile)="loadingFile($event)"
              textoBoton="Subir Imagen"
            ></app-upload-file>
          </div>
        </div>
        <mat-progress-bar
          color="accent"
          [mode]="this.loading ? 'indeterminate' : 'determinate'"
          style="margin-top: 1em"
        >
        </mat-progress-bar>
        <div
          *ngFor="let urlActual of documentoImagenes.value; let i = index"
          class="row-center"
          style="width: 100%; margin-top: 1em; margin-bottom: 1em"
        >
          <div class="column-center" style="width: 70%">
            <img
              [src]="urlActual"
              alt="Imagen"
              style="max-width: 100%; width: auto"
            />
          </div>
          <div class="column-center" style="width: 30%">
            <app-button
              matType="mat-fab extended"
              icon="cancel"
              color="warn"
              (click)="eliminarImagen(i)"
              text="Eliminar"
            ></app-button>
          </div>
          <br />
        </div>
      </mat-card-content>
    </mat-card>
    <!-- Botones  -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>
  </form>
}
