import { Component, Input, OnInit } from '@angular/core';
import { IParada, IUbicacion } from 'modelos/src';
import { FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Coordinate } from 'ol/coordinate';
import {
  CreadorPolygon,
  Parada,
} from '../../../../../auxiliares/mapa-creador/mapa-creador.component';

@Component({
  selector: 'app-mapa-paradas-ol',
  templateUrl: './mapa-paradas-ol.component.html',
  styleUrl: './mapa-paradas-ol.component.scss',
})
export class MapaParadasOlComponent implements OnInit {
  @Input() formulario?: FormGroup;
  @Input() terminales?: IUbicacion[];
  public lineString: Coordinate[] = [];
  public points: Parada[] = [];
  public paradas: IParada[] = [];
  public indicaciones = [
    'Para dibujar un recorrido presione el botón de las líneas.',
    'Para finalizar el recorrido haga doble click.',
    'Para agregar paradas haga click en el mapa con el recorrido en pausa.',
  ];

  public terminalesMapa: CreadorPolygon[] = [];

  /// Drag and Drop
  public drop(event: CdkDragDrop<IParada[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );
    this.renombrarParadas();
    this.points = this.paradas.map((p) => {
      return { nombre: p.nombre, ubicacion: p.ubicacionOl! };
    });
  }

  private renombrarParadas() {
    this.paradas.forEach((p, idx) => {
      if (p.nombre?.includes('Parada')) {
        p.nombre = `Parada #${idx + 1}`;
      }
    });
  }

  public removeParada(p: IParada) {
    event?.stopPropagation();
    this.paradas = this.paradas.filter((parada) => parada !== p);
    this.renombrarParadas();
    this.points = this.paradas.map((p) => {
      return { nombre: p.nombre, ubicacion: p.ubicacionOl! };
    });
    this.formulario?.patchValue({ paradas: this.paradas });
  }

  /////

  public onLineStringChange(e: Coordinate[]) {
    this.lineString = e;
    this.formulario?.patchValue({ recorridoOl: e });
  }

  public onPointsChange(e: Parada[]) {
    this.points = e;
    this.generarParadas();
    this.formulario?.patchValue({ paradas: this.paradas });
  }

  private generarParadas() {
    const paradasViejas = this.paradas;
    this.paradas = this.points.map((p, idx) => {
      const paradaAnterior = this.paradas.find(
        (parada) => parada.ubicacionOl === p.ubicacion,
      );
      let nombre;
      if (paradaAnterior?.nombre) {
        nombre = paradaAnterior.nombre;
      } else {
        nombre = `Parada #${idx + 1}`;
      }

      let destino;
      if (paradaAnterior?.destino) {
        destino = paradaAnterior.destino;
      } else {
        if (paradasViejas[idx - 1]?.destino) {
          destino = paradasViejas[idx - 1].destino;
        } else {
          destino = this.formulario?.get('destino')?.value;
        }
      }

      let por;
      if (paradaAnterior?.por) {
        por = paradaAnterior.por;
      } else {
        if (paradasViejas[idx - 1]?.por) {
          por = paradasViejas[idx - 1].por;
        } else {
          por = this.formulario?.get('por')?.value;
        }
      }

      const parada: IParada = {
        ubicacionOl: p.ubicacion,
        nombre,
        destino,
        por,
        bajada: paradaAnterior?.bajada || true,
        subida: paradaAnterior?.subida || true,
      };

      return parada;
    });
  }

  ngOnInit() {
    this.lineString = this.formulario?.get('recorridoOl')?.value || [];
    this.paradas = this.formulario?.get('paradas')?.value || [];
    this.points = this.paradas.map((p) => {
      return { nombre: p.nombre, ubicacion: p.ubicacionOl! };
    });
  }
}
