import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ICreateNota, INota, IUpdateNota, TipoNota } from 'modelos/src';

import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import { NotasService } from '../../../../auxiliares/servicios/http/notas.service';

@Component({
  standalone: true,
  selector: 'app-crear-editar-nota',
  templateUrl: './crear-editar-nota.component.html',
  styleUrl: './crear-editar-nota.component.scss',
  imports: [CommonModule, AuxiliaresModule],
})
export class CrearEditarNotaComponent implements OnInit {
  //DEMAS

  public id?: string;
  public idEntidad?: string;

  public tipo?: TipoNota;
  public tipoAsignado?: string;
  public form?: FormGroup;
  public title?: string;
  public editar: boolean = false;
  public nota?: INota;
  public totalCount?: number;

  //Subss
  public notas$: Subscription;

  public tiposNota: TipoNota[] = ['Nota', 'Contacto'];

  public getPermanente() {
    return this.form?.value?.permanente;
  }

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any,
    public helper: HelperService,
    private service: NotasService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private listados: ListadosService,
  ) {}

  get informacion() {
    return this.form?.get('informacion') as FormGroup;
  }
  private createForm() {
    this.form = new FormGroup({
      idAsignado: new FormControl(this.nota?.idAsignado || this.idEntidad),
      permanente: new FormControl(
        this.nota?.permanente === false ? false : true,
      ),
      vigenciaDesde: new FormControl(this.nota?.vigenciaDesde),
      vigenciaHasta: new FormControl(this.nota?.vigenciaHasta),
      tipo: new FormControl(this.nota?.tipo || this.data?.tipo || null),
      informacion: new FormGroup({
        contacto: new FormControl(this.nota?.informacion?.contacto),
        telefono: new FormControl(this.nota?.informacion?.telefono),
        email: new FormControl(this.nota?.informacion?.email),
        palabraSeguridadNormal: new FormControl(
          this.nota?.informacion?.palabraSeguridadNormal,
        ),
        palabraSeguridadEmergencia: new FormControl(
          this.nota?.informacion?.palabraSeguridadEmergencia,
        ),
        nota: new FormControl(this.nota?.informacion?.nota),
        numeroUsuarioAlarma: new FormControl(
          this.nota?.informacion?.numeroUsuarioAlarma,
        ),
      }),
    });
  }

  //
  public async onSubmit() {
    const res = await this.dialogService.confirm(
      `${this.title} Nota`,
      `¿Desea ${this.title.toLowerCase()} la nota?`,
    );
    if (!res) return;
    try {
      const data = this.getData();
      if (this.editar) {
        // Update
        await this.service.update(this.id, data);
        this.helper.notifSuccess('Nota actualizada');
        this.dialog.closeAll();
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Nota creada');
        this.dialog.closeAll();
      }
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  public getData(): ICreateNota | IUpdateNota {
    const data: ICreateNota | IUpdateNota = this.form?.value;
    return data;
  }
  async ngOnInit() {
    if (this.data.nota) {
      this.id = this.data?.nota?.id;
      this.editar = true;
      this.nota = this.data?.nota;
    } else {
      this.editar = false;
    }
    this.tipo = this.data?.tipo ? this.data?.tipo : '';
    this.tipoAsignado = this.data?.tipoDispositivo;
    this.idEntidad = this.data?.idEntidad;
    if (this.editar) {
      if (this.data.tipo === 'Nota') this.title = 'Editar Nota';
      if (this.data.tipo === 'Contacto') this.title = 'Editar Contacto';
      if (!this.data.tipo) this.title = 'Editar Nota/Contacto';
    } else {
      if (this.data.tipo === 'Nota') this.title = 'Crear Nota';
      if (this.data.tipo === 'Contacto') this.title = 'Crear Contacto';
      if (!this.data.tipo) this.title = 'Crear Nota/Contacto';
    }
    this.createForm();
  }
}
