import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IActivo,
  IFilter,
  IListado,
  IQueryParam,
  IUpdateActivo,
  IUsuario,
} from 'modelos/src';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ActivosService } from '../../../../../auxiliares/servicios/http/activos.service';

@Component({
  selector: 'app-asignar-vehiculo-chofer',
  templateUrl: './asignar-vehiculo-chofer.component.html',
  styleUrl: './asignar-vehiculo-chofer.component.scss',
})
export class AsignarVehiculoChoferComponent implements OnInit {
  public vehiculoInicialID: string = '';
  private vehiculoInicial: IActivo = {};
  public vehiculos: IActivo[] = [];
  public vehiculos$?: Subscription;
  public totalCount = 0;
  public form?: FormGroup;
  //public vehiculos$?: Subscription;
  public vehiculo: IActivo = {};

  public filter: IFilter<IActivo> = {};

  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    sort: 'nombre',
    page: 0,
    includeChildren: false,
    childrenLevel: 0,
  };

  public filterVehiculos: IFilter<IActivo> = {};
  public queryVehiculos: IQueryParam = {
    filter: JSON.stringify(this.filterVehiculos),
    page: 0,
    includeChildren: false,
    childrenLevel: 0,
  };
  public usuario: IUsuario = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public param: IUsuario,
    private listados: ListadosService,
    private helper: HelperService,
    private activosService: ActivosService,
    private dialogRef: MatDialogRef<AsignarVehiculoChoferComponent>,
  ) {}
  public vehiculoActual() {
    const vehiculo = this.vehiculos.find(
      (v) => v.vehiculo.idChofer == this.usuario._id,
    );

    if (!vehiculo?._id) this.vehiculoInicialID = '';
    else {
      this.vehiculoInicial = vehiculo;
      this.vehiculo = this.vehiculoInicial;
      this.vehiculoInicialID = vehiculo?._id;
      console.log(this.vehiculoInicial);
    }
  }
  public createForm() {
    this.form = new FormGroup({
      idChofer: new FormControl(undefined),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getData(): IUpdateActivo {
    const data: IUpdateActivo = this.form?.value;
    return data;
  }

  public async verificarChofer(usuario: IUsuario): Promise<void> {
    const veh = this.vehiculos.find((v) => v.vehiculo.idChofer == usuario._id);
    if (veh && veh._id) {
      this.createForm();
      await this.activosService.update(veh._id, this.getData());
    }
  }

  public async asignarVehiculo() {
    try {
      if (this.vehiculoInicial._id != (this.vehiculo && this.vehiculo._id)) {
        if (this.vehiculoInicial && this.vehiculoInicial._id) {
          this.verificarChofer(this.usuario);
        }
        if (this.vehiculo && this.vehiculo._id) {
          this.vehiculo.vehiculo.idChofer = this.usuario._id;
          await this.activosService.update(this.vehiculo._id, this.vehiculo);
        }
        this.helper.notifSuccess('Chofer Asignado');
      }
    } catch {
      this.helper.notifError('Error al Asignar');
    }
    this.close();
  }

  public async listarVehiculos(): Promise<void> {
    this.vehiculos$?.unsubscribe();
    this.vehiculos$ = this.listados
      .subscribe<IListado<IActivo>>('vehiculos', this.queryVehiculos)
      .subscribe((data) => {
        this.vehiculos = data.datos;
        console.log(`listado de vehiculos`, data);
      });
    await this.listados.getLastValue('vehiculos', this.queryVehiculos);
  }

  async ngOnInit() {
    this.usuario = this.param;
    await this.listarVehiculos();
    this.vehiculoActual();
  }
}
