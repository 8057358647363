import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IActivo } from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';

@Component({
  selector: 'app-detalles-colectivo',
  templateUrl: './detalles-colectivo.component.html',
  styleUrl: './detalles-colectivo.component.scss',
})
export class DetallesColectivoComponent implements OnInit, OnDestroy {
  public id?: string | null;
  public vehiculo?: IActivo;
  public vehiculo$?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private listados: ListadosService,
    public helper: HelperService,
  ) {}

  public async listar() {
    this.vehiculo$?.unsubscribe();
    this.vehiculo$ = this.listados
      .subscribe<IActivo>('activo', this.id!)
      .subscribe((data) => {
        this.vehiculo = data;
        console.log(`listado de vehiculo`, data);
      });
    await this.listados.getLastValue('activo', this.id!);
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    this.listar();
  }

  ngOnDestroy() {
    this.vehiculo$?.unsubscribe();
  }
}
