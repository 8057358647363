@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      @if (id) {
        <span class="titulo">Editar {{ cliente?.nombre }}</span>
      } @else {
        <span class="titulo">Crear Cliente</span>
      }
    </div>
    <br />
    <!-- Nombre y Tipo -->
    <mat-card>
      <mat-card-content>
        <!-- Nombre -->
        <mat-form-field style="width: 100%">
          <mat-label>Nombre del Cliente</mat-label>
          <input matInput required formControlName="nombre" />
        </mat-form-field>

        <!-- NumeroCliente -->
        <mat-form-field style="width: 100%">
          <mat-label>Numero de Cliente</mat-label>
          <input matInput required formControlName="numeroCliente" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Estado del Cliente</mat-label>
          <mat-select formControlName="estadoDeCuenta">
            @for (s of estadoCuenta; track s) {
              <mat-option [value]="s">{{ s }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <!-- Tipo (solo al crear) -->
        @if (!id) {
          <mat-form-field style="width: 100%">
            <mat-label>Tipo de Cliente</mat-label>
            <mat-select formControlName="tipoCliente" required>
              @for (t of tipoCliente; track t) {
                <mat-option [value]="t">{{ t }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      </mat-card-content>
    </mat-card>
    <br />
    @if (!editandoPropio) {
      <!-- Servicios -->
      <mat-card>
        <mat-card-header>
          <mat-card-title>Servicios</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="column-center" style="gap: 10px">
            <div class="row-space-around" style="width: 100%">
              <ng-select
                [items]="servicios"
                formControlName="idServiciosContratados"
                bindLabel="nombre"
                bindValue="_id"
                style="width: 100%"
                multiple
                (change)="this.calcularCostoTotal()"
              >
                <ng-template ng-label-tmp let-item="item">
                  <img height="15" width="15" [src]="item.icono" />
                  {{ item.nombre }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <img height="15" width="15" [src]="item.icono" />
                  {{ item.nombre }}
                </ng-template>
              </ng-select>
            </div>
            <div class="row-space-around" style="width: 100%">
              Facturación Mensual: {{ costoTotal() | currency }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <br />
    } @else {
      <mat-card>
        <mat-card-header>
          <mat-card-title>Servicios</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="column-center" style="gap: 10px">
            <app-mostrar-servicios
              [cliente]="cliente"
              orientacion="horizontal"
              [linea]="true"
              [size]="50"
              [gap]="8"
            ></app-mostrar-servicios>
          </div>
        </mat-card-content>
      </mat-card>
      <br />
    }
    <!-- Configuraciones -->
    <ng-container [formGroup]="config">
      <!-- Módulos -->
      @if (!editandoPropio || this.clientePropio?.nivel === 0) {
        <mat-card>
          <mat-card-header>
            <mat-card-title>Módulos</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="row-space-around">
              @if (
                this.clientePropio?.config?.moduloColectivos?.activo ||
                this.clientePropio?.nivel === 0
              ) {
                <div
                  class="column-center bordecito"
                  style="gap: 10px; align-items: flex-start"
                  [formGroup]="moduloColectivos"
                >
                  <span class="subtitulo">Colectivos</span>
                  <mat-checkbox formControlName="activo">Activo</mat-checkbox>
                  <mat-checkbox
                    formControlName="crearDispositivos"
                    [disabled]="
                      !this.clientePropio?.config?.moduloColectivos
                        ?.crearDispositivos ||
                      this.clientePropio?.config?.moduloColectivos
                        ?.crearDispositivos === false
                    "
                    >Crear Dispositivos</mat-checkbox
                  >
                  <mat-checkbox formControlName="derivarEventos"
                    >Derivar Eventos</mat-checkbox
                  >
                  <mat-checkbox formControlName="compartirFlota"
                    >Compartir Flota</mat-checkbox
                  >
                </div>
              }
              @if (
                this.clientePropio?.config?.moduloAlarmasDomiciliarias
                  ?.activo || this.clientePropio?.nivel === 0
              ) {
                <div
                  class="column-center bordecito"
                  style="gap: 10px; align-items: flex-start"
                  [formGroup]="moduloAlarmasDomiciliarias"
                >
                  <span class="subtitulo">Alarmas Domiciliarias</span>
                  <mat-checkbox formControlName="activo">Activo</mat-checkbox>
                  <mat-checkbox
                    formControlName="crearDispositivos"
                    [disabled]="
                      !this.clientePropio?.config?.moduloAlarmasDomiciliarias
                        ?.crearDispositivos ||
                      this.clientePropio?.config?.moduloAlarmasDomiciliarias
                        ?.crearDispositivos === false
                    "
                    >Crear Dispositivos</mat-checkbox
                  >
                  <mat-checkbox formControlName="derivarEventos"
                    >Derivar Eventos</mat-checkbox
                  >
                  <mat-checkbox formControlName="compartirAlarmas"
                    >Compartir Alarmas</mat-checkbox
                  >
                </div>
              }
              @if (
                this.clientePropio?.config?.moduloActivos?.activo ||
                this.clientePropio?.nivel === 0
              ) {
                <div
                  class="column-center bordecito"
                  style="gap: 10px; align-items: flex-start"
                  [formGroup]="moduloActivos"
                >
                  <span class="subtitulo">Activos</span>
                  <mat-checkbox formControlName="activo">Activo</mat-checkbox>
                  <mat-checkbox
                    formControlName="crearDispositivos"
                    [disabled]="
                      (!this.clientePropio?.config?.moduloActivos
                        ?.crearDispositivos ||
                        this.clientePropio?.config?.moduloActivos
                          ?.crearDispositivos === false) &&
                      this.clientePropio?.nivel !== 0
                    "
                    >Crear Dispositivos</mat-checkbox
                  >
                  <mat-checkbox formControlName="derivarEventos"
                    >Derivar Eventos</mat-checkbox
                  >
                  <mat-checkbox formControlName="compartirActivos"
                    >Compartir Activos</mat-checkbox
                  >
                </div>
              }
              @if (
                !this.clientePropio?.config?.moduloColectivos?.activo &&
                !this.clientePropio?.config?.moduloAlarmasDomiciliarias?.activo
              ) {
                <div class="row-center">
                  <span class="no-info"> No hay módulos activados </span>
                </div>
              }
            </div>
          </mat-card-content>
        </mat-card>
        <br />
      }
      <!-- Imágenes -->
      <mat-card>
        <mat-card-header>
          <mat-card-title>Imágenes</mat-card-title>
        </mat-card-header>
        <mat-card-content [formGroup]="imagenes">
          <div class="column-center" style="gap: 10px">
            <div class="row-space-around" style="width: 100%">
              <app-upload-file
                [(url)]="urlIcono"
                textoBoton="Subir Ícono"
                (urlChange)="previewTema()"
              ></app-upload-file>
              @if (urlIcono) {
                <img [src]="urlIcono" alt="ícono" width="100px" />
              } @else {
                <span class="no-info row-center">
                  Sin imágen
                  <mat-icon color="warn">close</mat-icon>
                </span>
              }
            </div>
            <div class="row-space-around" style="width: 100%">
              <app-upload-file
                [(url)]="urlBanner"
                textoBoton="Subir Banner"
                (urlChange)="previewTema()"
              ></app-upload-file>
              @if (urlBanner) {
                <img [src]="urlBanner" alt="banner" height="100px" />
              } @else {
                <span class="no-info row-center">
                  Sin imágen
                  <mat-icon color="warn">close</mat-icon>
                </span>
              }
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <br />
      <!-- Tema -->
      <mat-card>
        <mat-card-header>
          <mat-card-title> Tema </mat-card-title>
        </mat-card-header>
        <mat-card-content [formGroup]="tema">
          <section class="layout">
            <!-- Colores -->
            <div class="grow1 column-center" style="gap: 15px">
              <mat-form-field style="width: 100%">
                <mat-label>Primario</mat-label>
                <input matInput readonly formControlName="primaryColor" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="primaryColor"
                  (change)="
                    onColorChange($event, 'primaryColor'); previewTema()
                  "
                >
                </ngx-colors>
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <mat-label>Acento</mat-label>
                <input matInput readonly formControlName="accentColor" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="accentColor"
                  (change)="onColorChange($event, 'accentColor'); previewTema()"
                >
                </ngx-colors>
              </mat-form-field>

              <mat-form-field style="width: 100%">
                <mat-label>Alerta</mat-label>
                <input matInput readonly formControlName="warnColor" />
                <ngx-colors
                  class="suffix"
                  matSuffix
                  ngx-colors-trigger
                  formControlName="warnColor"
                  (change)="onColorChange($event, 'warnColor'); previewTema()"
                >
                </ngx-colors>
                <mat-hint>Usualmente es un rojo</mat-hint>
              </mat-form-field>
            </div>
          </section>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <br />
    <!-- Botones  -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.rollbackTema(); this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>
  </form>
}
