import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IQueryParam,
  IFilter,
  IPopulate,
  IListado,
  IActivo,
  IReporte,
  IGeoJSONPoint,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import {
  MapaFacilData,
  MarkerMapa,
} from '../../../../../auxiliares/mapa-facil/interfaces';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { OpenLayersService } from '../../../../../auxiliares/servicios/openLayers.service';
import { IFiltroLastReporte } from '../../../../../auxiliares/servicios/http/reportes.service';

@Component({
  selector: 'app-detalles-activo-info',
  templateUrl: './detalles-activo-info.component.html',
  styleUrl: './detalles-activo-info.component.scss',
})
export class DetallesActivoInfoComponent implements OnInit {
  public id: string | null = null;
  public title = '';
  public activo: IActivo;
  public reporte: IReporte = null;
  private reportes$: Subscription;
  public activos$: Subscription;
  public datoMapa?: MapaFacilData;

  public loading = true;
  private query: IQueryParam = {};
  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private route: ActivatedRoute,
  ) {}

  public async listar(): Promise<void> {
    const filter: IFilter<IActivo> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    const polulate: IPopulate[] = [
      {
        path: 'cliente',
        select: 'nombre',
      },
      {
        path: 'grupo',
        select: 'nombre',
      },
      {
        path: 'tracker',
        select: 'nombre idModelo',
        populate: { path: 'modelo', select: 'marca modelo' },
      },
    ];
    filter._id = this.id;
    this.query.filter = JSON.stringify(filter);
    this.query.populate = JSON.stringify(polulate);
    this.activos$?.unsubscribe();
    this.activos$ = this.listados
      .subscribe<IListado<IActivo>>('activos', this.query)
      .subscribe((data) => {
        this.activo = data.datos[0];
        console.log(`listado de activos`, data);
      });
    await this.listados.getLastValue('activos', this.query);
  }
  public async listarUltimoReporte(): Promise<void> {
    const query: IFiltroLastReporte = {
      idsActivos: [this.id],
    };
    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IReporte[]>('reportesLastFiltered', query)
      .subscribe(async (data) => {
        if (data[0]) this.reporte = data[0];
        console.log(`listado de reportes ${new Date().toISOString()}`, data);
      });
    await this.listados.getLastValue('reportesLastFiltered', query);
  }

  public datadelmapa() {
    if (this.reporte) {
      const cordinate = OpenLayersService.lonLatToCoordinate(
        (this.reporte?.geojson as IGeoJSONPoint).coordinates[0],
        (this.reporte?.geojson as IGeoJSONPoint).coordinates[1],
      );
      const marker: MarkerMapa = {
        label: 'puntoSimple',
        coor: cordinate,
      };
      const mapaFacil: MapaFacilData = { markers: [marker] };
      this.datoMapa = mapaFacil;
    }
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.paramMap);
    this.id = params.get('id');
    await Promise.all([this.listar(), this.listarUltimoReporte()]);
    this.datadelmapa();
    this.loading = false;
  }
}
