import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICliente,
  IFilter,
  IGrupo,
  IListado,
  IPopulate,
  IQueryParam,
  IRecorrido,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { RecorridosService } from '../recorridos.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../../auxiliares/servicios/listados.service';
import { HelperService } from '../../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../../auxiliares/dialog/dialog.service';
import {
  LineStringMapa,
  MapaFacilData,
} from '../../../../../auxiliares/mapa-facil/interfaces';
import { MapaFacilDialogComponent } from '../../../../../auxiliares/mapa-facil-dialog/mapa-facil-dialog.component';

@Component({
  selector: 'app-listado-recorridos',
  templateUrl: './listado-recorridos.component.html',
  styleUrl: './listado-recorridos.component.scss',
})
export class ListadoRecorridosComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = ListadoRecorridosComponent.name;
  public recorridos: IRecorrido[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<IRecorrido>[];

  //Subs
  public recorridos$?: Subscription;
  public clientes$?: Subscription;
  public grupos$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear Recorrido',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crear(),
  };
  public search: IRegExpSearch = {
    fields: ['recorrido'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public grupo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idGrupo',
    },
    label: 'Linea',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public filtros: IFiltroTabla[] = [this.grupo];
  //Query
  public filter: IFilter<IRecorrido> = {};
  public populate: IPopulate[] = [
    {
      path: 'grupo',
    },
  ];
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: RecorridosService,
    private navigator: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar(), this.listarClientes(), this.listarGrupos()]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    this.recorridos$?.unsubscribe();
    this.recorridos$ = this.listados
      .subscribe<IListado<IRecorrido>>('recorridos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.recorridos = data.datos;
        console.log(`listado de recorridos`, data);
      });
    await this.listados.getLastValue('recorridos', this.query);
  }
  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }
  private async listarGrupos(): Promise<void> {
    const filter: IFilter<IGrupo> = {
      categoria: 'Línea de colectivo',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.grupos$?.unsubscribe();
    this.grupos$ = this.listados
      .subscribe<IListado<IGrupo>>('grupos', query)
      .subscribe((data) => {
        this.grupo.elementos = data.datos;
        console.log(`listado de grupos`, data);
      });
    await this.listados.getLastValue('grupos', query);
  }

  // Acciones
  public async crear() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(recorrido: IRecorrido) {
    this.navigator.navigate(['editar', recorrido._id], {
      relativeTo: this.route,
    });
  }

  public async ubicar(r: IRecorrido) {
    const polyline: LineStringMapa = {
      color: r.color,
      points: r.recorridoOl,
      width: 6,
      centrar: true,
    };
    const data: MapaFacilData = {
      polylines: [polyline],
      zoom: 14,
    };
    const config: MatDialogConfig = {
      data,
      minWidth: '450px',
    };
    this.dialog.open(MapaFacilDialogComponent, config);
  }

  public async eliminar(recorrido: IRecorrido) {
    const res = await this.dialogService.confirm(
      'Eliminar Recorrido',
      `¿Desea eliminar el recorrido ${recorrido.nombre}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(recorrido._id!);
      this.helper.notifSuccess('Recorrido eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      // Grupo
      {
        header: {
          label: 'Linea',
        },
        row: {
          parse(dato) {
            return dato.grupo?.nombre;
          },
          noData: 'Sin Linea',
        },
      },
      // Nombre
      {
        header: {
          label: 'Nombre',
        },
        row: {
          field: 'nombre',
        },
      },
      // Destino
      {
        header: {
          label: 'Destino',
        },
        row: {
          field: 'destino',
        },
      },
      // Por
      {
        header: {
          label: 'Por',
        },
        row: {
          field: 'por',
        },
      },
      // Paradas
      {
        header: {
          label: 'Paradas',
        },
        row: {
          parse(dato) {
            return dato.paradas?.length;
          },
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'primary',
              icon: 'place',
              tooltip: 'Ubicar',
              click: (dato) => this.ubicar(dato),
            },
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    ];
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();
    await this.listarTodo();
  }

  ngOnDestroy() {
    this.recorridos$?.unsubscribe();
  }
}
