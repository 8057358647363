<section class="layout">
  <div class="header">
    <div class="column-center mat-elevation-z4" style="padding: 0.5em">
      <!-- Vehiculo -->
      <div class="row-flex-start nombre">
        <span style="margin-right: 0.5em">
          Vehiculo: {{ vehiculo?.identificacion }}
          @if (vehiculo?.vehiculo.patente) {
            - {{ vehiculo?.vehiculo.patente }}
          }
        </span>

        @switch (vehiculo?.vehiculo.estado) {
          @case ("Operativo") {
            <mat-icon matTooltip="Operativo" class="ok">check</mat-icon>
          }
          @case ("En mantenimiento") {
            <mat-icon matTooltip="En Mantenimiento" class="warn">
              handyman
            </mat-icon>
          }
          @case ("Fuera de servicio") {
            <mat-icon matTooltip="Fuera de Servicio" class="error">
              clear
            </mat-icon>
          }
          @default {
            <div class="no-info">Sin Estado</div>
          }
        }
      </div>

      <!-- Grupo -->
      @if (vehiculo?.grupo) {
        <div class="detalles">
          <span>Grupo: </span>
          <span>{{ vehiculo?.grupo?.nombre }}</span>
        </div>
      }
      <!-- Tracker -->
      @if (vehiculo?.tracker) {
        <div class="detalles" style="width: 100%">
          <span>Tracker: </span>
          <span>{{
            vehiculo?.tracker?.identificacion || vehiculo?.tracker?.nombre
          }}</span>
        </div>
      }
      <!-- Recorrido -->
      @if (vehiculo?.vehiculo.recorrido) {
        <div class="column-center">
          <div class="row-center" style="gap: 15px">
            Recorrido: {{ vehiculo?.vehiculo.recorrido?.nombre }}
            <ngx-colors
              ngx-colors-trigger
              disabled
              [(ngModel)]="vehiculo!.vehiculo.recorrido!.color"
            >
            </ngx-colors>
          </div>
          <div></div>
          <div class="row-center" style="gap: 15px">
            <span>A: {{ vehiculo?.vehiculo.recorrido?.destino }}</span>
            <span>Por: {{ vehiculo?.vehiculo.recorrido?.por }}</span>
          </div>
        </div>
        <br />
        <section class="franjas">
          @for (
            f of vehiculo?.vehiculo.recorrido?.franjaHoraria;
            track $index
          ) {
            <div class="column-center rango">
              <div>
                {{ helper.diaATexto(f.dia!) }}
              </div>

              <div>
                Rango: {{ helper.getHora(f.desde!) }} -
                {{ helper.getHora(f.hasta!) }}
              </div>

              <div>Frecuencia: {{ f.frecuenciaMinutos }} min.</div>
            </div>
          }
        </section>
      }
    </div>
  </div>
  <div class="main">
    @if (vehiculo) {
      <app-viajes-paradas [vehiculo]="vehiculo"></app-viajes-paradas>
      <br />

      <div class="mat-elevation-z4" style="padding: 0.5em">
        <div class="row-flex-start nombre">
          <span>Trackeos</span>
        </div>
        <br />
        <!-- <app-detalles-trackeos [vehiculo]="vehiculo"></app-detalles-trackeos> -->
        <app-listado-trackeos [vehiculo]="vehiculo"></app-listado-trackeos>
      </div>
      <br />
      <div class="mat-elevation-z4" style="padding: 0.5em">
        <div class="row-flex-start nombre">
          <span>Log de Trackeos</span>
        </div>
        <br />
        <app-listado-logs-trackeos
          [vehiculo]="vehiculo"
        ></app-listado-logs-trackeos>
      </div>
    }
  </div>

  <!-- Trackeos -->
  <!-- @if (vehiculo) {

    } -->
  <!-- </div> -->
</section>
