<div class="isla-contenedor magico-100">
  <div (click)="close()" class="isla-close">
    <mat-icon style="transform: scale(0.7, 0.7)" color="warn">close</mat-icon>
  </div>
  @if (reporte?.activo || alarma) {
    <!-- Loading -->
    @if (this.loading.getLoading()) {
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    }
    <!-- Identificacion -->

    @if (reporte?.activo?.categoria === "Vehículo") {
      <span
        class="isla-titulo"
        style="cursor: pointer"
        [routerLink]="[
          '../colectivos/colectivos/detalles',
          reporte?.activo?._id,
        ]"
      >
        <span>🚐</span>
        {{ reporte?.activo?.identificacion }}
      </span>
    }
    @if (reporte?.activo?.categoria === "Normal") {
      <span
        class="isla-titulo"
        style="cursor: pointer"
        [routerLink]="['../activos/activos/detalles', reporte?.activo?._id]"
      >
        <span>🧭</span>
        {{ reporte?.activo?.identificacion }}
      </span>
    }
    @if (alarma) {
      <span class="isla-titulo" style="cursor: pointer">
        <span>🏠</span>
        {{ alarma?.nombre }}
      </span>
    }

    <!-- Cliente -->
    @if (cliente?.tipoCliente != "Final") {
      <div class="isla-dato">
        <div>Cliente:</div>
        <div>
          {{ reporte?.cliente.nombre || alarma?.cliente.nombre }}
        </div>
      </div>
    }
    @if (reporte) {
      <!-- grupo -->
      @if (reporte?.grupo?.nombre) {
        <div class="isla-dato">
          <div>Grupo:</div>
          <div>{{ reporte?.grupo?.nombre }}</div>
        </div>
      } @else {
        <div class="isla-dato-no-info">
          <span class="no-info">Sin grupo</span>
        </div>
      }
      <!-- Fecha  -->
      @if (reporte?.fechaCreacion) {
        <div class="isla-dato">
          <div>Último Reporte:</div>
          <div>{{ reporte?.fechaCreacion | date: "short" }}</div>
        </div>
      } @else {
        <div class="isla-dato-no-info">
          <span class="no-info">Sin Fecha</span>
        </div>
      }
      <!-- Bateria  -->
      @if (
        reporte?.reporteQualcomm?.["results"]?.[0]?.batteryStatus
          .batteryPercentage
      ) {
        <div class="isla-dato">
          <div>Bateria:</div>
          <div>
            {{
              reporte?.reporteQualcomm["results"][0]?.batteryStatus
                .batteryPercentage
            }}
            %
          </div>
        </div>
      } @else {
        <div class="isla-dato-no-info">
          <span class="no-info">Sin Bateria Reportada</span>
        </div>
      }

      <!-- Vehiculo -->
      @if (reporte?.activo?.categoria === "Vehículo") {
        <!-- Patente -->
        @if (reporte?.activo.vehiculo?.patente) {
          <div class="isla-dato">
            <div>Patente:</div>
            <div>
              {{ reporte?.activo.vehiculo?.patente }}
            </div>
          </div>
        }
        <!-- Recorridos -->
        @if (reporte?.activo.vehiculo?.recorridos?.length) {
          @for (
            item of reporte.activo.vehiculo.recorridos;
            track $index;
            let first = $first
          ) {
            <div class="isla-dato">
              <div *ngIf="first">Recorridos:</div>
              <div *ngIf="!first"></div>
              <div>{{ item.nombre }}</div>
            </div>
          }
        } @else {
          <div class="isla-dato-no-info">
            <span class="no-info">Sin Recorridos</span>
          </div>
        }
        <!-- Recorrido Actual -->
        @if (reporte?.recorrido?.nombre) {
          <div class="isla-dato">
            <div>Recorrido Actual:</div>
            <div>{{ reporte?.recorrido?.nombre }}</div>
          </div>
        } @else {
          <div class="isla-dato-no-info">
            <span class="no-info">Sin Recorrido actual</span>
          </div>
        }
        <!-- Velocidad  -->
        @if (reporte?.velocidad) {
          <div class="isla-dato">
            <div>Velocidad:</div>
            <div>{{ reporte?.velocidad | number: "1.0-0" }} km/h</div>
          </div>
        } @else {
          <div class="isla-dato-no-info">
            <span class="no-info">Sin Velocidad Reportada</span>
          </div>
        }
        <!-- Odómetro  -->
        @if (reporte?.reporteTraccar["attributes"]?.totalDistance) {
          <div class="isla-dato">
            <div>Odómetro:</div>
            <div>
              {{
                reporte?.reporteTraccar["attributes"].totalDistance / 1000
                  | number: "1.0-0"
              }}
              km
            </div>
          </div>
        } @else {
          <div class="isla-dato-no-info">
            <span class="no-info">Sin Odometro Reportado</span>
          </div>
        }
        <!-- Última Parada  -->
        @if (trackeo?.fecha) {
          <div class="isla-dato">
            <div>Última Parada:</div>
            <div>{{ trackeo?.fecha | date: "short" }}</div>
          </div>
          <div class="isla-dato">
            <div>Nombre:</div>
            <div>
              {{ parada?.nombre }} - #{{ idxParada }} /
              {{ trackeo?.recorrido?.paradas?.length }}
            </div>
          </div>
          <!-- <div class="isla-dato">
          <div>Destino:</div>
          <div>{{ parada?.destino }}</div>
        </div>
        <div class="isla-dato">
          <div>Por:</div>
          <div>{{ parada?.por }}</div>
        </div> -->
        } @else {
          <div class="isla-dato-no-info">
            <span class="no-info">Sin Última Parada</span>
          </div>
        }
        <!-- Datos del Chofer  -->
        @if (reporte?.chofer?._id) {
          <div
            class="isla-foto-container"
            style="cursor: pointer"
            [routerLink]="['../choferes/editar', reporte?.idChofer]"
          >
            <div class="isla-foto" [style.background-image]="getFoto()"></div>
          </div>
          <div class="isla-dato">
            <div>Chofer:</div>
            <div>{{ reporte?.chofer?.datosPersonales?.nombre }}</div>
          </div>
        }
      }
    }
    <!-- Editar -->
    <div class="isla-editar">
      <!-- Editar Colectivo -->
      @if (reporte?.activo?.categoria === "Vehículo") {
        <button
          mat-raised-button
          color="primary"
          [routerLink]="['../vehiculos/editar', reporte?.activo?._id]"
        >
          Editar
        </button>
      }
      @if (reporte?.activo?.categoria === "Normal") {
        <!-- Editar Activo -->
        <button
          mat-raised-button
          color="primary"
          (click)="editarActivo(reporte?.activo)"
        >
          Editar
        </button>
      }
      @if (alarma) {
        <!-- Editar Alarma -->
        <button mat-raised-button color="primary" (click)="editarAlarma()">
          Editar
        </button>
      }
    </div>
  }
</div>
