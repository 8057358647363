import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICliente } from 'modelos/src';
import { ListadosService } from '../../../../../../auxiliares/servicios';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';

@Component({
  selector: 'app-detalles-cliente-info',
  templateUrl: './detalles-cliente-info.component.html',
  styleUrl: './detalles-cliente-info.component.scss',
})
export class DetallesClienteInfoComponent implements OnInit {
  @Input() public cliente: ICliente;

  public loading = true;
  constructor(
    public helper: HelperService,
    private listados: ListadosService,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this.loading = false;
  }
}
