import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICliente,
  ICodigosDispositivo,
  IFilter,
  IListado,
  IQueryParam,
  TipoDispositivo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';
import {
  IColumnas,
  ICrearBoton,
} from '../../../../auxiliares/tabla/tabla.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../../auxiliares/tabla/filtro/filtro.component';
import { ListadosService } from '../../../../auxiliares/servicios';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { DialogService } from '../../../../auxiliares/dialog/dialog.service';
import { CodigosDispositivosService } from '../../../../auxiliares/servicios/http/codigos-dispositivos.service';
import { DetallesCodigosDispositivosComponent } from '../detalles-codigos-dispositivos/detalles-codigos-dispositivos.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-listado-codigos-dispositivos',
  templateUrl: './listado-codigos-dispositivos.component.html',
  styleUrl: './listado-codigos-dispositivos.component.scss',
})
export class ListadoCodigosDispositivoComponent implements OnInit, OnDestroy {
  public tipo?: TipoDispositivo;
  //// Tabla nueva
  public name = ListadoCodigosDispositivoComponent.name;
  public codigosDispositivos: ICodigosDispositivo[] = [];
  public totalCount = 0;
  public columnas?: IColumnas<ICodigosDispositivo>[];
  //Subs
  public codigosDispositivos$?: Subscription;
  public clientes$?: Subscription;
  //
  private childrenLevel?: number = -1;
  private includeChildren?: boolean;
  //
  public botonCrear: ICrearBoton = {
    mostrar: this.helper.puedeEditar(),
    tooltip: 'Crear CodigosAlarma',
    color: 'accent',
    tipo: 'material',
    icon: 'add',
    accion: () => this.crearCodigosAlarma(),
  };
  public search: IRegExpSearch = {
    fields: ['nombre'],
  };
  // FILTROS
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'ngselect',
  };
  public filtros: IFiltroTabla[] = [];

  //Query
  public query: IQueryParam = {
    sort: 'nombre',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    private listados: ListadosService,
    public helper: HelperService,
    private dialogService: DialogService,
    private service: CodigosDispositivosService,
    public dialog: MatDialog,
    private navigator: Router,
    private route: ActivatedRoute,
  ) {}
  // Listars
  public async listarTodo() {
    if (
      this.query.childrenLevel !== this.childrenLevel ||
      this.includeChildren !== this.query.includeChildren
    ) {
      this.includeChildren = this.query.includeChildren;
      this.childrenLevel = this.query.childrenLevel;
      Promise.all([this.listar(), this.listarClientes()]);
    } else {
      await this.listar();
    }
  }
  public async listar(): Promise<void> {
    const filter: IFilter<ICodigosDispositivo> = this.query.filter
      ? JSON.parse(this.query.filter)
      : {};
    filter.tipo = this.tipo;
    this.query.filter = JSON.stringify(filter);

    //
    this.codigosDispositivos$?.unsubscribe();
    this.codigosDispositivos$ = this.listados
      .subscribe<
        IListado<ICodigosDispositivo>
      >('codigosDispositivos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.codigosDispositivos = data.datos;
        console.log(`listado de codigosDispositivos`, data);
      });
    await this.listados.getLastValue('codigosDispositivos', this.query);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
      limit: 0,
      includeChildren: this.includeChildren,
      childrenLevel: this.childrenLevel,
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }
  // Acciones
  public crearCodigosAlarma() {
    this.navigator.navigate(['crear'], { relativeTo: this.route });
  }

  public async editar(codigosAlarma: ICodigosDispositivo) {
    this.navigator.navigate(['editar', codigosAlarma._id], {
      relativeTo: this.route,
    });
  }

  public async codigos(codigosAlarma: ICodigosDispositivo) {
    this.dialog.open(DetallesCodigosDispositivosComponent, {
      data: codigosAlarma,
      width: '600px',
    });
  }

  public async eliminar(codigosalarma: ICodigosDispositivo) {
    const res = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Desea eliminar los códigos de ${codigosalarma.nombre}?`,
    );
    if (!res) return;
    try {
      await this.service.delete(codigosalarma._id!);
      this.helper.notifSuccess('CodigosAlarma eliminado');
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      // Nombre
      {
        header: {
          label: 'Nombre',
          sortable: true,
        },
        row: {
          field: 'nombre',
        },
      },
      // Codigos

      // Nombre
      {
        header: {
          label: 'Códigos',
        },
        row: {
          parse: (dato) => {
            return dato.codigos?.length || 0;
          },
        },
      },
      // Acciones
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'material',
              color: 'accent',
              icon: 'edit',
              tooltip: 'Editar',
              click: (dato) => this.editar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
            {
              tipo: 'material',
              color: 'primary',
              icon: 'search',
              tooltip: 'Codigos',
              click: (dato) => this.codigos(dato),
            },
            {
              tipo: 'material',
              color: 'warn',
              icon: 'delete',
              tooltip: 'Eliminar',
              click: (dato) => this.eliminar(dato),
              disabled: () => !this.helper.puedeEditar(),
            },
          ],
        },
      },
    ];
  }

  // Hooks

  async ngOnInit() {
    if (HelperService.getTipoCliente() !== 'Final') {
      this.filtros.push(this.cliente);
    }
    this.setColumnas();

    this.route.params.subscribe(async (params) => {
      this.tipo = params['tipo'] as TipoDispositivo;
      console.log('Tipo', this.tipo);
      await Promise.all([this.listarTodo()]);
    });
  }

  async ngOnDestroy() {
    this.codigosDispositivos$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
