@if (form) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <!-- Título -->
    <div class="row-center">
      <span class="titulo"> {{ title }} Recordatorio </span>
    </div>
    <br />
    <div
      class="column-center mat-elevation-z1"
      style="gap: 15px; padding: 0.5em"
    >
      <div class="row-flex-start" style="width: 100%">
        <span class="subtitulo"> Datos </span>
      </div>
      <!-- Tipo // Vehiculo -->
      <section class="layout">
        <ng-select
          [items]="tipos"
          bindLabel="tipo"
          formControlName="tipo"
          placeholder="Tipo"
          style="width: 100%"
          multiple="true"
          searchable="true"
        >
        </ng-select>
        @if (tieneKm()) {
          <mat-checkbox formControlName="repetible"> Repetible </mat-checkbox>
        }
        <ng-select
          [items]="vehiculos"
          bindLabel="identificacion"
          bindValue="_id"
          formControlName="idActivo"
          placeholder="Vehiculo"
          [notFoundText]="'No hay vehiculos creados'"
          style="width: 100%"
          multiple="false"
          searchable="true"
        >
        </ng-select>
      </section>
      <!-- Cliente  -->
      <section class="layout">
        <ng-select
          [items]="clientes"
          bindLabel="nombre"
          bindValue="_id"
          formControlName="idCliente"
          placeholder="Cliente"
          [notFoundText]="'No hay clientes creados'"
          style="width: 100%"
          multiple="false"
          searchable="true"
        >
        </ng-select>
      </section>

      <!-- Fecha // KM del Mantenimiento -->
      @if (!repetible()) {
        <section class="layout">
          <mat-form-field style="width: 100%">
            <mat-label>Fecha</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="fechaLimite"
            />
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field style="width: 100%">
            <mat-label>Km del Mantenimiento</mat-label>

            <input matInput formControlName="kmLimite" />
          </mat-form-field>
        </section>
      } @else {
        <section class="layout">
          <mat-form-field style="width: 100%">
            <mat-label>Apartir del Kilometro</mat-label>
            <mat-hint
              >Km del primer recordatorio (cero es apartir del Km
              actual)</mat-hint
            >
            <input matInput formControlName="kmLimite" />
          </mat-form-field>
          <mat-form-field style="width: 100%">
            <mat-label>Frecuencia del Recordatorio</mat-label>
            <mat-hint>Cada cuantos km</mat-hint>
            <input matInput formControlName="frecuencia" />
          </mat-form-field>
        </section>
      }
      <section class="layout">
        <mat-form-field style="width: 100%">
          <mat-label>Detalles del Mantenimiento</mat-label>
          <input matInput formControlName="detallesDelMantenimiento" />
        </mat-form-field>
      </section>
    </div>
    <!-- Botones  -->
    <div class="row-flex-end" style="gap: 10px">
      <app-button
        matType="mat-fab extended"
        color="warn"
        (click)="this.helper.volver()"
        text="Volver"
        icon="arrow_back"
      ></app-button>
      <app-button
        matType="mat-fab extended"
        [disabled]="!this.form.valid"
        color="accent"
        text="Guardar"
        icon="save"
        type="submit"
      ></app-button>
    </div>
  </form>
}
