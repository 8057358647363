import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IRecorrido } from 'modelos/src';

@Component({
  selector: 'app-flotante',
  templateUrl: './flotante.component.html',
  styleUrl: './flotante.component.scss',
})
export class FlotanteComponent {
  @Input() recorridos: IRecorrido[] = [];
  @Output() recorridosChange = new EventEmitter<IRecorrido[]>();
  @Input() vehiculos?: number;
  public activosShow: boolean = true;
  constructor() {}

  public onRecorridoChange(recorridos: IRecorrido[]) {
    this.recorridosChange.emit(recorridos);
  }
}
