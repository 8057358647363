/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Feature, Map, View } from 'ol';
import {
  ICategoriaActivo,
  IFilter,
  IGeoJSONPolygon,
  IListado,
  IPopulate,
  IQueryParam,
  IRecorrido,
  IReporte,
  ITrackeo,
  IUbicacion,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { Circle, Geometry, LineString, Point, Polygon } from 'ol/geom';
import { OpenLayersService } from '../../../../auxiliares/servicios/openLayers.service';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Icon from 'ol/style/Icon';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { Coordinate } from 'ol/coordinate';
import VectorSource from 'ol/source/Vector';
import { IFiltroLastReporte } from '../../../../auxiliares/servicios/http/reportes.service';
import Fill from 'ol/style/Fill';

@Component({
  selector: 'app-mapa-colectivos',
  templateUrl: './mapa-colectivos.component.html',
  styleUrl: './mapa-colectivos.component.scss',
})
export class MapaColectivosComponent implements OnInit, OnDestroy {
  public ctrl = false;
  //Vehiculos
  public reportes?: IReporte[] = [];
  public reporte?: IReporte;
  public reportes$?: Subscription;

  // Recorridos
  public recorridosSeleccionados: IRecorrido[] = [];
  public recorridos: IRecorrido[] = [];
  public recorridos$?: Subscription;
  // Trackeo
  public trackeo?: ITrackeo;
  private trackeo$?: Subscription;

  //Terminals
  public terminals?: IUbicacion[] = [];
  private $terminals$?: Subscription;

  /// OPENLAYERS
  public map?: Map;
  // Layer de Markers
  public markerVectorOptions = OpenLayersService.vehiculosVectorLayer();
  public orientationVectorOptions =
    OpenLayersService.vehiculosCompassVectorLayer();
  /// Layer de Polylines
  public polylineVectorOptions = OpenLayersService.polylineVectorLayer();
  /// Layer de Paradas
  public paradasVectorOptions = OpenLayersService.paradasChicasVectorLayer();
  public circleVectorOptions = OpenLayersService.circleVectorLayer();
  /// Layer Polygon
  public polygonVectorOptions = OpenLayersService.polygonsVectorLayer();
  /// Centro
  private puntos: [number, number][] = [];

  constructor(
    private listados: ListadosService,
    private helper: HelperService,
  ) {}

  /// Vehiculos
  public async listar(): Promise<void> {
    const categoriaActivo: ICategoriaActivo = 'Vehículo';
    const query: IFiltroLastReporte = {
      categoriaActivo,
    };
    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IReporte[]>('reportesLastFiltered', query)
      .subscribe(async (data) => {
        this.reportes = data;
        this.addMarkers();
        console.log(
          `listado de vehiculosEnVivo2 ${new Date().toISOString()}`,
          data,
        );
      });
    await this.listados.getLastValue('reportesLastFiltered', query);
  }

  public async listarTerminales(): Promise<void> {
    const categoriaActivo: ICategoriaActivo = 'Vehículo';
    const query: IFiltroLastReporte = {
      categoriaActivo,
    };
    this.reportes$?.unsubscribe();
    this.reportes$ = this.listados
      .subscribe<IListado<IUbicacion>>('terminals', query)
      .subscribe(async (data) => {
        this.terminals = data.datos;
        console.log(`listado de terminals ${new Date().toISOString()}`, data);
      });
    await this.listados.getLastValue('terminals', query);
  }

  private async listarTrackeo() {
    const filter: IFilter<ITrackeo> = {
      idActivo: this.reporte!.activo?._id,
    };
    const populate: IPopulate = {
      path: 'recorrido',
      select: 'paradas',
    };
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      sort: '-fecha',
      limit: 1,
      includeChildren: true,
      childrenLevel: 0,
    };
    this.trackeo$?.unsubscribe();
    this.trackeo$ = this.listados
      .subscribe<IListado<ITrackeo>>('trackeos', query)
      .subscribe((data) => {
        this.trackeo = data.datos[0];
        if (this.reporte) this.addCircle();
        console.log(`Trackeo listado: `, data);
      });
    await this.listados.getLastValue('trackeos', query);
  }

  private addMarkers() {
    const source = this.markerVectorOptions.getSource();
    if (!source) return;
    source.clear();
    const orientationSource = this.orientationVectorOptions.getSource();
    if (!orientationSource) return;
    orientationSource.clear();
    if (this.reportes) {
      const idsRecorridos = this.recorridosSeleccionados.map((r) => r._id);
      for (const reporte of this.reportes) {
        if (
          this.reporte &&
          this.reporte.activo.vehiculo &&
          this.reporte.activo._id === reporte.activo._id
        ) {
          this.reporte.fechaCreacion = reporte.fechaCreacion;
        }
        if (!reporte.geojson?.coordinates) return;
        // Solo mostrar los vehiculos que estén en los recorridos seleccionados
        if (idsRecorridos.length > 0) {
          if (!idsRecorridos.includes(reporte.activo.vehiculo.idRecorrido))
            continue;
        }
        this.puntos.push(reporte.geojson.coordinates);
        // marker
        const coord = OpenLayersService.lonLatToCoordinate(
          reporte.geojson.coordinates[0],
          reporte.geojson.coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: reporte,
          tipo: 'vehiculo',
        });
        source.addFeature(feature);
        // orientation
        if (reporte.reporteTraccar?.['course']) {
          const radian = (reporte.reporteTraccar?.['course'] * Math.PI) / 180;
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'vehiculo',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/brujula.png',
              height: 40,
              width: 40,
              rotation: radian,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        } else {
          const featureOrientation: Feature<Geometry> = new Feature({
            geometry: new Point(coord),
            data: reporte,
            tipo: 'vehiculo',
          });
          const style = new Style({
            image: new Icon({
              anchor: [0.5, 0.5],
              src: 'assets/map/circle.png',
              height: 40,
              width: 40,
            }),
          });
          featureOrientation.setStyle(style);
          orientationSource.addFeature(featureOrientation);
        }
      }
    }
  }

  /// Ver Terminales de los recorridos elegidos
  public mostrarTerminales(idsTerminales: string[], color: string) {
    const source = this.polylineVectorOptions.getSource();
    if (!source) return;
    const terminales = this.terminals.filter((i) =>
      idsTerminales.includes(i._id),
    );
    terminales.map((t) => {
      const feature: Feature<Geometry> = new Feature({
        geometry: new Polygon(
          OpenLayersService.polygonToCoordinates(
            (t.geojson as IGeoJSONPolygon).coordinates,
          ),
        ),
      });
      const color2 = this.hexToRgba(color, 0.2);
      const s = new Style({
        stroke: new Stroke({
          color: color,
          width: 4,
        }),
        fill: new Fill({
          color: color2,
        }),
      });
      feature.setStyle(s);
      source.addFeature(feature);
    });
  }

  /// Recorridos
  public async listarRecorridos(): Promise<void> {
    const populate: IPopulate[] = [
      {
        path: 'grupo',
      },
      {
        path: 'ubicaciones',
      },
    ];
    const query: IQueryParam = {
      populate: JSON.stringify(populate),
      sort: 'nombre',
      includeChildren: true,
      childrenLevel: 0,
    };
    this.recorridos$?.unsubscribe();
    this.recorridos$ = this.listados
      .subscribe<IListado<IRecorrido>>('recorridos', query)
      .subscribe((data) => {
        this.recorridos = data.datos;
        this.addPolyLines();

        console.log(`listado de recorridos`, data);
      });
    await this.listados.getLastValue('recorridos', query);
  }

  private addPolyLines() {
    const source = this.polylineVectorOptions.getSource();
    if (!source) return;
    source.clear();
    if (this.recorridosSeleccionados.length > 0) {
      this.recorridosSeleccionados.forEach((recorrido) => {
        if (!recorrido.recorridoOl) return;

        const feature: Feature<Geometry> = new Feature({
          geometry: new LineString(recorrido.recorridoOl),
          data: recorrido,
        });
        const s = new Style({
          stroke: new Stroke({
            color: recorrido.color,
            width: 4,
          }),
        });
        feature.setStyle(s);
        source.addFeature(feature);
        if (recorrido.idsUbicaciones)
          this.mostrarTerminales(recorrido.idsUbicaciones, recorrido.color);
      });
    } else {
      this.recorridos.forEach((recorrido) => {
        if (!recorrido.recorridoOl) return;

        const feature: Feature<Geometry> = new Feature({
          geometry: new LineString(recorrido.recorridoOl),
          data: recorrido,
        });
        const s = new Style({
          stroke: new Stroke({
            color: recorrido.color,
            width: 4,
          }),
        });
        feature.setStyle(s);
        source.addFeature(feature);
      });
    }
  }

  public async onRecorridosChange(r: IRecorrido[]) {
    this.recorridosSeleccionados = r;
    this.addPolyLines();
    this.addMarkers();
    this.reporte = undefined;
    this.trackeo = undefined;
  }

  private async initMap() {
    const position = await OpenLayersService.getCurrentPosition();
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'map',
      controls: [],
      layers: [
        OpenLayersService.mapTile(),
        this.polylineVectorOptions,
        this.orientationVectorOptions,
        this.markerVectorOptions,
        this.paradasVectorOptions,
        this.circleVectorOptions,
        this.polygonVectorOptions,
      ],
      view: new View({
        center: position,
        zoom: 16,
      }),
    });

    this.map.on('error', (e) => {
      console.error('Error en el mapa', e);
    });

    await this.handleClick();
    const sourceres: VectorSource<Feature<Geometry>> = new VectorSource();
    if (this.reportes?.length) {
      const sourceMarker = this.markerVectorOptions.getSource();
      sourceMarker.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (sourceres) {
      this.setBounds(sourceres);
    }
  }

  private async handleClick() {
    if (!this.map) return;
    this.reporte = undefined;
    this.trackeo = undefined;
    this.map.on('singleclick', async (evt) => {
      const feature = this.map?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          const geometry = feature.getGeometry();
          if (geometry instanceof Point) {
            if (feature.get('tipo') === 'vehiculo') {
              /// ES UN VEHICULO
              return feature;
            }
            if (feature.get('tipo') === 'activo') {
              /// ES UN ACTIVO
              return feature;
            }
            /// ES UNA PARADA
            return null;
          } else if (geometry instanceof LineString) {
            return null;
          }
          return null;
        },
      );

      if (!feature) return;

      const data = feature.get('data') as IReporte;
      if (feature.get('tipo') === 'vehiculo') {
        this.reporte = data;
        const recorrido = this.recorridos.find(
          (r) => r._id === this.reporte?.idRecorrido,
        );
        const recorridos = this.recorridos.filter((r) =>
          this.reporte?.activo.vehiculo?.idsRecorridos?.includes(r._id),
        );
        if (recorrido) {
          this.reporte.recorrido = recorrido;
        }
        if (recorridos) {
          this.reporte.activo.vehiculo.recorridos = recorridos;
        }
        await this.addParadas();
        await this.addCircle();
        await this.addPolygons();
        if (!this.reporte?.geojson?.coordinates) return;
      }
      if (feature.get('tipo') === 'activo') {
        this.reporte = data;
        if (!this.reporte?.geojson?.coordinates) return;
      }
    });
  }

  private async addParadas() {
    await this.listarTrackeo();
    // Paradas
    const source = this.paradasVectorOptions.getSource();
    if (!source) return;
    source.clear();
    if (!this.reporte) return;
    const recorrido = this.recorridos.find(
      (r) => r._id === this.reporte?.idRecorrido,
    );
    if (!recorrido) return;
    const paradas = recorrido.paradas;
    if (!paradas) return;
    // Agrego las paradas
    for (const p of paradas) {
      if (!p.geojson?.coordinates) continue;
      const coord = OpenLayersService.lonLatToCoordinate(
        p.geojson?.coordinates[0],
        p.geojson?.coordinates[1],
      );
      const feature: Feature<Geometry> = new Feature({
        geometry: new Point(coord),
        data: p,
        id: p._id,
      });
      source.addFeature(feature);
    }
  }

  private async addCircle() {
    const recorrido = this.recorridos.find(
      (r) => r._id === this.reporte?.idRecorrido,
    );
    if (!recorrido) return;
    const paradas = recorrido.paradas;
    if (!paradas) return;
    // Agrego el circulo sobre la que está en el trackeo

    if (!this.trackeo) return;

    const sourceCircle = this.circleVectorOptions.getSource();
    if (!sourceCircle) return;
    sourceCircle.clear();
    const p = paradas.find((p) => p._id === this.trackeo!.idParada);
    if (!p) return;
    if (!p.geojson?.coordinates) return;

    const coord = OpenLayersService.lonLatToCoordinate(
      p.geojson?.coordinates[0],
      p.geojson?.coordinates[1],
    );
    const feature: Feature<Geometry> = new Feature({
      geometry: new Circle(coord, 10),
      data: p,
      id: p._id,
    });
    sourceCircle.addFeature(feature);
  }

  private clearParadas() {
    const source = this.paradasVectorOptions.getSource();
    if (!source) return;
    source.clear();
    const sourceCircle = this.circleVectorOptions.getSource();
    if (!sourceCircle) return;
    sourceCircle.clear();
  }

  private async addPolygons() {
    // Polygons
    const source = this.polygonVectorOptions.getSource();
    if (!source) return;
    source.clear();
    if (!this.reporte) return;
    const recorrido = this.recorridos.find(
      (r) => r._id === this.reporte?.idRecorrido,
    );
    if (!recorrido) return;
    const terminales = recorrido.ubicaciones;
    if (!terminales) return;
    // Agrego las paradas
    for (const t of terminales) {
      if (!t.geojson?.coordinates) continue;
      const coords = OpenLayersService.polygonToCoordinates(
        t.geojson?.coordinates as [[number, number][]],
      );
      const feature: Feature<Geometry> = new Feature({
        geometry: new Polygon(coords),
        data: t,
        id: t._id,
      });
      source.addFeature(feature);
    }
  }

  private panTo(coordinate: Coordinate) {
    if (!this.map) return;
    this.map.getView().animate({
      center: coordinate,
      duration: 1000,
      zoom: 11,
    });
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    if (!extent) return;
    this.map?.getView().fit(extent, { padding: [200, 200, 200, 200] });
    if (this.map?.getView().getZoom() > 17) {
      this.map?.getView().setZoom(17);
    }
  }

  public onClose() {
    this.reporte = undefined;
    this.trackeo = undefined;
    this.clearParadas();
  }
  private hexToRgba(hex: string, opacity: number): string {
    // Elimina el símbolo '#' si está presente
    hex = hex.replace(/^#/, '');

    // Divide el valor HEX en partes de 2 caracteres
    let r = 0,
      g = 0,
      b = 0;

    if (hex.length === 3) {
      // Si el HEX es del tipo corto (#abc), lo expandimos a 6 caracteres (#aabbcc)
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      // Si el HEX ya tiene 6 caracteres
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }

    // Retorna el formato RGBA
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  ///
  async ngOnInit() {
    await Promise.all([
      await this.listarRecorridos(),
      await this.listar(),
      await this.listarTerminales(),
    ]);
    await this.initMap();
  }

  ngOnDestroy() {
    this.reportes$?.unsubscribe();
    this.recorridos$?.unsubscribe();
  }
}
