@if (!loading) {
  <!-- Evento -->
  <h2 mat-dialog-title>
    Evento {{ tratamientoEvento?.evento?.valores?.titulo }}
  </h2>
  <mat-dialog-content class="mat-typography column-center">
    <section>
      <div class="column-flex-start">
        <!-- Detalles -->
        <h3 class="row-flex-start" style="font-size: 2em">Detalles</h3>
        <span class="row-flex-start">{{
          tratamientoEvento?.evento?.valores?.mensaje ||
            tratamientoEvento?.evento?.valores["detalles"]
        }}</span>
      </div>
      <div class="row-flex-start" style="gap: 100px; padding: 0.5em">
        <div class="column-flex-start">
          <!--Dispositivo-->
          <h3 class="row-flex-start" style="font-size: 2em">Dispositivo</h3>
          <span class="row-flex-start">
            {{
              tratamientoEvento?.evento?.activo?.identificacion ||
                tratamientoEvento?.evento?.alarma?.nombre
            }}</span
          >
        </div>

        <div class="column-flex-start">
          <!--Cliente-->
          <h3 class="row-flex-start" style="font-size: 2em">Cliente</h3>
          <span class="row-flex-start">
            {{ tratamientoEvento?.evento?.cliente.nombre }}</span
          >
        </div>

        <div class="column-flex-start">
          <!--Notificar-->
          <h3 class="row-flex-start" style="font-size: 2em">Notificar</h3>
          <span class="row-flex-start">
            {{ tratamientoEvento?.evento?.notificar ? "Si" : "No" }}
          </span>
        </div>
      </div>
    </section>

    <br />
    @if (
      tratamientoEvento?.evento?.activo || tratamientoEvento?.evento?.alarma
    ) {
      <!-- Estados -->
      <h3 class="row-flex-start" style="font-size: 2em">Estados</h3>
      <section style="width: 100%; max-height: 200px; overflow: auto">
        <mat-table [dataSource]="estados">
          <!-- Usuario -->
          <ng-container matColumnDef="usuarios">
            <mat-cell *matCellDef="let element">
              {{ element.usuario }}
            </mat-cell>
          </ng-container>
          <!-- Estado -->
          <ng-container matColumnDef="estados">
            <mat-cell *matCellDef="let element">
              {{ element.estado }}
            </mat-cell>
          </ng-container>
          <mat-row *matRowDef="let row; columns: columnasEstados"></mat-row>
        </mat-table>
      </section>

      <!-- Notas -->
      <h3 class="row-flex-start" style="font-size: 2em">Notas</h3>
      <section class="layout">
        <!-- Notas -->
        <div class="column" style="width: 50%">
          <mat-label>Notas</mat-label>
          <div
            class="grow1"
            style="min-width: 50%; overflow: auto; max-height: 200px"
          >
            <mat-table [dataSource]="notas">
              <!-- Usuario -->
              <ng-container matColumnDef="usuarios">
                <mat-cell *matCellDef="let element">
                  {{ element.usuario }}
                </mat-cell>
              </ng-container>
              <!-- Estado -->
              <ng-container matColumnDef="notas">
                <mat-cell *matCellDef="let element">
                  {{ element.nota }}
                </mat-cell>
              </ng-container>
              <mat-row *matRowDef="let row; columns: columnasNotas"></mat-row>
            </mat-table>
          </div>
        </div>
        <!-- NotasInternas -->
        <div class="column" style="width: 50%">
          <mat-label>Notas Internas</mat-label>
          <div
            class="grow1"
            style="min-width: 50%; overflow: auto; max-height: 200px"
          >
            <mat-table [dataSource]="notasInternas" class="mat-elevation-z8">
              <!-- Usuario -->
              <ng-container matColumnDef="usuarios">
                <mat-cell *matCellDef="let element">
                  {{ element.usuario }}
                </mat-cell>
              </ng-container>
              <!-- Estado -->
              <ng-container matColumnDef="notasInternas">
                <mat-cell *matCellDef="let element">
                  {{ element.nota }}
                </mat-cell>
              </ng-container>
              <mat-row
                *matRowDef="let row; columns: columnasNotasInternas"
              ></mat-row>
            </mat-table>
          </div>
        </div>
      </section>
    }
    <section style="width: 100%; height: 600px">
      <h3 align="center" style="font-size: 2em">Ubicacion</h3>
      <app-mapa-facil [data]="ubicacion"></app-mapa-facil>
    </section>
  </mat-dialog-content>
  <!-- Botones  -->
  <mat-dialog-actions align="end">
    <app-button
      matType="mat-fab extended"
      color="warn"
      (click)="this.dialog.closeAll()"
      text="Volver"
      icon="arrow_back"
    ></app-button>
  </mat-dialog-actions>
}
