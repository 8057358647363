import { Component, OnChanges, OnInit } from '@angular/core';
import { LoadingService } from '../../../auxiliares/servicios/loading.service';
import { HelperService } from '../../../auxiliares/servicios/helper.service';
import { ThemesService } from '../../../auxiliares/servicios/themes.service';
import { ListadosService } from '../../../auxiliares/servicios/listados.service';
import { Subscription } from 'rxjs';
import { ICliente, ITipoCliente } from 'modelos/src';
import { Router } from '@angular/router';
import { PushNotificationsService } from '../../../auxiliares/servicios/push-notifications.service';
import { LoginService } from '../../login/login.service';
import { ListadoEventosVivoService } from '../../../auxiliares/servicios/listado-eventos-vivo';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
})
export class NavigationComponent implements OnInit, OnChanges {
  private cliente$?: Subscription;
  public usuario = LoginService.getUsuario();
  public clientePropio?: ICliente;
  public verClientes = true;
  public verActivos = false;
  public verColectivos = false;
  public verAlarmas = false;

  public notificacionesActivas?: boolean;

  public expandedPanel?: number;

  constructor(
    public loading: LoadingService,
    public helper: HelperService,
    public temas: ThemesService,
    private listados: ListadosService,
    private router: Router,
    public pushNotificationsService: PushNotificationsService,
    public listadoEventosVivoService: ListadoEventosVivoService,
  ) {
    this.listadoEventosVivoService.iniciarServicio();
  }

  public editarClientePropio() {
    window.location.href = `/main/clientes/editar/${this.clientePropio?._id}`;
  }

  private async clienteUpdate() {
    this.cliente$?.unsubscribe();
    this.cliente$ = this.listados
      .subscribe<ICliente>('clientePropio', {})
      .subscribe((data) => {
        console.log('clientePropio', data);
        this.clientePropio = data;
        if (data.config) {
          this.temas.setTema(data.config);
          // Setea ver clientes
          const tipos: ITipoCliente[] = ['Mayorista', 'Minorista'];
          const tipoCliente = data.tipoCliente as ITipoCliente;
          this.verClientes = tipos.includes(tipoCliente);

          this.verAlarmas = data.config.moduloAlarmasDomiciliarias?.activo;
          this.verColectivos = data.config.moduloColectivos?.activo;
          this.verActivos = data.config.moduloActivos?.activo;
          this.checkPermisos();
        }
      });
    await this.listados.getLastValue('clientePropio', {});
  }

  private checkPermisos() {
    const clienteLocal = LoginService.getCliente();
    // Activos
    const activoCliente =
      this.clientePropio?.config?.moduloActivos?.activo !== false ||
      clienteLocal.config.moduloActivos;
    const activoUsuario = this.usuario?.permisos?.moduloActivos !== false;
    this.helper.verModuloActivos.set(activoCliente && activoUsuario);
    // Colectivos
    const colectivoCliente =
      this.clientePropio?.config?.moduloColectivos?.activo !== false ||
      clienteLocal.config.moduloColectivos;
    const colectivoUsuario = this.usuario?.permisos?.moduloColectivos !== false;
    this.helper.verModuloColectivos.set(colectivoCliente && colectivoUsuario);
    // Alarmas
    const alarmaCliente =
      this.clientePropio?.config?.moduloAlarmasDomiciliarias?.activo !==
        false || clienteLocal.config.moduloAlarmasDomiciliarias;
    const alarmaUsuario =
      this.usuario?.permisos?.moduloAlarmasDomiciliarias !== false;
    this.helper.verModuloAlarmas.set(alarmaCliente && alarmaUsuario);
    const roles = this.usuario?.roles;
    this.helper.puedeEditar.set(
      roles.includes('Administrador') || roles.includes('Operador'),
    );
  }

  private openPanelInicial() {
    const ruta = this.router.url;
    if (ruta.includes('/main/activos')) {
      this.expandedPanel = 0;
    } else if (ruta.includes('/main/colectivos')) {
      this.expandedPanel = 1;
    } else if (ruta.includes('/main/alarmas')) {
      this.expandedPanel = 2;
    } else if (ruta.includes('/main/configuracion')) {
      this.expandedPanel = 3;
    } else if (ruta.includes('/main/administracion')) {
      this.expandedPanel = 4;
    }
  }

  public async activarNotificaciones() {
    const estado = await this.pushNotificationsService.requestPermission();
    if (estado === 'granted') {
      await this.pushNotificationsService.iniciarPermisos();
      this.notificacionesActivas = estado === 'granted';
    }
  }

  async ngOnInit() {
    this.checkPermisos();
    this.openPanelInicial();
    const [estado] = await Promise.all([
      this.pushNotificationsService.estadoPermisos(),
      this.clienteUpdate(),
    ]);
    this.notificacionesActivas = estado === 'granted';
  }

  async ngOnChanges() {
    await this.clienteUpdate();
  }
}
