/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Feature, Map, View } from 'ol';
import { ICategoriaActivo, IReporte, ITrackeo } from 'modelos/src';
import { Subscription } from 'rxjs';
import { ListadosService } from '../../../../auxiliares/servicios/listados.service';
import { Geometry, LineString, Point } from 'ol/geom';
import { OpenLayersService } from '../../../../auxiliares/servicios/openLayers.service';

import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { Coordinate } from 'ol/coordinate';
import VectorSource from 'ol/source/Vector';
import { IFiltroLastReporte } from '../../../../auxiliares/servicios/http/reportes.service';

@Component({
  selector: 'app-mapa-activos',
  templateUrl: './mapa-activos.component.html',
  styleUrl: './mapa-activos.component.scss',
})
export class MapaActivosComponent implements OnInit, OnDestroy {
  public ctrl = false;
  //Vehiculos
  public reportes?: IReporte[] = [];
  public reporte?: IReporte;
  public reportes$?: Subscription;

  //Activos
  public reportesActivos?: IReporte[] = [];
  public reporteActivos?: IReporte;
  public reportesActivos$?: Subscription;

  // Activos
  private activosMostrar: boolean = true;
  // Trackeo
  public trackeo?: ITrackeo;
  private trackeo$?: Subscription;

  /// OPENLAYERS
  public map?: Map;

  // Punto Activo
  public activoSimpleLayer = OpenLayersService.activoVectorLayer();
  /// Centro
  private puntos: [number, number][] = [];

  constructor(
    private listados: ListadosService,
    private helper: HelperService,
  ) {}

  /// Activos
  public async listarActivos(): Promise<void> {
    try {
      const categoriaActivo: ICategoriaActivo = 'Normal';
      const query: IFiltroLastReporte = {
        categoriaActivo,
      };

      this.reportesActivos$?.unsubscribe();
      this.reportesActivos$ = this.listados
        .subscribe<IReporte[]>('reportesLastFiltered', query)
        .subscribe(async (data) => {
          this.reportesActivos = data;
          this.addMarkersActivos();
          console.log(
            `listado de reportesActivos ${new Date().toISOString()}`,
            data,
          );
        });
      await this.listados.getLastValue('reportesLastFiltered', query);
    } catch (error: any) {
      this.helper.notifError('Error al listar activos');
    }
  }
  // Punto Activos
  private addMarkersActivos() {
    const source = this.activoSimpleLayer.getSource();
    if (!source) return;
    source.clear();
    if (!this.activosMostrar) return;
    if (this.reportesActivos) {
      for (const reporte of this.reportesActivos) {
        if (
          this.reporte &&
          this.reporte.activo &&
          this.reporte.activo._id === reporte.activo._id
        ) {
          this.reporte = reporte;
        }
        if (!reporte.geojson?.coordinates) return;
        this.puntos.push(reporte.geojson.coordinates);
        // punto
        const coord = OpenLayersService.lonLatToCoordinate(
          reporte.geojson.coordinates[0],
          reporte.geojson.coordinates[1],
        );
        const feature: Feature<Geometry> = new Feature({
          geometry: new Point(coord),
          data: reporte,
          tipo: 'activo',
        });
        source.addFeature(feature);
      }
    }
  }

  public async onActivosChange() {
    this.activosMostrar = !this.activosMostrar;
    this.addMarkersActivos();
  }

  private async initMap() {
    const position = await OpenLayersService.getCurrentPosition();
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      target: 'map',
      controls: [],
      layers: [OpenLayersService.mapTile(), this.activoSimpleLayer],
      view: new View({
        center: position,
        zoom: 16,
      }),
    });

    this.map.on('error', (e) => {
      console.error('Error en el mapa', e);
    });

    await this.handleClick();
    const sourceres: VectorSource<Feature<Geometry>> = new VectorSource();

    if (this.reportesActivos?.length) {
      const sourcePoint = this.activoSimpleLayer.getSource();
      sourcePoint.forEachFeature((f) => sourceres.addFeature(f));
    }
    if (sourceres) {
      this.setBounds(sourceres);
    }
  }

  private async handleClick() {
    if (!this.map) return;
    this.reporte = undefined;
    this.trackeo = undefined;
    this.map.on('singleclick', async (evt) => {
      const feature = this.map?.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          const geometry = feature.getGeometry();
          if (geometry instanceof Point) {
            if (feature.get('tipo') === 'vehiculo') {
              /// ES UN VEHICULO
              return feature;
            }
            if (feature.get('tipo') === 'activo') {
              /// ES UN ACTIVO
              return feature;
            }
            /// ES UNA PARADA
            return null;
          } else if (geometry instanceof LineString) {
            return null;
          }
          return null;
        },
      );

      if (!feature) return;

      const data = feature.get('data') as IReporte;
      if (feature.get('tipo') === 'vehiculo') {
        this.reporte = data;
        if (!this.reporte?.geojson?.coordinates) return;
      }
      if (feature.get('tipo') === 'activo') {
        this.reporte = data;
        if (!this.reporte?.geojson?.coordinates) return;
      }
    });
  }

  private panTo(coordinate: Coordinate) {
    if (!this.map) return;
    this.map.getView().animate({
      center: coordinate,
      duration: 1000,
      zoom: 11,
    });
  }

  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    if (!extent) return;
    this.map?.getView().fit(extent, { padding: [200, 200, 200, 200] });
    if (this.map?.getView().getZoom() > 17) {
      this.map?.getView().setZoom(17);
    }
  }

  public onClose() {
    this.reporte = undefined;
    this.trackeo = undefined;
  }
  ///
  async ngOnInit() {
    await Promise.all([await this.listarActivos()]);
    await this.initMap();
  }

  ngOnDestroy() {
    this.reportes$?.unsubscribe();
  }
}
