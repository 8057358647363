import { ICliente, IListado, IQueryParam } from 'modelos/src';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { AuxiliaresModule } from '../auxiliares.module';
import { LoadingService } from '../servicios/loading.service';
import { Subscription } from 'rxjs';
import { ListadosService } from '../servicios';

export interface DateRange {
  start?: string;
  end?: string;
}

@Component({
  selector: 'app-clientes-hasta-selector',
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './clientes-hasta-selector.component.html',
  styleUrl: './clientes-hasta-selector.component.scss',
})
export class ClientesHastaSelectorComponent implements OnInit, OnChanges {
  @Input() public clienteDispositivo?: ICliente;
  @Input() public clientesIn?: string[];
  public clientesQuePuedenAtender?: ICliente[] = [];
  @Output() public clientesOut? = new EventEmitter<string[]>();

  private clientes: ICliente[] = [];
  private clientes$: Subscription;
  constructor(
    public loading: LoadingService,
    private listados: ListadosService,
  ) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onChange(clientes: ICliente[]) {
    const idClientes = clientes.map((c) => {
      return c._id;
    });
    this.clientesOut.emit(idClientes);
  }

  public async listarClientes(): Promise<void> {
    const query: IQueryParam = { includeChildren: true };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listados
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listados.getLastValue('clientes', query);
  }

  public clienteChange(cliente: ICliente) {
    const clientesPadres: ICliente[] = [cliente];
    let idPadre = cliente.idPadre;
    while (idPadre) {
      const clientePadre = this.clientes.find((c) => c._id === idPadre);
      if (clientePadre) {
        clientesPadres.push(clientePadre);
      }
      idPadre = clientePadre?.idPadre;
    }
    this.clientesQuePuedenAtender = clientesPadres;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clienteDispositivo']) {
      this.clienteChange(changes['clienteDispositivo'].currentValue);
    }
  }

  async ngOnInit() {
    await this.listarClientes();
    this.clienteChange(this.clienteDispositivo);
  }
}
