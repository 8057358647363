import { Component, Input, OnInit } from '@angular/core';
import {
  IActivo,
  IFilter,
  IListado,
  ILogTrackeo,
  IPopulate,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { IColumnas } from '../../../../../../auxiliares/tabla/tabla.component';
import { HelperService } from '../../../../../../auxiliares/servicios/helper.service';
import { ListadosService } from '../../../../../../auxiliares/servicios/listados.service';

@Component({
  selector: 'app-listado-logs-trackeos',
  templateUrl: './listado-logs-trackeos.component.html',
  styleUrl: './listado-logs-trackeos.component.scss',
})
export class ListadoLogsTrackeosComponent implements OnInit {
  @Input() vehiculo?: IActivo;

  //// Tabla nueva
  public name = ListadoLogsTrackeosComponent.name;
  public logs: ILogTrackeo[] = [];
  public logs$?: Subscription;
  public totalCount = 0;
  public columnas?: IColumnas<ILogTrackeo>[];

  public filter: IFilter<ILogTrackeo> = {};
  public populate: IPopulate = {
    path: 'cliente',
    select: 'nombre',
  };
  public query: IQueryParam = {
    filter: JSON.stringify(this.filter),
    populate: JSON.stringify(this.populate),
    sort: '-fecha',
    page: 0,
    limit: +this.helper.getPageSize(this.name),
    includeChildren: false,
    childrenLevel: 0,
  };

  constructor(
    public helper: HelperService,
    private listados: ListadosService,
  ) {}

  public async listar(): Promise<void> {
    if (!this.vehiculo?._id) return;
    const filter = HelperService.stringToObject(this.query.filter);
    filter['idActivo'] = this.vehiculo._id;
    this.query.filter = JSON.stringify(filter);

    this.logs$?.unsubscribe();
    this.logs$ = this.listados
      .subscribe<IListado<ILogTrackeo>>('logTrackeos', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.logs = data.datos;
        console.log(`listado de logTrackeos`, data);
      });
    await this.listados.getLastValue('logTrackeos', this.query);
  }

  // Tabla

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Fecha de Creación',
          sortable: true,
        },
        row: {
          field: 'fecha',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      // {
      //   header: {
      //     label: 'Parada Trackeo',
      //     sortable: true,
      //   },
      //   row: {
      //     field: 'ultimaParada',
      //   },
      // },
      {
        header: {
          label: 'Parada Trackeo #',
        },
        row: {
          parse(dato) {
            if (!HelperService.isDefined(dato.indexUltimaParada)) return 'N/A';
            return dato.indexUltimaParada! + 1;
          },
        },
      },
      {
        header: {
          label: 'Parada Actual',
          sortable: true,
        },
        row: {
          field: 'paradaActual',
        },
      },
      {
        header: {
          label: '#',
        },
        row: {
          parse(dato) {
            if (!HelperService.isDefined(dato.indexParadaActual)) return 'N/A';
            return dato.indexParadaActual! + 1;
          },
        },
      },
      {
        header: {
          label: 'Parada Nueva',
        },
        row: {
          field: 'nuevaParada',
          noData: 'No hay parada nueva',
        },
      },
      {
        header: {
          label: 'Motivo',
        },
        row: {
          field: 'motivo',
        },
      },
    ];
  }

  async ngOnInit() {
    this.setColumnas();
    await this.listar();
  }
}
