import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuxiliaresModule } from '../auxiliares.module';
import { View, Feature, Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Geometry, Point } from 'ol/geom';
import { OpenLayersService } from '../servicios/openLayers.service';
import { HelperService } from '../servicios/helper.service';
import VectorSource from 'ol/source/Vector';

@Component({
  selector: 'app-mapa-unico-punto',
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  templateUrl: './mapa-unico-punto.component.html',
  styleUrl: './mapa-unico-punto.component.scss',
})
export class MapaUnicoPuntoComponent implements OnInit {
  // Icon -- Marker
  @Input() point: Coordinate;
  @Output() pointsChange = new EventEmitter<Coordinate>();

  // OpenLayers
  private map?: Map;
  public tileLayer = OpenLayersService.mapTile();
  public proveedorLayer = OpenLayersService.ubicacionVectorLayer();

  constructor(private helper: HelperService) {}

  private async initMap(): Promise<void> {
    this.map = new Map({
      interactions: OpenLayersService.interactions(),
      controls: [],
      layers: [this.tileLayer, this.proveedorLayer],
      target: 'mapa-creador',
      view: new View({
        center: this.point
          ? this.point
          : await OpenLayersService.getCurrentPosition(),
        zoom: 13,
      }),
    });

    if (this.proveedorLayer.getSource()?.getFeatures().length) {
      this.setBounds(this.proveedorLayer.getSource());
    }
  }

  /// Icon -- Marker
  private async dibujarPuntos() {
    if (!this.map) return;
    this.handleProveedores();
  }

  private handleProveedores() {
    // Cargo las que haya (EDIT)
    if (this.point) this.addIconProveedor(this.point);

    this.handleClick();
  }

  private handleClick() {
    // ONCLICK
    this.map?.on('singleclick', (e) => {
      const ubicacion = e.coordinate;
      if (!ubicacion) {
        return this.helper.notifError(
          'Error al obtener la ubicación',
          'Cerrar',
        );
      }
      const proveedor: Coordinate = ubicacion;

      this.point = proveedor;
      this.addIconProveedor(proveedor);
      this.pointsChange.emit(this.point);
    });
  }

  private addIconProveedor(p: Coordinate) {
    this.proveedorLayer.getSource()?.clear();
    // Icon
    const source = this.proveedorLayer.getSource();
    if (!source) return;
    const feature: Feature<Geometry> = new Feature({
      geometry: new Point(p),
    });
    source.addFeature(feature);
  }

  // Fondo
  private setBounds(source: VectorSource<Feature<Geometry>> | null) {
    if (!source) return;
    const extent = source.getExtent();
    this.map?.getView().fit(extent, { padding: [50, 50, 50, 50] });
  }

  //// HOOKS
  async ngOnInit(): Promise<void> {
    await this.initMap();
    /// Siempre agarro markers con el click
    this.dibujarPuntos();
  }
}
