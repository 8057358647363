import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { IActivo, ICliente, IParada, IReporte, ITrackeo } from 'modelos/src';
import { LoadingService } from '../../../../../auxiliares/servicios/loading.service';
import { LoginService } from '../../../../login/login.service';
import { MatDialog } from '@angular/material/dialog';
import { CrearEditarActivoComponent } from '../../../activos/activos/crear-editar-activo/crear-editar-activo.component';

@Component({
  selector: 'app-isla',
  templateUrl: './isla.component.html',
  styleUrl: './isla.component.scss',
})
export class IslaComponent implements OnInit, OnChanges {
  @Input() public reporte?: IReporte;
  @Output() public reporteChange = new EventEmitter<IReporte>();
  @Input() public trackeo?: ITrackeo;
  @Output() public trackeoChange = new EventEmitter<ITrackeo>();

  public parada?: IParada;
  public idxParada?: number;
  public cliente?: ICliente;

  constructor(
    public loading: LoadingService,
    public dialog: MatDialog,
  ) {
    this.cliente = LoginService.getCliente();
  }

  public close() {
    this.reporte = undefined;
    this.reporteChange.emit(this.reporte);
    this.trackeo = undefined;
    this.trackeoChange.emit(this.trackeo);
  }

  private getParada() {
    this.parada = this.trackeo?.recorrido?.paradas?.find((p, index) => {
      if (p._id === this.trackeo?.idParada) {
        this.idxParada = index + 1;
        return p._id === this.trackeo?.idParada;
      }
      return null;
    });
  }

  public getFoto() {
    return (
      `url("${this.reporte?.chofer?.datosPersonales?.foto}")` ||
      `url("https://static.vecteezy.com/system/resources/previews/018/765/757/original/user-profile-icon-in-flat-style-member-avatar-illustration-on-isolated-background-human-permission-sign-business-concept-vector.jpg")`
    );
  }

  public async editarActivo(activo: IActivo) {
    this.dialog.open(CrearEditarActivoComponent, { data: activo._id });
  }

  async ngOnInit() {
    this.getParada();
  }

  async ngOnChanges() {
    this.getParada();
  }
}
