import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  IListado,
  IQueryParam,
  IModeloDispositivo,
  ICodigosDispositivo,
  ICreateModeloDispositivo,
  IUpdateModeloDispositivo,
  IFilter,
  TipoDispositivo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../../../auxiliares/servicios/helper.service';
import { ModelosDispositivosService } from '../../../../auxiliares/servicios/http/modelos-dispositivos.service';
import { ListadosService } from '../../../../auxiliares/servicios';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../../../auxiliares/auxiliares.module';

@Component({
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
  selector: 'app-crear-editar-modelo-dispositivo',
  templateUrl: './crear-editar-modelo-dispositivo.component.html',
  styleUrl: './crear-editar-modelo-dispositivo.component.scss',
})
export class CrearEditarModeloDispositivoComponent
  implements OnInit, OnDestroy
{
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public formatos = ['Nanocomm ED5200'];

  public codigosDispositivos?: ICodigosDispositivo[] = [];
  public codigosDispositivos$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { edit: IModeloDispositivo; tipo: TipoDispositivo },
    public helper: HelperService,
    private service: ModelosDispositivosService,
    public dialogRef: MatDialogRef<CrearEditarModeloDispositivoComponent>,
    private listados: ListadosService,
  ) {}

  private createForm() {
    this.form = new FormGroup({
      marca: new FormControl(this.data?.edit?.marca),
      tipo: new FormControl(this.data?.edit?.tipo),
      modelo: new FormControl(this.data?.edit?.modelo),
      idCodigos: new FormControl(this.data?.edit?.idCodigos),
      formatoMensaje: new FormControl(this.data?.edit?.formatoMensaje),
    });
  }

  public async listarCodigosDispositivos(): Promise<void> {
    const filter: IFilter<ICodigosDispositivo> = {
      tipo: this.data.tipo,
    };
    const query: IQueryParam = { filter: JSON.stringify(filter) };
    this.codigosDispositivos$?.unsubscribe();
    this.codigosDispositivos$ = this.listados
      .subscribe<IListado<ICodigosDispositivo>>('codigosDispositivos', query)
      .subscribe((data) => {
        this.codigosDispositivos = data.datos;
        console.log(`listado de codigosDipositivos`, data);
      });
    await this.listados.getLastValue('codigosDispositivos', query);
  }

  //
  public async onSubmit() {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?.edit?._id) {
        // Update
        await this.service.update(this.data.edit._id, data);
        this.helper.notifSuccess('Actualizado correctamente');
      } else {
        // Create
        await this.service.create(data);
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
      this.helper.notifError(error);
    }
    this.loading = false;
  }

  public getData(): ICreateModeloDispositivo | IUpdateModeloDispositivo {
    const data: ICreateModeloDispositivo | IUpdateModeloDispositivo =
      this.form?.value;
    return data;
  }

  async ngOnInit() {
    this.title = this.data.edit?._id
      ? `Editar ${this.data.tipo} `
      : `Crear ${this.data.tipo}`;
    this.createForm();
    await this.listarCodigosDispositivos();
  }

  ngOnDestroy(): void {
    this.codigosDispositivos$?.unsubscribe();
  }
}
