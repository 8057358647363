<div id="map" class="map-container">
  <div id="flotante" class="ol-flotante">
    <app-flotante-ol
      (ocultar)="ocultar($event)"
      [vehiculos]="reportes?.length"
      [activos]="reportesActivos?.length"
      [alarmas]="alarmas?.length"
    ></app-flotante-ol>
  </div>
  @if (reporte || alarma) {
    <div id="isla" class="ol-isla">
      <app-isla-ol
        [(reporte)]="reporte"
        [(alarma)]="alarma"
        (alarmaChange)="onClose()"
        (reporteChange)="onClose()"
      ></app-isla-ol>
    </div>
  }
</div>
